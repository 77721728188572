import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import includes from 'lodash/includes';

import i18n from '../../localization/i18n-wrapper';
import utils from '../../util/utils';
import formatNumber from '../../util/formatNumber';
import BillingLots from '../billingLots';
import {
  getCustomer,
  getCustomerBeneficiary,
  getCustomerBeneficiaryDetails,
  getSpouse,
  getSpouseBeneficiary,
  getSpouseBeneficiaryDetails,
  getChildren,
  getCampaignCode,
} from '../../redux/reducers/purchaseReducer';
import {
  getChildDABCompensation,
  getChildDAICompensation,
  getChildDALCompensation,
  isMemberOfOrganization,
  getCustomerProducts,
  getSpouseAge,
  getCustomerAge,
  getCustomerCompensation,
  getSpouseCompensation,
  getSpouseProducts,
  getBillsPerYear,
  isCoupleInsuranceSelected,
  getSpouseCICompensation,
  getCustomerCICompensation,
  isCustomerSmoking,
  isSpouseSmoking,
  isCustomerLifeSelected,
  isCampaignCodeValid,
  getCampaignDescription,
  isCampaignActive,
} from '../../redux/reducers/selectedReducer';
import {
  getNormalPrices,
  getDiscountedPrices,
  getDiscountedBillingLot,
  getNormalTotalSelectedAdditionalProductsPerMonth,
  getNormalTotalPerMonth,
  getTotalPerMonthNoOrg,
  getDiscountedTotalPerMonth,
  getNormalCoupleInsurancePerMonth,
  getNormalChildInsurancePrices,
  getNormalCustomerInsurancePrices,
  getNormalSpouseInsurancePrices,
} from '../../redux/reducers/priceReducer';
import { CampaignCode } from './campaignCode';
import { InsuranceTotalRow } from './insuranceTotalRow';
import { InsuredPerson } from './insuredPerson';

export const formatBeneficiary = (beneficiary, details) => {
  let value;

  if (beneficiary === 'NAMED') {
    value = `${details.firstName} ${details.lastName}`;
  } else {
    value = i18n.t(`beneficiary_${beneficiary}`);
  }

  return value;
};

class InsuranceDetails extends React.Component {
  static displayName = 'InsuranceDetails';

  static propTypes = {
    showOrganization: PropTypes.bool,
    showAddress: PropTypes.bool,
    readOnly: PropTypes.bool,
    children: PropTypes.array,
    customer: PropTypes.object.isRequired,
    spouse: PropTypes.object,
    customerAge: PropTypes.number.isRequired,
    spouseAge: PropTypes.number,
    customerBeneficiary: PropTypes.string.isRequired,
    spouseBeneficiary: PropTypes.string.isRequired,
    customerBeneficiaryDetails: PropTypes.object,
    spouseBeneficiaryDetails: PropTypes.object,
    totalPerMonth: PropTypes.number.isRequired,
    totalPerMonthNoOrg: PropTypes.number,
    discountedTotalPerMonth: PropTypes.number.isRequired,
    coupleInsurancePerMonth: PropTypes.number.isRequired,
    customerInsurancePrices: PropTypes.object.isRequired,
    spouseInsurancePrices: PropTypes.object,
    childInsurancePrices: PropTypes.object.isRequired,
    normalPrices: PropTypes.object.isRequired,
    billsPerYear: PropTypes.number.isRequired,
    discountedBillingLot: PropTypes.number.isRequired,
    childDABCompensation: PropTypes.number.isRequired,
    childDAICompensation: PropTypes.number.isRequired,
    childDALCompensation: PropTypes.number.isRequired,
    memberOfOrganization: PropTypes.bool.isRequired,
    customerProducts: PropTypes.array.isRequired,
    spouseProducts: PropTypes.array.isRequired,
    coupleInsuranceSelected: PropTypes.bool.isRequired,
    customerCompensation: PropTypes.number,
    spouseCompensation: PropTypes.number,
    customerCiCompensation: PropTypes.number,
    spouseCiCompensation: PropTypes.number,
    customerSmoking: PropTypes.bool.isRequired,
    spouseSmoking: PropTypes.bool.isRequired,
    additionalProducts: PropTypes.any,
    campaignCode: PropTypes.string,
    campaignCodeValid: PropTypes.bool,
    discountPercentage: PropTypes.number,
    customerLifeSelected: PropTypes.bool,
    campaignActive: PropTypes.bool,
  };

  _getChildInsurancePrices = (child, prices) => {
    const age = utils.calculateAge(child.socialSecurityNumber);

    if (age <= 9) {
      return prices.from0To9Prices;
    }
    if (age >= 10 && age <= 12) {
      return prices.from10To12Prices;
    }
    if (age >= 13 && age <= 17) {
      return prices.from13To17Prices;
    }

    return null;
  };

  getInsuredChildren = () => {
    const { children, customer, childInsurancePrices } = this.props;
    const { childDABCompensation, childDAICompensation, childDALCompensation } = this.props;
    const { memberOfOrganization } = this.props;
    const products = ['DAB', 'DAI', 'DAL'];
    const content = [];

    const dab = childDABCompensation || 20000;
    const dai = childDAICompensation || 100000;
    const dal = childDALCompensation || 5000;

    const childAdditionalProducts = [
      {
        productType: 'DAB',
        maxCompensation: dab,
      },
      {
        productType: 'DAI',
        maxCompensation: dai,
      },
      {
        productType: 'DAL',
        maxCompensation: dal,
      },
    ];

    let idx = 0;
    children.forEach((person) => {
      const childPrices = this._getChildInsurancePrices(person, childInsurancePrices);

      if (childPrices) {
        const idPrefix = `child${idx++}`;
        content.push(
          <InsuredPerson
            key={idPrefix}
            idPrefix={idPrefix}
            person={person}
            beneficiary="NEXT_OF_KIN"
            memberOfOrganization={memberOfOrganization}
            products={products}
            additionalProducts={childAdditionalProducts}
            prices={childPrices}
          />,
        );
      }
    });

    return content;
  };

  totalSameAsInsurancePrice = () => {
    const {
      coupleInsurancePerMonth, customerInsurancePrices, spouseInsurancePrices, childInsurancePrices, totalPerMonth,
    } = this.props;

    if (coupleInsurancePerMonth == totalPerMonth) {
      return true;
    }
    for (const price in customerInsurancePrices) {
      if (totalPerMonth == customerInsurancePrices[price]) {
        return true;
      }
    }
    for (const price in spouseInsurancePrices) {
      if (totalPerMonth == spouseInsurancePrices[price]) {
        return true;
      }
    }
    for (const price in childInsurancePrices) {
      if (totalPerMonth == childInsurancePrices[price]) {
        return true;
      }
    }
    return false;
  }

  getAmountOfProducts = () => {
    const { customerProducts, children, spouseProducts } = this.props;

    return utils.sumArrayLengths(
      customerProducts,
      children,
      spouseProducts,
    );
  }

  getInsuranceTotalRows = () => {
    const {
      memberOfOrganization, totalPerMonth, totalPerMonthNoOrg, discountedTotalPerMonth,
      customerLifeSelected, campaignActive,
    } = this.props;
    const totalPerYear = totalPerMonth * 12;
    const discountedPerYear = discountedTotalPerMonth * 12;
    const content = [];
    const description = this.showOrganizationDiscountDescription()
      ? i18n.t('insuranceTotalPerMonthDescription') : null;

    if (memberOfOrganization) {
      const noOrgPrice = totalPerMonthNoOrg > totalPerMonth ? totalPerMonthNoOrg : undefined;
      content.push(
        <InsuranceTotalRow
          key="insuranceTotalPerMonth"
          title={i18n.t('insuranceTotalPerMonthTitle')}
          description={description}
          price={totalPerMonth}
          noOrgPrice={noOrgPrice}
          showReferencePriceOnly={this.totalSameAsInsurancePrice()}
        />,
      );
    } else {
      // We omit total price per month when customer is purchasing only one product to avoid showing duplicate prices.
      const showReferencePriceOnly = this.getAmountOfProducts() === 1;

      content.push(
        <InsuranceTotalRow
          key="insuranceTotalPerMonth"
          title={i18n.t('insuranceTotalPerMonthTitle')}
          description=" "
          price={totalPerMonth}
          showReferencePriceOnly={showReferencePriceOnly}
        />,
      );
    }

    // show campaign code field for life insurance buyers if campaign is active
    if (customerLifeSelected && memberOfOrganization && campaignActive) {
      content.push(this.getCampaignCode());
    }

    // only show discounted price if it's actually lower than the price per year
    if (totalPerYear !== discountedPerYear) {
      /* discountDescription is 'erityisalennus teollisuusliiton jäsenille' which is not active anymore
        content.push(<div className="discountDescription" key="discountDescription">{i18n.t('discountDescription')}</div>);
      */
      content.push(
        <InsuranceTotalRow
          key="insuranceTotalDiscountedPerYear"
          title={i18n.t('insuranceTotalDiscountedPerYearTitle')}
          description={i18n.t('insuranceTotalDiscountedPerYearDescription')}
          price={discountedPerYear}
        />,
      );
    }

    return content;
  };

  getCampaignCode = () => {
    const { campaignCodeValid, campaignCode, campaignDescription } = this.props;
    return (
      <CampaignCode
        key="campaignCode"
        id="campaignCode"
        label={i18n.t('campaignCodeLabel')}
        campaignCodeValid={campaignCodeValid}
        campaignCode={campaignCode}
        campaignDescription={campaignDescription}
      />
    );
  }

  getBillingLots = () => {
    const content = [];
    const { billsPerYear, discountedBillingLot } = this.props;

    content.push(
      <BillingLots key="billingLots" billsPerYear={billsPerYear} discountedBillingLot={discountedBillingLot} />,
    );
    content.push(this.getBillsFromDifferentSourcesNotice());

    return content;
  };

  showOrganizationDiscountDescription = () => {
    const {
      customerProducts, spouseProducts, memberOfOrganization, children,
    } = this.props;
    const allProducts = [...customerProducts, ...spouseProducts];
    const hasPrimusLife = allProducts.includes('LIFE') && memberOfOrganization;
    const hasAccidentalProduct = allProducts.filter((p) => p !== 'CI' && p !== 'LIFE').length > 0;
    return hasPrimusLife || hasAccidentalProduct || (children && children.length > 0);
  }

  getTotalPerYear = () => {
    const description = this.showOrganizationDiscountDescription()
      ? i18n.t('insuranceTotalPerYearDescription') : null;

    const content = [];
    const totalPerYear = this.props.totalPerMonth * 12;

    content.push(
      <InsuranceTotalRow
        key="insuranceTotalPerYear"
        title={i18n.t('insuranceTotalPerYearTitle')}
        description={description}
        price={totalPerYear}
      />,
    );

    return content;
  }

  getBillsFromDifferentSourcesNotice = () => {
    const { customerProducts, spouseProducts, memberOfOrganization } = this.props;
    const allProducts = [...customerProducts, ...spouseProducts];
    const hasCi = allProducts.includes('CI');
    const hasPrimusLife = allProducts.includes('LIFE') && memberOfOrganization;
    const hasMLLife = allProducts.includes('LIFE') && !memberOfOrganization;
    const hasAccidentalProduct = allProducts.filter((p) => p !== 'CI' && p !== 'LIFE').length > 0;
    const billsFromDifferentSources = hasCi && (hasPrimusLife || hasAccidentalProduct || hasMLLife);

    return billsFromDifferentSources ? (
      <div className="billsFromDifferentSources" key="billsFromDifferentSources">
        {i18n.t('billsFromDifferentSources')}
      </div>
    ) : (
      []
    );
  };

  isBuyingCoupleInsurance = () => {
    const { coupleInsuranceSelected, customerProducts } = this.props;
    return coupleInsuranceSelected && includes(customerProducts, 'LIFE');
  };

  render() {
    const content = [];
    const {
      customerAge,
      spouseAge,
      customerProducts,
      spouseProducts,
      customerCompensation,
      spouseCompensation,
    } = this.props;
    const {
      customerSmoking, spouseSmoking, customerCiCompensation, spouseCiCompensation,
    } = this.props;
    const {
      children,
      customer,
      spouse,
      customerBeneficiary,
      spouseBeneficiary,
      customerBeneficiaryDetails,
      spouseBeneficiaryDetails,
      memberOfOrganization,
    } = this.props;
    const {
      coupleInsurancePerMonth, customerInsurancePrices, spouseInsurancePrices, normalPrices,
    } = this.props;

    content.push(<h2 key="insuranceDetailsHeader">{i18n.t('insuranceDetailsTitle')}</h2>);

    content.push(
      <h4 id="insuranceTotalsHeader" key="insuranceTotalsHeader" className="bg-line">
        <span>{i18n.t('insuranceTotalsTitle')}</span>
      </h4>,
    );

    if (normalPrices) {
      if (this.isBuyingCoupleInsurance()) {
        content.push(
          <div className="coupleInsuranceDetailRow" key="coupleInsuranceDetailRow">
            <InsuranceTotalRow
              key="coupleInsuranceHeader"
              title={i18n.t('coupleInsuranceTitleHeader')}
              description={i18n.t('coupleInsuranceDescription')}
              additionalDescription={i18n.t('coupleInsuranceCompensationText')}
              compensation={`: ${formatNumber(customerCompensation)} €`}
              price={coupleInsurancePerMonth}
              beneficiary={customerBeneficiary}
              beneficiaryDetails={customerBeneficiaryDetails}
              priceDisplayUnit={i18n.t('priceDisplayUnit')}
            />
          </div>,
        );

        content.push(
          <label key="individualInsuranceHeader" className="title">
            {i18n.t('coupleOwnInsurances')}
          </label>,
        );
      }

      if (customerAge && customer && customerInsurancePrices) {
        content.push(
          <InsuredPerson
            key="customer"
            idPrefix="customer"
            person={customer}
            beneficiary={customerBeneficiary}
            beneficiaryDetails={customerBeneficiaryDetails}
            memberOfOrganization={memberOfOrganization}
            products={customerProducts}
            additionalProducts={this.props.additionalProducts}
            prices={customerInsurancePrices}
            lifeAmount={customerCompensation}
            ciAmount={customerCiCompensation}
            smoking={customerSmoking}
            coupleInsurance={this.isBuyingCoupleInsurance()}
          />,
        );
      }

      if (spouseAge && spouse && spouseInsurancePrices && spouseProducts.length > 0) {
        content.push(
          <InsuredPerson
            key="spouse"
            idPrefix="spouse"
            person={spouse}
            beneficiary={spouseBeneficiary}
            beneficiaryDetails={spouseBeneficiaryDetails}
            memberOfOrganization={memberOfOrganization}
            products={spouseProducts}
            additionalProducts={this.props.additionalProducts}
            prices={spouseInsurancePrices}
            lifeAmount={spouseCompensation}
            ciAmount={spouseCiCompensation}
            smoking={spouseSmoking}
            coupleInsurance={this.isBuyingCoupleInsurance()}
          />,
        );
      }

      if (children.length > 0) {
        utils.extendArray(content, this.getInsuredChildren());
      }

      utils.extendArray(content, this.getInsuranceTotalRows());
      utils.extendArray(content, this.getBillingLots());
      utils.extendArray(content, this.getTotalPerYear(memberOfOrganization));
    }
    return <div className="purchaseSection insuranceDetails">{content}</div>;
  }
}

const mapStateToProps = (state) => ({
  customer: getCustomer(state),
  customerBeneficiary: getCustomerBeneficiary(state),
  customerBeneficiaryDetails: getCustomerBeneficiaryDetails(state),
  spouse: getSpouse(state),
  spouseBeneficiary: getSpouseBeneficiary(state),
  spouseBeneficiaryDetails: getSpouseBeneficiaryDetails(state),
  children: getChildren(state),
  coupleInsuranceSelected: isCoupleInsuranceSelected(state),
  childDABCompensation: getChildDABCompensation(state),
  childDAICompensation: getChildDAICompensation(state),
  childDALCompensation: getChildDALCompensation(state),
  memberOfOrganization: isMemberOfOrganization(state),
  customerProducts: getCustomerProducts(state),
  spouseProducts: getSpouseProducts(state),
  customerAge: getCustomerAge(state),
  spouseAge: getSpouseAge(state),
  customerCompensation: getCustomerCompensation(state),
  spouseCompensation: getSpouseCompensation(state),
  customerCiCompensation: getCustomerCICompensation(state),
  spouseCiCompensation: getSpouseCICompensation(state),
  customerSmoking: isCustomerSmoking(state),
  spouseSmoking: isSpouseSmoking(state),
  billsPerYear: getBillsPerYear(state),

  normalPrices: getNormalPrices(state),
  totalPerMonth: getNormalTotalPerMonth(state),
  totalPerMonthNoOrg: getTotalPerMonthNoOrg(state),
  discountedTotalPerMonth: getDiscountedTotalPerMonth(state),
  totalSelectedAdditionalProductsPerMonth: getNormalTotalSelectedAdditionalProductsPerMonth(state),
  coupleInsurancePerMonth: getNormalCoupleInsurancePerMonth(state),
  customerInsurancePrices: getNormalCustomerInsurancePrices(state),
  spouseInsurancePrices: getNormalSpouseInsurancePrices(state),
  childInsurancePrices: getNormalChildInsurancePrices(state),
  discountedPrices: getDiscountedPrices(state),
  discountedBillingLot: getDiscountedBillingLot(state),

  customerLifeSelected: isCustomerLifeSelected(state),
  campaignCode: getCampaignCode(state),
  campaignCodeValid: isCampaignCodeValid(state),
  campaignDescription: getCampaignDescription(state),
  campaignActive: isCampaignActive(state),
});

export default connect(mapStateToProps)(InsuranceDetails);
