import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import i18n from 'i18next-client';
import Picker from './picker';
import SearchSelector from './searchSelector';

import i18nWrapper from '../localization/i18n-wrapper';
import { safeNum } from '../util/utils';
import { setOrganization } from '../redux/actions/selectedActions';
import purchaseApi from '../api/purchaseApi';
import { getOrganizations } from '../redux/reducers/dataReducer';
import { isMemberOfOrganization, getOrganization } from '../redux/reducers/selectedReducer';
import { defaultOrg } from '../models/organizationModel';

class OrgPicker extends React.Component {
  handleChange = (value) => {
    const {
      setOrganization, postState,
    } = this.props;
    setOrganization(value);
    postState();
    this.picker.doClose();
  };

  onVisibilityChange = () => {
    const { onVisibilityChange } = this.props;
    this.selector.onShow();
    if (onVisibilityChange) {
      onVisibilityChange(true);
    }
  };

  render() {
    const { organization, organizations, memberOfOrganization } = this.props;

    let value = defaultOrg;
    let pickerValue = i18n.t('selectOrganization');
    let isDefaultOrg = true;

    const searchFilterLabel = i18n.t('organizationSelectorPlaceholder');

    if (organization && organization.id !== undefined && organizations) {
      value = organization.id;
      if (!organization.defaultOrganization) {
        pickerValue = organization.name;
        isDefaultOrg = false;
      }
    }

    return (
      <Picker
        value={pickerValue}
        className="attachLeft orgPicker"
        role="button"
        ref={(self) => (this.picker = self)}
        onVisibilityChange={this.onVisibilityChange}
        open={this.props.open}
        highlight={isDefaultOrg}
        memberOfOrganization={memberOfOrganization}
        {...this.props}
      >
        <SearchSelector
          ref={(self) => (this.selector = self)}
          selection={value}
          dataSrc={organizations}
          idPropName="id"
          displayPropName="name"
          hiddenPropName="hidden"
          idFormatter={safeNum}
          onChange={this.handleChange}
          searchFilterLabel={searchFilterLabel}
        >
          <div className="section orgInfo">{i18nWrapper.t('orgSelectorInfo')}</div>
        </SearchSelector>
      </Picker>
    );
  }
}

OrgPicker.displayName = 'OrgPicker';

OrgPicker.propTypes = {
  organizations: PropTypes.array.isRequired,
  organization: PropTypes.object,
  open: PropTypes.bool,
  memberOfOrganization: PropTypes.bool.isRequired,
  onVisibilityChange: PropTypes.func.isRequired,
  setOrganization: PropTypes.func.isRequired,
  postState: PropTypes.func.isRequired,
};

OrgPicker.defaultProps = {
  organization: {},
  open: false,
};

const mapStateToProps = (state) => ({
  organizations: getOrganizations(state),
  organization: getOrganization(state),
  memberOfOrganization: isMemberOfOrganization(state),
});

const mapDispatchToProps = (dispatch) => ({
  setOrganization: (org) => dispatch(setOrganization(org)),
  postState: () => purchaseApi.postState(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgPicker);
