import * as React from 'react';
import * as PropTypes from 'prop-types';

import { verifyCampaignCode } from '../../api/campaignApi';

const CHECKMARK_CHAR = String.fromCharCode(10003);

export class CampaignCode extends React.Component {
  static displayName = 'CampaignCode';

  static propTypes = {
    campaignCodeValid: PropTypes.bool.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    campaignDescription: PropTypes.string,
    campaignCode: PropTypes.string,
  };

  static contextTypes = {
    store: PropTypes.object,
  };

  handleChange = (e) => {
    const { value } = e.target;
    const { store } = this.context;
    verifyCampaignCode(store, value);
  };

  render() {
    const {
      campaignCodeValid, id, label, campaignDescription, campaignCode,
    } = this.props;
    const inputSymbol = campaignCodeValid ? CHECKMARK_CHAR : undefined;
    const inputElement = campaignCodeValid
      ? (
        <span className="input-group-addon">
          {inputSymbol}
        </span>
      )
      : null;

    const campaignDescriptionElement = campaignCodeValid
      ? (<span>{campaignDescription}</span>)
      : null;

    const labelId = `${id}-label`;

    return (
      <div className="campaignCode">
        <div className="form-group" data-marker="campaignCode">
          <label className="control-label" htmlFor="campaignCode" id={labelId}>
            <span>{label}</span>
          </label>
          <div className="form-entry">
            <div className="input-group">
              <input
                key={id}
                id={id}
                type="text"
                onChange={this.handleChange}
                className="form-control"
                value={campaignCode}
                aria-labelledby={labelId}
              />
              {inputElement}
            </div>
          </div>
          <div id="campaignDescription">
            {campaignDescriptionElement}
          </div>
        </div>
      </div>
    );
  }
}
