import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import i18n from '../localization/i18n-wrapper';
import ChildAgePicker from './childAgePicker';
import { getChildrenFrom0to9, getChildrenFrom10to12, getChildrenFrom13to17 } from '../redux/reducers/selectedReducer';
import {
  getNormalChildFrom0To9PerMonth,
  getNormalChildFrom10To12PerMonth,
  getNormalChildFrom13To17PerMonth,
} from '../redux/reducers/priceReducer';

class ChildSelector extends React.Component {
  static displayName = 'ChildSelector';

  static propTypes = {
    price0to9: PropTypes.number.isRequired,
    price10to12: PropTypes.number.isRequired,
    price13to17: PropTypes.number.isRequired,
    childrenFrom0to9: PropTypes.number.isRequired,
    childrenFrom10to12: PropTypes.number.isRequired,
    childrenFrom13to17: PropTypes.number.isRequired,
    childInsurancePossible: PropTypes.bool,
  };

  render = () => {
    const { childrenFrom0to9, childrenFrom10to12, childrenFrom13to17 } = this.props;
    const { price0to9, price10to12, price13to17 } = this.props;

    return (
      <div className="childPickerBody">
        <div className="section">{i18n.t('childSelectorInfo')}</div>
        <ChildAgePicker
          label={i18n.t('childSelectorUnder10')}
          amount={childrenFrom0to9}
          propertyName="childrenFrom0to9"
          price={price0to9}
        />
        <ChildAgePicker
          label={i18n.t('childSelectorFrom10To12')}
          amount={childrenFrom10to12}
          propertyName="childrenFrom10to12"
          price={price10to12}
        />
        <ChildAgePicker
          label={i18n.t('childSelectorFrom13To17')}
          amount={childrenFrom13to17}
          propertyName="childrenFrom13to17"
          price={price13to17}
        />
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  childrenFrom0to9: getChildrenFrom0to9(state),
  childrenFrom10to12: getChildrenFrom10to12(state),
  childrenFrom13to17: getChildrenFrom13to17(state),
  price0to9: getNormalChildFrom0To9PerMonth(state),
  price10to12: getNormalChildFrom10To12PerMonth(state),
  price13to17: getNormalChildFrom13To17PerMonth(state),
});

export default connect(mapStateToProps)(ChildSelector);
