import i18n from 'i18next-client';

export const createQuestionsWithAnswers = (questionLocalizationKeys, oldQuestions) => {
  const questionsWithAnswers = questionLocalizationKeys.map((localizationKeys) => {
    const oldQuestion = findMatchingQuestion(localizationKeys.key, oldQuestions);
    const oldAnswer = oldQuestion && oldQuestion.answer;
    return questionToObject(localizationKeys.key, localizationKeys.infoKey, oldAnswer);
  });
  return questionsWithAnswers;
};

export const questionToObject = (questionKey, infoKey, answer) => {
  const questionWithAnswer = {
    key: {
      questionKey,
      ref: i18n.t && i18n.t('__ref'),
    },
    infoKey,
    answer,
  };
  return questionWithAnswer;
};

const findMatchingQuestion = (questionKey, questions) => {
  if (questions === undefined) {
    return undefined;
  }
  return questions.find((q) => q.key.questionKey === questionKey);
};
