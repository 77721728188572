import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { navigate } from 'react-mini-router';
import Info from '../common/info';
import OrgPicker from '../calculator/orgPicker';
import PurchaseAgeSelector from './ageSelector';

import ChildSelector from '../calculator/childSelector';
import i18n from '../localization/i18n-wrapper';

import BackButton from '../common/backButton';
import ContinueButton from '../common/continueButton';
import ProductContainer from '../products/productContainer';
import {
  getCustomerAge,
  getSpouseAge,
  getCustomerProducts,
  getSpouseProducts,
  getChildCount,
  isMemberOfOrganization,
  isChildInsurancePossible,
  isDefaultOrganization,
} from '../redux/reducers/selectedReducer';
import { isSpouseDifferent } from '../redux/reducers/purchaseReducer';
import { setPageAndPriceVisibility } from '../redux/actions/purchaseActions';
import purchaseApi from '../api/purchaseApi';
import { toggleMemberOfOrganization } from '../redux/actions/selectedActions';
import { getCookie, setCookie } from '../util/cookie';
import { ProductLayout } from '../models/productLayout';

const getPurchaseAgeSelectionHeaderText = (productLayout) => (
  productLayout === ProductLayout.childInsurancesFirst
    ? i18n.t('purchaseAgeSelectionHeaderAlt') : i18n.t('purchaseAgeSelectionHeader'));

const getChildProductsHeaderText = (productLayout) => (
  productLayout === ProductLayout.childInsurancesFirst
    ? i18n.t('purchaseChildInsuranceSelectionHeaderAlt') : i18n.t('purchaseChildInsuranceSelectionHeader'));

const getAdultProductsHeaderText = (productLayout, options) => {
  const {
    spouseDifferent, customerAge, memberOfOrganization, spouseAge,
  } = options;

  if (spouseAge && spouseDifferent) {
    return i18n.t('purchaseProductSelectionHeaderOnlyMe');
  }

  if (spouseAge && !customerAge) {
    return i18n.t('purchaseProductSelectionHeaderSpouse');
  }

  if (spouseAge && !spouseDifferent && memberOfOrganization) {
    return i18n.t('purchaseProductSelectionHeaderBoth');
  }

  if (productLayout === ProductLayout.childInsurancesFirst) {
    return i18n.t('purchaseProductSelectionHeaderAlt');
  }

  return i18n.t('purchaseProductSelectionHeader');
};

class ProductPage extends React.Component {
  constructor() {
    super();
    this.state = { orgOpen: false };
  }

  componentDidMount() {
    document.getElementById('purchaseContent').scrollTop = 0;
    setPageAndPriceVisibility();
  }

  onOrgVisibilityChange = (visible) => {
    this.setState({ orgOpen: visible });
  };

  handleContinue = (event) => {
    event.preventDefault();
    event.stopPropagation();

    // validate that the user has selected at least one product
    if (this.anyProductSelected()) {
      this.props.postState();
      navigate('/2a');
    }
  };

  handleBack = (event) => {
    event.preventDefault();
    event.stopPropagation();
    window.location.href = './';
  };

  adultProductCount = () => {
    const {
      customerAge, spouseAge, customerProducts, spouseProducts,
    } = this.props;

    const productCount = ((customerAge && customerProducts.length) || 0) + ((spouseAge && spouseProducts.length) || 0);

    return productCount;
  };

  adultAccidentalProductCount = () => {
    const {
      customerProducts, spouseProducts,
    } = this.props;
    const productlist = [].concat(customerProducts).concat(spouseProducts);
    const accidentalProducts = productlist.filter((product) => product !== 'LIFE' && product !== 'CI');
    return accidentalProducts.length;
  };

  anyProductSelected = () => {
    const {
      childCount,
    } = this.props;

    return this.adultProductCount() || childCount;
  };

  setProductLayout = () => {
    const {
      childCount,
    } = this.props;
    const onlyChildProducts = this.adultProductCount() === 0 && childCount > 0;
    const onlyAdultAccidentalProducts = this.adultAccidentalProductCount() === this.adultProductCount()
      && childCount === 0;

    let productLayout;
    if (onlyChildProducts) {
      productLayout = ProductLayout.childInsurancesFirst;
    } else if (onlyAdultAccidentalProducts) {
      productLayout = ProductLayout.accidentalInsurancesFirst;
    } else {
      productLayout = ProductLayout.normal;
    }
    setCookie('productLayout', productLayout);
    return productLayout;
  }

  getProductLayout = () => {
    let productLayout = getCookie('productLayout');
    if (!productLayout) {
      productLayout = this.setProductLayout();
    }
    return productLayout;
  }

  handleChkNoOrganizationChange = () => {
    this.props.toggleMemberOfOrganization();
    this.props.postState();
  };

  render() {
    const self = this;
    const {
      customerAge, spouseAge, memberOfOrganization, defaultOrganization, childInsurancePossible,
    } = this.props;
    const productLayout = this.getProductLayout();
    const continueError = this.anyProductSelected() ? '' : i18n.t('purchaseNoProductsSelected');
    const adultProducts = [];

    const childProducts = [];

    if (customerAge || spouseAge) {
      const header = getAdultProductsHeaderText(productLayout, this.props);
      adultProducts.push(
        <CSSTransition classNames="products" timeout={{ enter: 500, exit: 300 }} key="productSelection">
          <div className="purchaseSection">
            <h2>{header}</h2>
            <div className="productSelector">
              <ProductContainer key="productContainer" identifier="purchase" />
            </div>
          </div>
        </CSSTransition>,
      );
    }

    const disabled = defaultOrganization === true && memberOfOrganization === true;

    const continueLabel = disabled ? i18n.t('continueButtonMissingOrg') : i18n.t('continueButton');

    const ciInfo = i18n.raw('childInfoKey');

    const info = <Info>{ciInfo}</Info>;

    if (childInsurancePossible) {
      childProducts.push(
        <CSSTransition classNames="products" timeout={{ enter: 500, exit: 300 }} key="childProductSelection">
          <div className="purchaseSection">
            <h2>
              { getChildProductsHeaderText(productLayout) }
              <div className="infoContainer">{info}</div>
            </h2>
            <ChildSelector />
          </div>
        </CSSTransition>,
      );
    }

    const organizationAndAgePart = (
      <div className="purchaseSection">
        <h2>{ getPurchaseAgeSelectionHeaderText(productLayout) }</h2>
        <div className="orgSelector form-group">
          <label className="title control-label">{i18n.t('purchaseOrganizationSelectorLabel')}</label>
          <div className="organization">
            <OrgPicker
              disabled={!memberOfOrganization}
              onVisibilityChange={self.onOrgVisibilityChange}
              open={self.state.orgOpen}
            />
            <div className="clsPreFooter">
              <input
                type="checkbox"
                id="chkNoOrganization"
                name="chkNoOrganization"
                onChange={this.handleChkNoOrganizationChange}
                value="memberOfOrganization"
                checked={!memberOfOrganization}
                aria-labelledby="orgCheckBox"
              />
              <span id="orgCheckBox">{i18n.t('labTxtDoNotBelongToOrg')}</span>
            </div>
          </div>
        </div>
        <PurchaseAgeSelector />
      </div>
    );

    const adultProductPart = (
      <TransitionGroup>
        {adultProducts}
      </TransitionGroup>
    );

    const childProductsPart = (
      <TransitionGroup>
        {childProducts}
      </TransitionGroup>
    );

    const content = productLayout === ProductLayout.childInsurancesFirst
      ? (
        <>
          {childProductsPart}
          {organizationAndAgePart}
          {adultProductPart}
        </>
      )
      : (
        <>
          {organizationAndAgePart}
          {adultProductPart}
          {childProductsPart}
        </>
      );

    return (
      <form className="form-horizontal">
        {content}
        <BackButton onClick={this.handleBack} />
        <ContinueButton onClick={this.handleContinue} label={continueLabel} error={continueError} disabled={disabled} />
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  customerAge: getCustomerAge(state),
  spouseAge: getSpouseAge(state),
  customerProducts: getCustomerProducts(state),
  spouseProducts: getSpouseProducts(state),
  childCount: getChildCount(state),
  childInsurancePossible: isChildInsurancePossible(state),
  memberOfOrganization: isMemberOfOrganization(state),
  defaultOrganization: isDefaultOrganization(state),
  spouseDifferent: isSpouseDifferent(state),
});

const mapDispatchToProps = (dispatch) => ({
  setPageAndPriceVisibility: dispatch(setPageAndPriceVisibility('product', true)),
  toggleMemberOfOrganization: () => dispatch(toggleMemberOfOrganization()),
  postState: () => purchaseApi.postState(dispatch),
});

ProductPage.displayName = 'ProductPage';

ProductPage.propTypes = {
  customerAge: PropTypes.number,
  spouseAge: PropTypes.number,
  customerProducts: PropTypes.arrayOf(PropTypes.string).isRequired,
  spouseProducts: PropTypes.arrayOf(PropTypes.string).isRequired,
  childCount: PropTypes.number.isRequired,
  memberOfOrganization: PropTypes.bool.isRequired,
  defaultOrganization: PropTypes.bool.isRequired,
  spouseDifferent: PropTypes.bool.isRequired,
  childInsurancePossible: PropTypes.bool.isRequired,
  toggleMemberOfOrganization: PropTypes.func.isRequired,
  postState: PropTypes.func.isRequired,
};

ProductPage.defaultProps = {
  customerAge: undefined,
  spouseAge: undefined,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductPage);
