import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import * as classNames from 'classnames';
import { postState } from '../api/purchaseApi';
import { post } from '../util/http';

import i18n from '../localization/i18n-wrapper';

import { verifyAgeIsCorrect } from './thankYouHelper';
import PurchaseModel from '../models/purchaseModel';
import { getCustomerSocialSecurityNumber, getPurchase } from '../redux/reducers/purchaseReducer';
import { getCustomerAge, getSelected } from '../redux/reducers/selectedReducer';

const PurchaseButton = (props) => {
  let errors;

  const classes = {
    btn: true,
  };

  const hasError = !!props.error;

  if (hasError) {
    errors = <div className="error">{props.error}</div>;
  } else {
    classes['btn-active'] = true;
    errors = <div className="error" />;
  }

  return (
    <div className="results">
      <button className={classNames(classes)} type="button" onClick={props.onClick} disabled={hasError}>
        {i18n.t('startThankYouPurchaseFlow')}
      </button>
      {errors}
    </div>
  );
};

PurchaseButton.displayName = 'PurchaseButton';

PurchaseButton.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func.isRequired,
};

class ThankYouPurchase extends React.Component {
  static displayName = 'ThankYouPurchase';

  static propTypes = {
    customerSocialSecurityNumber: PropTypes.string.isRequired,
    customerAge: PropTypes.number.isRequired,
    postState: PropTypes.func.isRequired,
    getApprovalQuestions: PropTypes.func.isRequired,
    getHealthQuestions: PropTypes.func.isRequired,
    purchaseUrl: PropTypes.string.isRequired,
    validationFilter: PropTypes.func,
    purchase: PropTypes.object.isRequired,
    selected: PropTypes.object.isRequired,
  };

  static defaultProps = {
    validationFilter: () => {},
    getHealthQuestions: () => [],
  }

  static contextTypes = {
    store: PropTypes.object,
  };

  onChange() {
    this.forceUpdate();
  }

  handlePurchase = () => {
    if (this.hasUnansweredQuestions()) {
      this.setState({
        strict: true,
      });
    } else {
      const { store } = this.context;
      const { purchaseUrl } = this.props;
      this.props.postState().then(() => {
        post(purchaseUrl, store.getState()).then(
          (response) => {
            window.location.href = response.data;
          },
          () => {
            window.location.href = '/virhe';
          },
        );
      });
    }
  };

  hasUnansweredQuestions() {
    const { approvalQuestions, healthQuestions } = this.props;
    return approvalQuestions.find((q) => !q.answer) !== undefined
      || (healthQuestions && healthQuestions.find((q) => !q.answer) !== undefined);
  }

  render() {
    const { purchase, selected, validationFilter } = this.props;

    const purchaseModel = new PurchaseModel(purchase);
    const context = {
      purchase,
      selected,
    };
    const validationErrors = purchaseModel.validate(context, false, validationFilter);

    let error;
    if (this.hasUnansweredQuestions() || Object.keys(validationErrors).length !== 0) {
      error = i18n.t('missingApprovalAnswer');
    } else {
      error = verifyAgeIsCorrect(this.props.customerSocialSecurityNumber, parseInt(this.props.customerAge, 10));
    }

    return (
      <div className="result">
        <div className="purchaseButton">
          <PurchaseButton error={error} onClick={this.handlePurchase} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  customerSocialSecurityNumber: getCustomerSocialSecurityNumber(state),
  customerAge: getCustomerAge(state),
  approvalQuestions: ownProps.getApprovalQuestions(state),
  healthQuestions: ownProps.getHealthQuestions && ownProps.getHealthQuestions(state),
  purchase: getPurchase(state),
  selected: getSelected(state),
});

const mapDispatchToProps = (dispatch) => ({
  postState: () => postState(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThankYouPurchase);
