import { get } from '../util/http';
import { postState } from './purchaseApi';
import {
  setCampaignDescription,
  setCampaignActive,
  setCampaignCodeValid,
} from '../redux/actions/selectedActions';
import { setCampaignCode, setCampaignType } from '../redux/actions/purchaseActions';

export const verifyCampaignCode = async (store, campaignCode) => {
  try {
    const campaignData = await get(`findCampaignData?campaignCode=${campaignCode}`);
    const { campaignDescription, campaignType } = campaignData.data;
    store.dispatch(setCampaignDescription(campaignDescription));
    store.dispatch(setCampaignCodeValid(true));
    store.dispatch(setCampaignCode(campaignCode));
    store.dispatch(setCampaignType(campaignType));
    postState(store.dispatch);
  } catch (err) {
    store.dispatch(setCampaignCodeValid(false));
    store.dispatch(setCampaignCode(campaignCode));
    postState(store.dispatch);
  }
};

export const loadCampaign = async (store) => {
  const campaignResponse = await get('/campaigns');
  const campaignActive = campaignResponse.data.includes('GIFT') || campaignResponse.data.includes('FREESTART');
  store.dispatch(setCampaignActive(campaignActive));
};
