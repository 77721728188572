export const SET_CUSTOMER_AGE = 'SET_CUSTOMER_AGE';
export const SET_CUSTOMER_AGE_PREVIOUS = 'SET_CUSTOMER_AGE_PREVIOUS';
export const SET_SPOUSE_AGE = 'SET_SPOUSE_AGE';
export const SET_SPOUSE_AGE_PREVIOUS = 'SET_SPOUSE_AGE_PREVIOUS';
export const SET_SPOUSE_DIFFERENT = 'SET_SPOUSE_DIFFERENT';
export const SET_SPOUSE_PRESENT = 'SET_SPOUSE_PRESENT';
export const SET_COUPLE_INSURANCE = 'SET_COUPLE_INSURANCE';
export const SET_ORGANIZATION = 'SET_ORGANIZATION';
export const SET_MEMBER_OF_ORGANIZATION = 'SET_MEMBER_OF_ORGANIZATION';
export const SET_CUSTOMER_PRODUCTS = 'SET_CUSTOMER_PRODUCTS';
export const SET_SPOUSE_PRODUCTS = 'SET_SPOUSE_PRODUCTS';
export const SET_COMPENSATION = 'SET_COMPENSATION';
export const SET_CHILDREN = 'SET_CHILDREN';
export const SET_BILLS_PER_YEAR = 'SET_BILLS_PER_YEAR';
export const SET_CAMPAIGN_DESCRIPTION = 'SET_CAMPAIGN_DESCRIPTION';
export const SET_CAMPAIGN_ACTIVE = 'SET_CAMPAIGN_ACTIVE';
export const SET_CAMPAIGN_CODE = 'SET_CAMPAIGN_CODE';
export const SET_CAMPAIGN_CODE_VALID = 'SET_CAMPAIGN_CODE_VALID';
export const SET_CAMPAIGN_TYPE = 'SET_CAMPAIGN_TYPE';
export const SET_DATA_FIELD = 'SET_DATA_FIELD';
export const SET_CUSTOMER_MUNICIPALITY = 'SET_CUSTOMER_MUNICIPALITY';
export const SET_SPOUSE_MUNICIPALITY = 'SET_SPOUSE_MUNICIPALITY';
export const SET_CUSTOMER_COMPENSATION = 'SET_CUSTOMER_COMPENSATION';
export const SET_SPOUSE_COMPENSATION = 'SET_SPOUSE_COMPENSATION';

export const SET_YESNOQUESTION_ANSWER = 'SET_YESNOQUESTION_ANSWER';
export const SET_CHECKBOX_ANSWER = 'SET_CHECKBOX_ANSWER';
export const SET_CUSTOMER_BENEFICIARY = 'SET_CUSTOMER_BENEFICIARY';
export const SET_SPOUSE_BENEFICIARY = 'SET_SPOUSE_BENEFICIARY';
export const SET_SPOUSE_VASTUS_STATUS = 'SET_SPOUSE_VASTUS_STATUS';
export const SET_HEALTH_QUESTIONS = 'SET_HEALTH_QUESTIONS';
export const SET_CUSTOMER_OPTIONAL_HEALTH_QUESTIONS = 'SET_CUSTOMER_OPTIONAL_HEALTH_QUESTIONS';
export const SET_SPOUSE_HEALTH_QUESTIONS = 'SET_SPOUSE_HEALTH_QUESTIONS';
export const SET_SPOUSE_OPTIONAL_HEALTH_QUESTIONS = 'SET_SPOUSE_OPTIONAL_HEALTH_QUESTIONS';
export const SET_APPROVAL_QUESTIONS = 'SET_APPROVAL_QUESTIONS';
export const SET_CURRENT_PAGE_AND_PRICE_VISIBILITY = 'SET_CURRENT_PAGE_AND_PRICE_VISIBILITY';

export const SET_ORGANIZATION_LIST = 'SET_ORGANIZATION_LIST';
export const SET_POST_OFFICES = 'SET_POST_OFFICES';
export const SET_ORGANIZATION_REDIRECTS = 'SET_ORGANIZATION_REDIRECTS';

export const SET_PRICES = 'SET_PRICES';

export const SET_CUSTOMER_AVAILABLE_PRODUCTS = 'SET_CUSTOMER_AVAILABLE_PRODUCTS';
export const SET_SPOUSE_AVAILABLE_PRODUCTS = 'SET_SPOUSE_AVAILABLE_PRODUCTS';

export const ADD_CUSTOMER_PRODUCT = 'ADD_CUSTOMER_PRODUCT';
export const ADD_SPOUSE_PRODUCT = 'ADD_SPOUSE_PRODUCT';
export const REMOVE_CUSTOMER_PRODUCT = 'REMOVE_CUSTOMER_PRODUCT';
export const REMOVE_SPOUSE_PRODUCT = 'REMOVE_SPOUSE_PRODUCT';
export const REMOVE_ALL_CUSTOMER_PRODUCTS = 'REMOVE_ALL_CUSTOMER_PRODUCTS';
export const REMOVE_ALL_SPOUSE_PRODUCTS = 'REMOVE_ALL_SPOUSE_PRODUCTS';

export const SET_SPOUSE_SMOKING = 'SET_SPOUSE_SMOKING';
export const SET_CUSTOMER_SMOKING = 'SET_CUSTOMER_SMOKING';

export const SET_NEED_FIELD = 'SET_NEED_FIELD';
export const SET_RESULT = 'SET_RESULT';

export const SET_CUSTOMER_CI_COMPENSATION = 'SET_CUSTOMER_CI_COMPENSATION';
export const SET_SPOUSE_CI_COMPENSATION = 'SET_SPOUSE_CI_COMPENSATION';
export const SET_CI_COMPENSATIONS = 'SET_CI_COMPENSATIONS';
export const SET_CI_APPROVAL_QUESTIONS = 'SET_CI_APPROVAL_QUESTIONS';

export const SET_LANG = 'SET_LANG';
// export const SET_ORGANIZATION_DATA = 'SET_ORGANIZATION_DATA';
