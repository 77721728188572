import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import get from 'lodash/get';
import range from 'lodash/range';
import i18n from '../localization/i18n-wrapper';
import I18n from '../localization/I18n';
import Checkbox from '../common/checkbox';
import { setCheckboxAnswer } from '../redux/actions/purchaseActions';
import { getPurchase } from '../redux/reducers/purchaseReducer';

class ThankYouApproval extends React.Component {
  static displayName = 'ThankYouApproval';

  static propTypes = {
    purchase: PropTypes.object.isRequired,
    header: PropTypes.string,
    subHeader: PropTypes.string,
    path: PropTypes.string.isRequired,
    setCheckboxAnswer: PropTypes.func.isRequired,
    getApprovalQuestions: PropTypes.func.isRequired,
    approvalQuestions: PropTypes.array.isRequired,
  };

  static defaultProps = {
    header: null,
    subHeader: null,
  };

  handleChange = (checked, path) => {
    this.props.setCheckboxAnswer(...path, checked);
  };

  render() {
    const {
      header, subHeader, approvalQuestions, path,
    } = this.props;

    const headerEl = header
      ? (<h2 key="insuranceApprovalHeader">{i18n.t(header)}</h2>) : null;
    const subHeaderEl = subHeader
      ? (<h4 key="insuranceApprovalSubHeader">{i18n.t(subHeader)}</h4>) : null;

    const content = [
      headerEl,
      subHeaderEl,
      ...range(0, approvalQuestions.length).map(function (index) {
        const questionPath = [path, index];
        const q = get(this.props.purchase, questionPath);
        const key = `thankYouApprovalCheckbox${index}`;
        return (
          <Checkbox
            key={key}
            marker={q.key.questionKey}
            value={!!q.answer}
            onChange={(event) => this.handleChange(event, questionPath)}
          >
            <I18n i18nKey={q.key.questionKey} />
          </Checkbox>
        );
      }, this),
    ];

    return <div className="thankYouPurchaseSection">{content}</div>;
  }
}

const mapStateToProps = (state, ownProps) => ({
  approvalQuestions: ownProps.getApprovalQuestions(state),
  purchase: getPurchase(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCheckboxAnswer: (category, index, answer) => dispatch(setCheckboxAnswer(category, index, answer)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ThankYouApproval);
