import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import values from 'lodash/values';

import { navigate } from 'react-mini-router';
import CustomerAndChildQuestions from './customerAndChildQuestions';
import CustomerAndChildDetails from './customerAndChildDetails';

import utils from '../util/utils';

import i18n from '../localization/i18n-wrapper';

import ContinueButton from '../common/continueButton';
import BackButton from '../common/backButton';
import purchaseApi from '../api/purchaseApi';
import {
  getChildrenFrom0to9,
  getChildrenFrom10to12,
  getChildrenFrom13to17,
  getSpouseAge,
  getSelected,
  customerHasProduct,
  getOrganization,
} from '../redux/reducers/selectedReducer';
import { getChildren, getPurchase, getCustomerQuestions } from '../redux/reducers/purchaseReducer';
import PurchaseModel from '../models/purchaseModel';
import { setPageAndPriceVisibility } from '../redux/actions/purchaseActions';

import { getNormalTotalPerMonth } from '../redux/reducers/priceReducer';

function validationFilter(path) {
  return ['customer', 'customerBeneficiaryDetails', 'children'].indexOf(path[0]) > -1;
}

class CustomerAndChildrenPage extends React.Component {
  state = {
    strict: false,
  };

  getUnansweredQuestions = (questions = []) => {
    const result = questions.reduce((acc, q) => acc + (q.answer ? 0 : 1), 0);
    return result;
  };

  getProblems = () => {
    const {
      childrenFrom0to9,
      childrenFrom10to12,
      childrenFrom13to17,
      children,
      purchase,
      selected,
      organization,
      hasCi,
    } = this.props;
    const { strict } = this.state;

    const purchaseModel = new PurchaseModel(purchase);
    const context = {
      purchase,
      selected,
      organization,
    };
    const relaxedErrors = purchaseModel.validate(context, !strict, validationFilter);
    const strictErrors = strict ? relaxedErrors : purchaseModel.validate(context, false, validationFilter);

    const unansweredQuestions = this.getUnansweredQuestions(purchase.customerQuestions);
    const unansweredOptionalQuestions = hasCi ? this.getUnansweredQuestions(purchase.optionalCustomerQuestions) : 0;

    // check child ages!
    const childAges = map(children, 'socialSecurityNumber')
      .map(utils.calculateAge)
      .valueOf();

    const childAgeCounts = {
      _0to9: 0,
      _10to12: 0,
      _13to17: 0,
    };

    const childErrors = {};

    childAges.forEach((age, index) => {
      if (age) {
        if (age < 10) {
          childAgeCounts._0to9 += 1;
          if (childAgeCounts._0to9 > childrenFrom0to9) {
            childErrors[index] = i18n.t('errorChildAgeCountMismatch', {
              range: '0–9',
            });
          }
        } else if (age < 13) {
          childAgeCounts._10to12 += 1;
          if (childAgeCounts._10to12 > childrenFrom10to12) {
            childErrors[index] = i18n.t('errorChildAgeCountMismatch', {
              range: '10–12',
            });
          }
        } else if (age < 18) {
          childAgeCounts._13to17 += 1;
          if (childAgeCounts._13to17 > childrenFrom13to17) {
            childErrors[index] = i18n.t('errorChildAgeCountMismatch', {
              range: '13–17',
            });
          }
        } else {
          childErrors[index] = i18n.t('errorChildOver18');
        }
      }
    });

    return {
      relaxedErrors,
      strictErrors,
      childErrors,
      unansweredQuestions,
      unansweredOptionalQuestions,
    };
  };

  handleContinue = (event) => {
    event.preventDefault();
    event.stopPropagation();

    // are we valid?

    const problems = this.getProblems();

    // no!
    if (!isEmpty(problems.strictErrors)) {
      this.setState({
        strict: true,
      });
    } else if (
      !(
        problems.unansweredQuestions > 0
        || problems.unansweredOptionalQuestions > 0
        || !isEmpty(problems.childErrors)
      )
    ) {
      const { spouseAge } = this.props;

      this.props.postState(); // TODO: needed here?

      if (spouseAge) {
        navigate('/2b');
      } else {
        navigate('/3');
      }
    }
  };

  handleBack = (event) => {
    event.preventDefault();
    event.stopPropagation();
    navigate('/');
  };

  componentDidMount() {
    document.getElementById('purchaseContent').scrollTop = 0;
    setPageAndPriceVisibility();
  }

  render() {
    let error;

    const problems = this.getProblems();

    if (!isEmpty(problems.strictErrors)) {
      error = i18n.t('unansweredQuestions');
    } else if (problems.unansweredQuestions > 0 || problems.unansweredOptionalQuestions > 0) {
      error = i18n.t('missingHealthCheckAnswer');
    } else if (!isEmpty(problems.childErrors)) {
      error = size(problems.childErrors) > 1 ? i18n.t('childDetailsErrors') : values(problems.childErrors)[0];
    }

    const hasPrice = this.props.totalPrice > 0;

    return (
      <div className="customerAndChildrenPage">
        <CustomerAndChildDetails errors={problems.relaxedErrors} childErrors={problems.childErrors} />
        <CustomerAndChildQuestions errors={problems.relaxedErrors} />

        <BackButton onClick={this.handleBack} />
        <ContinueButton disabled={!hasPrice} error={error} onClick={this.handleContinue} />
      </div>
    );
  }
}

CustomerAndChildrenPage.displayName = 'CustomerAndChildrenPage';

CustomerAndChildrenPage.propTypes = {
  spouseAge: PropTypes.number,
  totalPrice: PropTypes.number.isRequired,
  childrenFrom0to9: PropTypes.number.isRequired,
  childrenFrom10to12: PropTypes.number.isRequired,
  childrenFrom13to17: PropTypes.number.isRequired,
  children: PropTypes.array.isRequired,
  purchase: PropTypes.object.isRequired,
  selected: PropTypes.object.isRequired,
  organization: PropTypes.object,
  hasCi: PropTypes.bool.isRequired,
  postState: PropTypes.func.isRequired,
};

CustomerAndChildrenPage.defaultProps = {
  spouseAge: undefined,
  organization: {},
};

const mapStateToProps = (state) => ({
  childrenFrom0to9: getChildrenFrom0to9(state),
  childrenFrom10to12: getChildrenFrom10to12(state),
  childrenFrom13to17: getChildrenFrom13to17(state),
  children: getChildren(state),
  spouseAge: getSpouseAge(state),
  customerQuestions: getCustomerQuestions(state),
  purchase: getPurchase(state),
  selected: getSelected(state),
  organization: getOrganization(state),
  hasCi: customerHasProduct(state, 'CI'),
  totalPrice: getNormalTotalPerMonth(state),
});

const mapDispatchToProps = (dispatch) => ({
  setPageAndPriceVisibility: dispatch(setPageAndPriceVisibility('customerAndChildren', true)),
  postState: () => purchaseApi.postState(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerAndChildrenPage);
