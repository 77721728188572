import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import i18n from '../localization/i18n-wrapper';

import LifeProduct from './lifeProduct';
import AccidentProduct from './accidentProducts';
import CIProduct from './ciProduct';

import { getCookie } from '../util/cookie';

import {
  isMemberOfOrganization,
  isCoupleInsuranceSelected,
  getCustomerProducts,
  getSpouseProducts,
  hasCustomer,
  hasSpouse,
  isAccidentalInsurancePossible,
} from '../redux/reducers/selectedReducer';
import { isSpouseDifferent } from '../redux/reducers/purchaseReducer';
import { toggleSpouseDifferent } from '../redux/actions/selectedActions';
import {
  getCustomerAvailableProducts,
  isProductAvailableForCustomer,
} from '../redux/reducers/customerAvailableProductsReducer';
import {
  getSpouseAvailableProducts,
  isProductAvailableForSpouse,
} from '../redux/reducers/spouseAvailableProductsReducer';
import { ProductLayout } from '../models/productLayout';

class ProductContainer extends React.Component {
  static displayName = 'ProductContainer';

  static propTypes = {
    context: PropTypes.string,
    analyticsIdentifier: PropTypes.string,
    customerAvailableProducts: PropTypes.array.isRequired,
    spouseAvailableProducts: PropTypes.array.isRequired,
    customerProducts: PropTypes.array.isRequired,
    spouseProducts: PropTypes.array.isRequired,
    ciAvailableForCustomer: PropTypes.bool.isRequired,
    ciAvailableForSpouse: PropTypes.bool.isRequired,
    accidentalInsuranceAvailable: PropTypes.bool.isRequired,
    memberOfOrganization: PropTypes.bool.isRequired,
    coupleInsuranceSelected: PropTypes.bool.isRequired,
    spouseDifferent: PropTypes.bool.isRequired,
    handleSeparateForSpouse: PropTypes.func.isRequired,
    hasCustomer: PropTypes.bool.isRequired,
    hasSpouse: PropTypes.bool.isRequired,
  };

  getProductElements(lifeProduct, ciProduct, accidentalProducts) {
    const { context } = this.props;
    const productLayout = getCookie('productLayout');
    const elements = [];
    if (context !== 'calculator'
      && productLayout === ProductLayout.accidentalInsurancesFirst) {
      elements.push(accidentalProducts);
      elements.push(lifeProduct);
      elements.push(ciProduct);
    } else {
      elements.push(lifeProduct);
      elements.push(ciProduct);
      elements.push(accidentalProducts);
    }
    return elements;
  }

  render() {
    const {
      customerAvailableProducts, customerProducts, ciAvailableForCustomer, accidentalInsuranceAvailable,
    } = this.props;
    const {
      hasCustomer, hasSpouse, spouseDifferent, coupleInsuranceSelected, memberOfOrganization,
    } = this.props;
    const { context } = this.props;
    const productLayout = getCookie('productLayout');
    const lifeInsuranceTitleNeedTitle = productLayout === ProductLayout.accidentalInsurancesFirst;
    const lifeProduct = (<LifeProduct analyticsIdentifier="customer" context={context} showInsuranceTitle={lifeInsuranceTitleNeedTitle} key="customerLifeProduct" />);
    const ciProduct = ciAvailableForCustomer
      ? (<CIProduct context={context} analyticsIdentifier="customer" key="customerCIProduct" />)
      : [];
    const accidentalProducts = accidentalInsuranceAvailable ? (
      <AccidentProduct
        availableProducts={customerAvailableProducts}
        selectedProducts={customerProducts}
        analyticsIdentifier="customer"
        key="customerAccidentProduct"
      />
    ) : <></>;

    const elements = this.getProductElements(lifeProduct, ciProduct, accidentalProducts);

    // this is not shown when products are in calculator view
    if (context !== 'calculator' && hasCustomer && hasSpouse && memberOfOrganization) {
      elements.push(
        <div key="differentForSpouse" className="differentForSpouse" onClick={this.props.handleSeparateForSpouse}>
          <div className="productCheckbox">
            <input type="checkbox" checked={spouseDifferent} readOnly />
          </div>
          <div className="productDescription">
            <h4>{i18n.t('purchaseDifferentProductsForSpouse')}</h4>
          </div>
        </div>,
      );
    }
    // If the insurance choices are different for a spouse
    // not to be shown when products are shown in calculator view
    if (spouseDifferent && hasSpouse && context !== 'calculator') {
      const { spouseAvailableProducts, spouseProducts, ciAvailableForSpouse } = this.props;
      elements.push(
        <h2 key="purchaseSpouseProductSelectionHeader">{i18n.t('purchaseProductSelectionHeaderSpouse')}</h2>,
      );

      // lifeProduct is shown for spouse only when yksilöturva is chosen
      const spouseLifeProduct = coupleInsuranceSelected
        ? []
        : (<LifeProduct identifier="spouse" analyticsIdentifier="spouse" context={context} showInsuranceTitle={lifeInsuranceTitleNeedTitle} key="spouseLifeProduct" />);
      const spouseCiProduct = ciAvailableForSpouse
        ? (<CIProduct identifier="spouse" context={context} analyticsIdentifier="spouse" key="spouseCIProduct" />)
        : [];
      const spouseAccidentalProducts = accidentalInsuranceAvailable ? (
        <AccidentProduct
          identifier="spouse"
          availableProducts={spouseAvailableProducts}
          selectedProducts={spouseProducts}
          analyticsIdentifier="spouse"
          key="spouseAccidentProduct"
        />
      ) : <></>;

      const spouseProductElements = this.getProductElements(spouseLifeProduct, spouseCiProduct, spouseAccidentalProducts);
      elements.push(spouseProductElements);
    }

    return (
      <div className="productPickerBody" data-marker={this.props.analyticsIdentifier}>
        <div className="section">{elements}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  spouseDifferent: isSpouseDifferent(state),
  hasCustomer: hasCustomer(state),
  hasSpouse: hasSpouse(state),
  memberOfOrganization: isMemberOfOrganization(state),
  coupleInsuranceSelected: isCoupleInsuranceSelected(state),
  customerAvailableProducts: getCustomerAvailableProducts(state),
  spouseAvailableProducts: getSpouseAvailableProducts(state),
  ciAvailableForCustomer: isProductAvailableForCustomer(state, 'CI'),
  ciAvailableForSpouse: isProductAvailableForSpouse(state, 'CI'),
  customerProducts: getCustomerProducts(state),
  spouseProducts: getSpouseProducts(state),
  accidentalInsuranceAvailable: isAccidentalInsurancePossible(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleSeparateForSpouse: () => {
    dispatch(toggleSpouseDifferent());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductContainer);
