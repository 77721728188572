import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import i18n from 'i18next-client';
import Picker from './picker';
import AgeSelector from './ageSelector';
import {
  getCustomerAge,
  getCustomerAgePrevious,
  getSpouseAge,
  getSpouseAgePrevious,
} from '../redux/reducers/selectedReducer';

class AgePicker extends React.Component {
  static displayName = 'AgePicker';

  static propTypes = {
    customerAge: PropTypes.number,
    spouseAge: PropTypes.number,
  };

  render() {
    const {
      customerAge, customerAgePrevious, spouseAge, spouseAgePrevious,
    } = this.props;

    let text = i18n.t('calculatorAgeSelectorDefault');

    if (customerAge > 0 && !spouseAge) {
      text = i18n.t('calculatorAgeSelectorMeWithAge', { age: customerAge });
    } else if (!customerAge && spouseAge > 0) {
      text = i18n.t('calculatorAgeSelectorSpouseWithAge', { age: spouseAge });
    } else if (customerAge > 0 && spouseAge > 0) {
      text = i18n.t('calculatorAgeSelectorMeAndSpouse');
    }

    return (
      <Picker className="agePicker" value={text} role="form" {...this.props}>
        <AgeSelector />
      </Picker>
    );
  }
}

const mapStateToProps = (state) => ({
  customerAge: getCustomerAge(state),
  spouseAge: getSpouseAge(state),
  customerAgePrevious: getCustomerAgePrevious(state),
  spouseAgePrevious: getSpouseAgePrevious(state),
});

export default connect(mapStateToProps)(AgePicker);
