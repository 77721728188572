import filter from 'lodash/filter';

import { getProduct } from './organizationModel';

export const defaultCompensation = 100000;
const defaultCompensations = [50000, 100000, 150000, 200000, 250000];

const getOrganizationMaxCompensation = (organization) => {
  let organizationMaxCompensation;
  if (organization) {
    const lifeProduct = getProduct(organization, 'LIFE');
    if (lifeProduct && lifeProduct.maxCompensation) {
      organizationMaxCompensation = lifeProduct.maxCompensation;
    }
  }
  return organizationMaxCompensation;
};

const getMaxCompensation = (organization) => getOrganizationMaxCompensation(organization) || 100000;

export const getCompensations = (organization) => {
  const maxCompensation = getMaxCompensation(organization);
  const compensations = filter(defaultCompensations, (c) => c < maxCompensation);
  compensations.push(maxCompensation);

  return compensations;
};
