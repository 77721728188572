import {
  ciApprovalQuestions,
  approvalQuestionsCi,
  approvalQuestionsLifeMl,
  approvalQuestionsLifeMlCi,
  approvalQuestionsPrimus,
  approvalQuestionsPrimusCi,
} from '../config';
import {
  hasCi,
  hasMLLife,
  hasPrimus,
} from '../redux/reducers/selectedReducer';

import { createQuestionsWithAnswers } from './questionModel';

export const updateApprovalQuestions = (state) => {
  const oldApprovalQuestions = state.purchase.approvalQuestions;
  const approvalQuestions = getApprovalQuestions(state);
  return createQuestionsWithAnswers(approvalQuestions, oldApprovalQuestions);
};

export const resetApprovalQuestions = (state) => {
  const oldApprovalQuestions = state.purchase.approvalQuestions;
  const approvalQuestions = getApprovalQuestions(state);
  return createQuestionsWithAnswers(approvalQuestions, oldApprovalQuestions);
};

export const updateCIApprovalQuestions = (oldCiApprovalQuestions) => {
  const updatedCiApprovalQuestions = createQuestionsWithAnswers(ciApprovalQuestions, oldCiApprovalQuestions);
  return updatedCiApprovalQuestions;
};

const getApprovalQuestions = (state) => {
  let approvalQuestions = [];
  if (hasPrimus(state)) {
    approvalQuestions = hasCi(state) ? approvalQuestionsPrimusCi : approvalQuestionsPrimus;
  } else if (hasMLLife(state)) {
    approvalQuestions = hasCi(state) ? approvalQuestionsLifeMlCi : approvalQuestionsLifeMl;
  } else {
    approvalQuestions = approvalQuestionsCi;
  }
  return approvalQuestions;
};
