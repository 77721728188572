import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import i18n from '../localization/i18n-wrapper';
import {
  getSelected, getCustomerProducts, getSpouseAge, getChildCount, isMemberOfOrganization,
  getOrganization,
} from '../redux/reducers/selectedReducer';
import { getPurchase } from '../redux/reducers/purchaseReducer';
import customerAvailableProducts from '../redux/reducers/customerAvailableProductsReducer';
import PersonDetails from './personDetails';

import { getOrganizations } from '../redux/reducers/dataReducer';
import { getEffectiveOrganization } from '../models/organizationModel';

class ReviewDetails extends React.Component {
  static displayName = 'ReviewDetails';

  static propTypes = {
    purchase: PropTypes.object.isRequired,
    spouseAge: PropTypes.number,
    childCount: PropTypes.number,
    organization: PropTypes.object.isRequired,
    customerProducts: PropTypes.array.isRequired,
    errors: PropTypes.object,
  };

  render() {
    const content = [];

    const {
      purchase, customerProducts, spouseAge, childCount, organization, organizations, memberOfOrganization,
    } = this.props;
    const showBeneficiary = customerProducts && customerAvailableProducts.length > 0;

    const effectiveOrg = getEffectiveOrganization(organization, memberOfOrganization, organizations);

    content.push(<h2 key="checkDetailsHeader">{i18n.t('checkDetailsTitle')}</h2>);

    content.push(
      <PersonDetails
        key="customer"
        marker="customer"
        root={purchase}
        organization={effectiveOrg}
        showOrganization
        showAddress
        showLanguage
        showBeneficiary={showBeneficiary}
        idPrefix="customer"
        path="customer"
        beneficiaryPath="customerBeneficiary"
        beneficiaryDetailsPath="customerBeneficiaryDetails"
        readOnly
      />,
    );

    if (spouseAge) {
      content.push(<h2 key="spouseDetailsHeader">{i18n.t('spouseDetailsTitle')}</h2>);
      content.push(
        <PersonDetails
          key="spouse"
          marker="spouse"
          root={purchase}
          showAddress
          idPrefix="spouse"
          path="spouse"
          readOnly
        />,
      );
    }

    if (childCount > 0) {
      for (let i = 0, c = childCount; i < c; i++) {
        if (childCount === 1) {
          content.push(<h2 key="childDetailsHeader">{i18n.t('childDetailsTitle')}</h2>);
        } else {
          content.push(
            <h2 key={`childDetailsHeader_${i + 1}`}>
              {i + 1}
              .
              {i18n.t('childDetailsTitle')}
            </h2>,
          );
        }

        content.push(
          <PersonDetails
            key={`child${i}`}
            root={purchase}
            marker="child"
            idPrefix={`child${i}`}
            path={`children.${i}`}
            readOnly
          />,
        );
      }
    }

    return <div className="purchaseSection">{content}</div>;
  }
}

const mapStateToProps = (state) => ({
  purchase: getPurchase(state),
  selected: getSelected(state),
  spouseAge: getSpouseAge(state),
  customerProducts: getCustomerProducts(state),
  childCount: getChildCount(state),
  organization: getOrganization(state),
  organizations: getOrganizations(state),
  memberOfOrganization: isMemberOfOrganization(state),
});

export default connect(mapStateToProps)(ReviewDetails);
