import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';

import http from '../util/http';
import i18n from '../localization/i18n-wrapper';
import purchaseApi from '../api/purchaseApi';

// Note: there is a similar list for backend in main/java/biz/ifint/hvk/dto/VastusProvider.js
const bankProviders = [
  'SAMPO', // (= Danske Bank)
  'OKO', // (= Osuuspankki)
  'NORDEA', // (= Nordea)
  'SAASTOPANKKI', // (=Säästöpankki)
  'ALANDSBANKEN', // (= Ålandsbanken)
  'HANDELSBANKEN', // (= Handelsbanken)
  'SPANKKI', // (= S-Pankki)
  'ELISA', // (= Elisa mobiilivarmenne),
  'POP', // Paikallisosuuspankki
  'AKTIA', // Aktia
  'OMASP', // Oma Säästöpankki
];

// Accessible names for img alt attributes
const providerMap = new Map([
  ['SAMPO', 'Danske Bank'],
  ['OKO', 'Osuuspankki'],
  ['NORDEA', 'Nordea'],
  ['SAASTOPANKKI', 'Säästöpankki'],
  ['ALANDSBANKEN', 'Ålandsbanken'],
  ['HANDELSBANKEN', 'Handelsbanken'],
  ['SPANKKI', 'S Pankki'],
  ['ELISA', 'Elisa mobiilivarmenne'],
  ['POP', 'Paikallisosuuspankki'],
  ['AKTIA', 'Aktia'],
  ['OMASP', 'Oma Säästöpankki'],
]);

class Vastus extends React.Component {
  static displayName = 'Vastus';

  static propTypes = {
    spouse: PropTypes.bool,
    title: PropTypes.string.isRequired,
    info: PropTypes.string,
    prompt: PropTypes.string.isRequired,
    vastusCallbackUrl: PropTypes.string,
    error: PropTypes.object,
    postState: PropTypes.func.isRequired,
  };

  handleVastus = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const el = event.currentTarget;
    const provider = el.getAttribute('data-provider');
    const p = this.props;

    if (isEmpty(p.error)) {
      this.props.postState().then(() => {
        // fetch vastus url
        let getVastusUrl = `vastusUrl?provider=${provider}`;

        getVastusUrl += `&vastusCallbackUrl=${p.vastusCallbackUrl}`;

        if (p.spouse) {
          getVastusUrl += '&spouse=true';
        }

        http.get(getVastusUrl).then((response) => {
          if (response.data === 'session_expired') {
            window.location.href = '/virhe';
          } else {
            window.location.href = response.data;
          }
        });
      });
    }
  };

  render() {
    const {
      title, info, prompt, error,
    } = this.props;
    const content = bankProviders.map(function (provider) {
      return (
        <a key={provider} data-provider={provider} onClick={this.handleVastus} href="#">
          <img
            alt={(`${provider}` !== 'ELISA')
              ? `Verkkopankki ${providerMap.get(`${provider}`)}`
              : providerMap.get(`${provider}`)}
            src={`images/vastus/${provider}`}
          />
        </a>
      );
    }, this);

    if (!isProductionEnv) {
      content.push(
        <a key="TESTI" data-provider="TESTI" onClick={this.handleVastus} href="#">
          TESTIPANKKI
        </a>,
      );
    }

    if (isEmpty(error)) {
      content.unshift(<div key="empty_error" className="error" />);
    } else {
      content.unshift(
        <div key="error_visible" className="error">
          {error}
        </div>,
      );
    }
    const infoElem = !info ? <h3 /> : <h3 key="info">{i18n.t(info)}</h3>;
    return (
      <div className="purchaseSection">
        <h2 key="title">{i18n.t(title)}</h2>
        {infoElem}
        <label key="prompt" className="bankProvidersHeading">
          {i18n.t(prompt)}
        </label>
        <div key="bankProviders" className="bankProviders">
          {content}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  postState: () => purchaseApi.postState(dispatch),
});

export default connect(
  undefined,
  mapDispatchToProps,
)(Vastus);
