import * as React from 'react';

import FormField from './formField';

export default class TaxablePropertyAndTaxPercentage extends React.Component {
  static displayName = 'TaxablePropertyAndTaxPercentage';

  render() {
    return (
      <div>
        {[
          <FormField key="taxableProperty" fieldName="taxableProperty" inputSymbol="€" />,
          <FormField key="taxPercentage" fieldName="taxPercentage" inputSymbol="%" />,
        ]}
      </div>
    );
  }
}
