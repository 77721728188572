import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import omit from 'lodash/omit';

import ThankYouCompensationSum from './thankYou/thankYouCompensationSum';
import ThankYouAge from './thankYou/thankYouAge';
import CiSmoke from './thankYou/ciSmoke';
import ThankYouFrequency from './thankYou/thankYouFrequency';
import ThankYouMonthlyPrice from './thankYou/thankYouMonthlyPrice';
import ThankYouApproval from './thankYou/thankYouApproval';
import { Footer, BottomFooter } from './thankYou/thankYouCommon';
import ThankYouPurchase from './thankYou/thankYouPurchase';

import stateApiProvider from './redux/stateApiProvider';
import localizationsLoader from './localization/loader';
import loadOrganizations from './api/organizationsApi';
import { postState } from './api/purchaseApi';
import CalculatorModel from './models/calculatorModel';
import HvkStore from './redux/hvkStore';
import { addCustomerProduct, setCICompensations, setCustomerCICompensation } from './redux/actions/selectedActions';
import { getCustomerCICompensation, getCICompensations } from './redux/reducers/selectedReducer';
import { setCiApprovalQuestions } from './redux/actions/purchaseActions';
import { getCIApprovalQuestions } from './redux/reducers/purchaseReducer';
import { getNormalCustomerCiPerMonth } from './redux/reducers/priceReducer';

export default async function (initialPriceVariables, calculationResult, localizations) {
  const calculatorModel = new CalculatorModel(initialPriceVariables.priceVariables);
  const initialState = {
    selected: calculatorModel,
    purchase: omit(initialPriceVariables, 'priceVariables'),
    price: calculationResult,
  };
  const hvkStore = HvkStore.getInstance(initialState).store;

  stateApiProvider.setupForCalculator();

  await loadOrganizations(hvkStore);
  localizationsLoader.initI18N(localizations);

  const calculatePrices = async (hvkStore) => {
    await postState(hvkStore.dispatch);
  };

  await calculatePrices(hvkStore);

  hvkStore.dispatch(addCustomerProduct('CI'));
  hvkStore.dispatch(setCICompensations());
  hvkStore.dispatch(setCiApprovalQuestions());

  stateApiProvider.setupForPurchase();

  const sum = document.getElementById('ciSum');
  if (sum) {
    ReactDOM.render(
      <Provider store={hvkStore}>
        <ThankYouCompensationSum
          sumInfoKey="ciSumInfoKey"
          setCompensation={setCustomerCICompensation}
          getCurrentCompensation={getCustomerCICompensation}
          getCompensations={getCICompensations}
        />
      </Provider>,
      sum,
    );
  }

  const age = document.getElementById('ciAge');
  if (age) {
    ReactDOM.render(
      <Provider store={hvkStore}>
        <ThankYouAge
          minAge={20}
          maxAge={65}
          infoKey="ciAgeInfoKey"
        />
      </Provider>,
      age,
    );
  }

  const smoke = document.getElementById('ciSmoke');
  if (smoke) {
    ReactDOM.render(
      <Provider store={hvkStore}>
        <CiSmoke />
      </Provider>,
      smoke,
    );
  }

  const freq = document.getElementById('ciFrequency');
  if (freq) {
    ReactDOM.render(
      <Provider store={hvkStore}>
        <ThankYouFrequency />
      </Provider>,
      freq,
    );
  }

  const resultField = document.getElementById('ciResult');
  if (resultField) {
    ReactDOM.render(
      <Provider store={hvkStore}>
        <ThankYouMonthlyPrice
          priceInfoKey="ciCalculatorText"
          getMonthlyPrice={getNormalCustomerCiPerMonth}
        />
      </Provider>,
      resultField,
    );
  }

  const approval = document.getElementById('ciApproval');
  if (approval) {
    ReactDOM.render(
      <Provider store={hvkStore}>
        <ThankYouApproval
          header="thankYouApprovalHeader"
          subHeader="ciApprovalSubHeader"
          path="ciApprovalQuestions"
          getApprovalQuestions={getCIApprovalQuestions}
        />
      </Provider>,
      approval,
    );
  }

  const begin = document.getElementById('beginPurchase');
  if (begin) {
    ReactDOM.render(
      <Provider store={hvkStore}>
        <ThankYouPurchase
          getApprovalQuestions={getCIApprovalQuestions}
          purchaseUrl="startCiPurchase"
        />
      </Provider>,
      begin,
    );
  }

  const footer = document.getElementById('ciFooter');
  if (footer) {
    ReactDOM.render(
      <Provider store={hvkStore}>
        <Footer
          footerLocalizationKey="ciFooter"
        />
      </Provider>,
      footer,
    );
  }

  const bottomFooter = document.getElementById('ciBottomFooter');
  if (bottomFooter) {
    ReactDOM.render(
      <Provider store={hvkStore}>
        <BottomFooter />
      </Provider>,
      bottomFooter,
    );
  }
}
