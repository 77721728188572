import http from '../util/http';

import stateApiProvider from '../redux/stateApiProvider';
import { isShortBuyChain } from '../redux/reducers/selectedReducer';
import { setPrices } from '../redux/actions/priceActions';

// miten tähän utilityyn store dispatchia varten?  param?

let stateDebounceTimeout;

export const postState = (dispatch) => {
  if (stateDebounceTimeout) {
    clearTimeout(stateDebounceTimeout); // to avoid possible race conditions
    stateDebounceTimeout = void 0;
  }
  return stateApiProvider.update().then((price) => {
    dispatch(setPrices(price.data));
  });
};

// TODO: replace 'homebrew' debounce with lodash implementation
export const debouncedPostState = (dispatch) => {
  if (stateDebounceTimeout) {
    clearTimeout(stateDebounceTimeout);
  }

  stateDebounceTimeout = setTimeout(() => {
    stateDebounceTimeout = void 0;
    postState(dispatch);
  }, 1500);
};

export const startPurchase = async (store) => {
  const state = store.getState();
  const payload = state.selected;
  const response = await http.post('startPurchase', payload);
  const shortBuyChain = isShortBuyChain(state);
  let postfix = '';
  if (shortBuyChain == true) {
    postfix = '#!/2a';
    postState(store.dispatch);
  }
  const nextUrl = `${response.data}${postfix}`;
  window.location.href = nextUrl;
};

export default {
  postState,
  debouncedPostState,
  startPurchase,
};
