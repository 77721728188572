import * as React from 'react';
import map from 'lodash/map';

import formatNumber from '../util/formatNumber';

export const CompensationRows = ({ compensations }) => {
  const sumRows = map(compensations, (sum) => (
    <option key={sum} value={sum}>
      {formatNumber(sum)}
      {' '}
      €
    </option>
  ));

  return sumRows;
};
