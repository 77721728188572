import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import i18n from '../localization/i18n-wrapper';

import YesNoQuestion from './yesNoQuestion';
import { setYesNoQuestionAnswer } from '../redux/actions/purchaseActions';

class HealthYesNoQuestion extends React.Component {
  static displayName = 'HealthYesNoQuestion';

  static propTypes = {
    // path contains string and number
    // eslint-disable-next-line react/forbid-prop-types
    path: PropTypes.array.isRequired,
    question: PropTypes.shape({
      answer: PropTypes.bool,
      infoKey: PropTypes.string,
      key: PropTypes.shape({
        questionKey: PropTypes.string.isRequired,
        ref: PropTypes.string,
      }),
    }).isRequired,
    setYesNoQuestionAnswer: PropTypes.func.isRequired,
  };

  handleChange = (answer) => {
    this.props.setYesNoQuestionAnswer(...this.props.path, answer);
  };

  render() {
    const { question } = this.props;
    return (
      <div className="yesNoWrapper">
        <YesNoQuestion
          marker={question.key.questionKey}
          value={question.answer}
          infoBubble={question.infoKey}
          onChange={this.handleChange}
        >
          {i18n.t(question.key.questionKey)}
        </YesNoQuestion>

      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setYesNoQuestionAnswer: (path, index, answer) => {
    dispatch(setYesNoQuestionAnswer(path, index, answer));
  },
});

export default connect(
  undefined,
  mapDispatchToProps,
)(HealthYesNoQuestion);
