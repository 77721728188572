import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import CommonFormField from '../common/commonFormField';
import { setDataField } from '../redux/actions/purchaseActions';

class FormField extends React.Component {
  static displayName = 'FormField';

  static propTypes = {
    setDataField: PropTypes.func.isRequired,
  };

  handleChange = (change) => {
    this.props.setDataField(change);
  };

  render() {
    return <CommonFormField {...this.props} handleChange={this.handleChange} />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  setDataField: (f) => dispatch(setDataField(f)),
});

export default connect(
  undefined,
  mapDispatchToProps,
)(FormField);
