import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import map from 'lodash/map';
import range from 'lodash/range';

import i18n from '../localization/i18n-wrapper';
import utils from '../util/utils';
import { setCustomerAge, setSpouseAge } from '../redux/actions/selectedActions';
import { postState } from '../api/purchaseApi';
import {
  getCustomerAge,
  getCustomerAgePrevious,
  getSpouseAge,
  getSpouseAgePrevious,
  getCustomerProducts,
  getSpouseProducts,
  defaultAge,
} from '../redux/reducers/selectedReducer';
import { setSpousePresent, setSpouseVastusStatus } from '../redux/actions/purchaseActions';

class AgeSelector extends React.Component {
  static displayName = 'AgeSelector';

  handleAgeChange = (event) => {
    this.props.setCustomerAge(utils.gtZeroOrNull(event.target.value));
    this.props.postState();
  };

  handleAgeCheckbox = (event) => {
    const { customerAge, customerAgePrevious, spouseProducts } = this.props;
    let ageValue;
    if (event.target.checked && !customerAge) {
      ageValue = customerAgePrevious || defaultAge;
    } else if (!event.target.checked) {
      this.props.setCustomerAge(customerAge);
      ageValue = undefined;
    }
    this.props.setCustomerAge(ageValue);
    this.props.postState();
  };

  handleSpouseAgeChange = (event) => {
    this.props.setSpouseAge(utils.gtZeroOrNull(event.target.value));
    this.props.postState();
  };

  handleSpouseAgeCheckbox = (event) => {
    const { spouseAge, spouseAgePrevious, customerProducts } = this.props;
    const {
      setSpouseAge, setSpousePresent, setSpouseVastusStatus, postState,
    } = this.props;
    let ageValue;
    if (event.target.checked && !spouseAge) {
      ageValue = spouseAgePrevious || defaultAge;
    } else if (!event.target.checked) {
      this.props.setSpouseAge(spouseAge);
      ageValue = undefined;
      setSpousePresent(true);
      setSpouseVastusStatus(false);
    }
    setSpouseAge(ageValue);
    postState();
  };

  render() {
    const { customerAge, spouseAge } = this.props;

    const ages = map(range(15, 70, 1), (age) => <option key={age}>{age}</option>);

    ages.unshift(
      <option key="0" value="0">
        {i18n.raw('calculatorAgeSelectorChooseAge')}
      </option>,
    );

    return (
      <div className="ageSelector">
        <div className="who form-group">
          <label className="title control-label">{i18n.t('ageSelectorTitle')}</label>
          <div className="customer" id="customerCheckBox">
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  data-analytics="customer"
                  onChange={this.handleAgeCheckbox}
                  checked={customerAge > 0}
                />
                {' '}
                {i18n.t('calculatorAgeSelectorMe')}
              </label>
            </div>
          </div>
          <div className="spouse" id="spouseCheckBox">
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  data-analytics="spouse"
                  onChange={this.handleSpouseAgeCheckbox}
                  checked={spouseAge > 0}
                />
                {' '}
                {i18n.t('calculatorAgeSelectorSpouse')}
              </label>
            </div>
          </div>
        </div>
        <div className="age form-group">
          <label className="title control-label" id="age">{i18n.t('ageSelectorAge')}</label>
          <div className="customer">
            <select
              ref={(self) => (this.ageSelect = self)}
              className="form-control"
              id="customerAgeSelect"
              onChange={this.handleAgeChange}
              value={customerAge}
              aria-labelledby="customerCheckBox age"
            >
              {ages}
            </select>
          </div>
          <div className="spouse">
            <select
              ref={(self) => (this.spouseAgeSelect = self)}
              className="form-control"
              id="spouseAgeSelect"
              onChange={this.handleSpouseAgeChange}
              value={spouseAge}
              aria-labelledby="spouseCheckBox age"
            >
              {ages}
            </select>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  customerAge: getCustomerAge(state),
  customerAgePrevious: getCustomerAgePrevious(state),
  spouseAge: getSpouseAge(state),
  spouseAgePrevious: getSpouseAgePrevious(state),
  customerProducts: getCustomerProducts(state),
  spouseProducts: getSpouseProducts(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCustomerAge: (age) => dispatch(setCustomerAge(age)),
  setSpouseAge: (age) => dispatch(setSpouseAge(age)),
  setSpousePresent: (value) => dispatch(setSpousePresent(value)),
  setSpouseVastusStatus: (value) => dispatch(setSpouseVastusStatus(value)),
  postState: () => postState(dispatch),
});

AgeSelector.propTypes = {
  customerAge: PropTypes.number,
  customerAgePrevious: PropTypes.number,
  spouseAge: PropTypes.number,
  spouseAgePrevious: PropTypes.number,
  customerProducts: PropTypes.array.isRequired,
  spouseProducts: PropTypes.array.isRequired,
  setCustomerAge: PropTypes.func.isRequired,
  setSpouseAge: PropTypes.func.isRequired,
  postState: PropTypes.func.isRequired,
};

AgeSelector.defaultProps = {
  customerAge: undefined,
  customerAgePrevious: undefined,
  spouseAge: undefined,
  spouseAgePrevious: undefined,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AgeSelector);
