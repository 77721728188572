import assign from 'lodash/assign';
import cloneDeep from 'lodash/cloneDeep';

import utils from '../util/utils';

export default function CalculatorModel(properties) {
  if (properties) {
    const propertiesWithoutNulls = nullToUndefined(properties);
    assign(this, propertiesWithoutNulls);
  }
}

const nullToUndefined = (obj) => {
  const objWithNoNullFields = {};
  for (const field in obj) {
    objWithNoNullFields[field] = obj[field] === null ? undefined : obj[field];
  }
  return objWithNoNullFields;
};

function productHelper(list, product) {
  if (product) {
    return (list || []).indexOf(product) >= 0;
  }
  return !!(list && list.length);
}

CalculatorModel.prototype = {
  // redux: ok
  childCount() {
    return (
      utils.safeNum(this.childrenFrom0to9)
      + utils.safeNum(this.childrenFrom10to12)
      + utils.safeNum(this.childrenFrom13to17)
    );
  },

  // redux: ok
  customerHasProduct(product) {
    return productHelper(this.customerProducts, product);
  },

  // redux: ok
  spouseHasProduct(product) {
    return productHelper(this.spouseProducts, product);
  },

  // redux: ok
  hasCustomer() {
    return this.customerAge > 0;
  },

  // redux: ok
  hasSpouse() {
    return this.spouseAge > 0;
  },

  adjustForAPI() {
    const copy = cloneDeep(this);

    if (!this.hasSpouse()) {
      copy.spouseProducts = null;
      copy.spouseCompensation = null;
    }

    if (!this.hasCustomer()) {
      copy.customerProducts = null;
      copy.customerCompensation = null;
    }

    return copy;
  },
};
