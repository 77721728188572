import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as classNames from 'classnames';
import i18n from '../localization/i18n-wrapper';

import AgePicker from './agePicker';
import OrgPicker from './orgPicker';
import ProductPicker from './productPicker';
import ChildPicker from './childPicker';
import SmallCalculator from './smallCalculator';
import purchaseApi from '../api/purchaseApi';
import { formatPrice } from '../util/utils';

import PriceDisplay from '../common/priceDisplay';

import { toggleMemberOfOrganization } from '../redux/actions/selectedActions';
import { isMemberOfOrganization, isDefaultOrganization } from '../redux/reducers/selectedReducer';
import { getNormalTotalPerMonth, getTotalPerMonthNoOrg } from '../redux/reducers/priceReducer';

class CalculatorComponent extends React.Component {
  static displayName = 'CalculatorComponent';

  static propTypes = {
    label: PropTypes.object,
    children: PropTypes.any,
  };

  render() {
    return (
      <div className="hvkPickerContainer">
        <label>{this.props.label}</label>
        {this.props.children}
      </div>
    );
  }
}

class Calculator extends React.Component {
  static displayName = 'Calculator';

  static propTypes = {
    memberOfOrganization: PropTypes.bool.isRequired,
    normalTotalPerMonth: PropTypes.number.isRequired,
    totalPerMonthNoOrg: PropTypes.number.isRequired,
    toggleMemberOfOrganization: PropTypes.func.isRequired,
    postState: PropTypes.func.isRequired,
  };

  static contextTypes = {
    store: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      openPickerName: undefined,
      showResponsiveCalculator: true,
    };
  }

  doSubmit = () => {
    if (!purchaseApiMaintenance) {
      const { store } = this.context;
      purchaseApi.startPurchase(store);
    }
  };

  handleChkNoOrganizationChange = () => {
    this.props.toggleMemberOfOrganization();
    this.props.postState();
  };

  handleShowResponsiveCalculator = () => {
    this.setState({
      showResponsiveCalculator: true,
    });
  };

  visibilityChange = (name) => (visible) => {
    if (visible) {
      this.setState({ openPickerName: name });
    } else if (this.state.openPickerName === name) {
      this.setState({ openPickerName: null });
    }
  };

  render = () => {
    const {
      memberOfOrganization, defaultOrganization, normalTotalPerMonth, totalPerMonthNoOrg,
    } = this.props;

    const buttonClasses = {
      btn: true,
      'btn-active': !purchaseApiMaintenance,
    };

    let info;

    if (purchaseApiMaintenance) {
      info = <div className="error">{i18n.t('purchaseApiMaintenance')}</div>;
    }

    let className = 'calculator';
    className += ' showLarge';

    const showReferencePrice = memberOfOrganization && totalPerMonthNoOrg > normalTotalPerMonth;
    const calculatorDiscountLabel = showReferencePrice ? (
      <div className="calcPriceDiscountText">
        {i18n.t('priceWithoutMembershipShort', {
          totalPerMonthNoOrg: formatPrice(totalPerMonthNoOrg, 2),
        })}
      </div>
    ) : (
      ''
    );

    return (
      <div className={className}>
        <SmallCalculator onTransition={this.handleShowResponsiveCalculator} />
        <div className="largeCalculator">
          <div className="calcHeader">
            <h2>{i18n.t('calculatorTitle')}</h2>
          </div>
          <div className="calcCol">
            <div className="form-group">
              <CalculatorComponent label={i18n.t('ageSelectorTitle')}>
                <AgePicker
                  id="agePicker"
                  onVisibilityChange={this.visibilityChange('age')}
                  open={this.state.openPickerName === 'age'}
                />
              </CalculatorComponent>
              <CalculatorComponent label={i18n.t('organizationSelectorTitle')}>
                <OrgPicker
                  id="orgPicker"
                  disabled={!memberOfOrganization}
                  onVisibilityChange={this.visibilityChange('org')}
                  open={this.state.openPickerName === 'org'}
                />
              </CalculatorComponent>
            </div>
            <div className="clsPreFooter">
              <input
                type="checkbox"
                id="chkNoOrganization"
                name="chkNoOrganization"
                value="memberOfOrganization"
                onChange={this.handleChkNoOrganizationChange}
                checked={!memberOfOrganization}
                aria-labelledby="orgMemberCheckBox"
              />
              <span id="orgMemberCheckBox">{i18n.t('labTxtDoNotBelongToOrg')}</span>
            </div>
          </div>
          <div className="calcCol">
            <div className="form-group">
              <CalculatorComponent label={i18n.t('productSelectorTitle')}>
                <ProductPicker
                  id="productPicker"
                  onVisibilityChange={this.visibilityChange('product')}
                  open={this.state.openPickerName === 'product'}
                />
              </CalculatorComponent>
              <CalculatorComponent label={i18n.t('childSelectorTitle')}>
                <ChildPicker
                  id="childPicker"
                  onVisibilityChange={this.visibilityChange('child')}
                  open={this.state.openPickerName === 'child'}
                />
              </CalculatorComponent>
            </div>
          </div>
          <div className="calcPrice">
            <PriceDisplay price={normalTotalPerMonth} priceDisplayUnit={i18n.t('priceDisplayUnit')} decimals />
            {calculatorDiscountLabel}
          </div>
          <div className="calcFooter">
            <button
              id="startPurchaseButton"
              type="button"
              onClick={this.doSubmit}
              disabled={defaultOrganization && memberOfOrganization}
              className={classNames(buttonClasses)}
            >
              {i18n.t('startPurchaseFlow')}
            </button>
            {info}
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  memberOfOrganization: isMemberOfOrganization(state),
  defaultOrganization: isDefaultOrganization(state),
  normalTotalPerMonth: getNormalTotalPerMonth(state),
  totalPerMonthNoOrg: getTotalPerMonthNoOrg(state),
});

const mapDispatchToProps = (dispatch) => ({
  toggleMemberOfOrganization: () => dispatch(toggleMemberOfOrganization()),
  postState: () => purchaseApi.postState(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Calculator);
