import * as React from 'react';
import * as PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import * as classNames from 'classnames';

import get from 'lodash/get';
import set from 'lodash/set';

import FieldBase from './fieldBase';
import i18n from '../localization/i18n-wrapper';

export default class extends React.Component {
  static displayName = 'CommonFormField';

  static propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    path: PropTypes.string.isRequired,
    root: PropTypes.object.isRequired,
    errors: PropTypes.object,
    trim: PropTypes.bool,
    readOnly: PropTypes.bool,
    inputSymbol: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    infoKey: PropTypes.string,
  };

  state = {
    focused: false,
  };

  handleFocus(e) {
    this.setState({
      focused: true,
    });

    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  handleBlur(e) {
    this.setState({
      focused: false,
    });

    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  handleChange(e) {
    const value = this.props.trim && e.target.value ? e.target.value.trim() : e.target.value;
    this.props.handleChange(set({}, this.props.path, value));
  }

  getErrorMessage(error) {
    if (error instanceof Array) {
      return i18n.t(error[0], error[1]);
    }

    return i18n.t(error);
  }

  getErrorMessageKey(error) {
    if (error instanceof Array) {
      return error[0];
    }

    return error;
  }

  selectContent = () => {
    if (this.input.value.length < 2) {
      this.input.setSelectionRange(0, this.input.value.length);
    }
  };

  getInput() {
    const p = this.props;
    const value = get(p.root, p.path);
    const error = !this.state.focused ? get(p.errors, p.path) : undefined;
    const hasError = !!error;

    const classes = classNames({
      error: hasError,
      'form-control': true,
    });

    const input = (
      <input
        ref={(self) => (this.input = self)}
        key={p.id}
        id={p.id}
        type="text"
        value={value || ''}
        onChange={(event) => this.handleChange(event)}
        onBlur={(event) => this.handleBlur(event)}
        onFocus={(event) => this.handleFocus(event)}
        className={classes}
      />
    );

    let inputSymbol;
    if (p.inputSymbol) {
      inputSymbol = <span className="input-group-addon">{p.inputSymbol}</span>;
    }

    return (
      <div className="input-group" key={p.id}>
        {input}
        {inputSymbol}
      </div>
    );
  }

  render() {
    const p = this.props;
    const value = get(p.root, p.path);
    const error = !this.state.focused ? get(p.errors, p.path) : undefined;
    let errorEl;

    if (error) {
      errorEl = (
        <CSSTransition classNames="error" timeout={{ enter: 500, exit: 300 }} key={this.getErrorMessageKey(error)}>
          <div className="error">
            {this.getErrorMessage(error)}
          </div>
        </CSSTransition>
      );
    }

    const self = this;

    function inputOrReadOnly() {
      if (p.readOnly) {
        return <p className="form-control-static">{value}</p>;
      }
      return [
        self.getInput(),
        <TransitionGroup>
          {errorEl}
        </TransitionGroup>,
      ];
    }

    return <FieldBase {...p}>{inputOrReadOnly()}</FieldBase>;
  }
}
