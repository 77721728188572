import axios from 'axios';
import { appendMainWindowUrlParams } from './httpUtil';

axios.defaults.headers.post['Content-Type'] = 'application/json; encoding=utf-8';

const fixUrl = (url) => {
  const prefix = window.location.href.indexOf('/artikkelit/') !== -1 ? '../' : '';
  return appendMainWindowUrlParams(`${prefix}${url}`);
};

export const post = (url, data) => {
  const postUrl = fixUrl(url);
  const postPromise = axios.post(postUrl, data);
  return postPromise;
};

export const get = (url) => {
  const getUrl = fixUrl(url);
  const getPromise = axios.get(getUrl);
  return getPromise;
};

export default {
  get,
  post,
};
