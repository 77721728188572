import * as React from 'react';
import * as PropTypes from 'prop-types';
import * as classNames from 'classnames';

import i18n from 'i18next-client';

class ContinueButton extends React.Component {
  render() {
    const classes = {
      btn: true,
    };

    const {
      label, error, onClick, disabled, className,
    } = this.props;

    const buttonLabel = label && label !== '' ? label : i18n.t('continueButton');

    let errors;
    const hasError = !!error;

    if (hasError) {
      classes['btn-active'] = false;
      errors = <div className="error">{error}</div>;
    } else {
      classes['btn-active'] = true;
      errors = <div className="error" />;
    }

    return (
      <div id="continueButton" className={`continueButton ${className}`}>
        <button className={classNames(classes)} disabled={disabled} onClick={onClick}>
          {buttonLabel}
        </button>
        {errors}
      </div>
    );
  }
}

ContinueButton.displayName = 'ContinueButton';

ContinueButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

ContinueButton.defaultProps = {
  error: undefined,
  label: i18n.t('continueButton'),
  className: '',
};

export default ContinueButton;
