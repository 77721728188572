import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import i18n from 'i18next-client';
import Picker from './picker';
import ChildSelector from './childSelector';
import { handleScroll } from '../util/handleScroll';
import {
  getChildrenFrom0to9,
  getChildrenFrom10to12,
  getChildrenFrom13to17,
  isChildInsurancePossible,
} from '../redux/reducers/selectedReducer';

class ChildPicker extends React.Component {
  static displayName = 'ChildPicker';

  static propTypes = {
    childrenFrom0to9: PropTypes.number.isRequired,
    childrenFrom10to12: PropTypes.number.isRequired,
    childrenFrom13to17: PropTypes.number.isRequired,
    childInsurancePossible: PropTypes.bool.isRequired,
  };

  handleScroll = (event) => {
    handleScroll(event, this.scrollElement);
  };

  render = () => {
    let count;
    let text;
    const { childInsurancePossible } = this.props;
    const { childrenFrom0to9, childrenFrom10to12, childrenFrom13to17 } = this.props;

    if (childInsurancePossible) {
      count = childrenFrom0to9 + childrenFrom10to12 + childrenFrom13to17;
      text = i18n.t('childSelectorChildCount', { count });
    } else {
      text = i18n.t('childSelectorNotAvailable');
    }

    return (
      <Picker
        onWheel={(event) => this.handleScroll(event)}
        value={text}
        className="attachRight childPicker"
        role="form"
        disabled={!childInsurancePossible}
        {...this.props}
      >
        <ChildSelector ref={(self) => (this.scrollElement = self)} />
      </Picker>
    );
  };
}

const mapStateToProps = (state) => ({
  childInsurancePossible: isChildInsurancePossible(state),
  childrenFrom0to9: getChildrenFrom0to9(state),
  childrenFrom10to12: getChildrenFrom10to12(state),
  childrenFrom13to17: getChildrenFrom13to17(state),
});

export default connect(mapStateToProps)(ChildPicker);
