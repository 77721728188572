/* eslint no-use-before-define: off */
/* eslint no-param-reassign: off */
/* eslint prefer-rest-params: off */
/* eslint no-cond-assign: off */
/* eslint prefer-spread: off */

import { validateSsn } from './utils';

const trim = String.prototype.trim
  || function () {
    return this.replace(/^\s+|\s+$/g, '');
  };

const socSecValidator = (function () {
  return function (val) {
    if (validators.blank(val)) {
      return true;
    }

    return validateSsn(val);
  };
}());

function regexValidator(regex) {
  return function (val) {
    return validators.blank(val) || regex.test(val);
  };
}

function validateProperty(validator, value, options, path, context) {
  if (!options.filter || options.filter(path, context)) {
    return validator(value, options, path, context);
  }

  return true;
}

function checkForObject(validator) {
  if (typeof validator === 'object') {
    validator = objectValidator(validator);
  }

  return validator;
}

export function arrayValidator(elementValidator) {
  elementValidator = checkForObject(elementValidator);

  return function (arr, options, path, context) {
    if (Array.isArray(arr)) {
      context = context.concat([arr]); // concat unwraps arrays, so we need to wrap the inner array

      const out = arr.reduce((result, value, index) => {
        const validout = validateProperty(elementValidator, value, options, path.concat(index), context);

        if (validout !== true) {
          result[index] = validout;
        }

        return result;
      }, {});

      if (Object.keys(out).length) {
        return out;
      }

      return true;
    }

    return validators.nullOrUndefined(arr);
  };
}

export function objectValidator(definition) {
  return function (obj, options, path, context) {
    if (!obj || typeof obj !== 'object') {
      obj = {};
    }

    context = context.concat(obj);

    const out = Object.keys(definition).reduce((result, key) => {
      const value = obj[key] || (obj.purchase && obj.purchase[key]);
      let validator = definition[key];

      validator = checkForObject(validator);

      const validout = validateProperty(validator, value, options, path.concat(key), context);

      if (validout !== true) {
        result[key] = validout;
      }

      return result;
    }, {});

    if (Object.keys(out).length) {
      return out;
    }

    return true;
  };
}

export const validators = {
  and() {
    const funcs = arguments;

    return function () {
      let retval;

      for (let i = 0, l = funcs.length; i < l; i++) {
        if ((retval = funcs[i].apply(undefined, arguments)) !== true) {
          return retval;
        }
      }

      return true;
    };
  },

  or() {
    const funcs = arguments;

    return function () {
      let retval;

      for (let i = 0, l = funcs.length; i < l; i++) {
        if ((retval = funcs[i].apply(undefined, arguments)) === true) {
          return true;
        }
      }

      return retval;
    };
  },

  hasLength(length) {
    return function (val) {
      return validators.blank(val) || val.toString().length === length;
    };
  },

  hasMaxLength(length) {
    return function (val) {
      return validators.blank(val) || val.toString().length <= length;
    };
  },

  blank(val) {
    if (validators.nullOrUndefined(val)) {
      return true;
    }

    if (Array.isArray(val) && val.length === 0) {
      return true;
    }

    val = trim.call(val.toString());
    return val.length === 0;
  },

  required(val, options) {
    return options.relaxed || !validators.blank(val);
  },

  regex: regexValidator,

  validSocialSecurityNumber: socSecValidator,

  validEmail: regexValidator(
    /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/,
  ),
  validName: regexValidator(/^[a-zA-ZåÅäÄöÖüÜéÉèÈôÔ´`\s-]+$/),
  validAddress: regexValidator(/^[a-zA-Z0-9åÅäÄöÖüÜéÉèÈôÔ\s,-.´`']{1,60}$/),

  nullOrUndefined(val) {
    return val === null || typeof val === 'undefined';
  },

  numeric(val) {
    return validators.blank(val) || (typeof val === 'number' || /^[0-9]+$/.test(val));
  },

  fail() {
    return false;
  },

  pass() {
    return true;
  },

  min(minVal) {
    return function (val) {
      return (validators.numeric(val) || validators.validFloat(val)) && val >= minVal;
    };
  },

  validFloat: regexValidator(/^(-?\d+)(?:\.(\d+))?$/),
};

/**
 * Build a validator that consists of an object tree mirroring the structure of
 * the object to be validated
 *
 * @param rootValidator
 */
export function build(rootValidator) {
  rootValidator = checkForObject(rootValidator);

  return function (value, relaxed, filter) {
    const result = rootValidator(
      value,
      {
        relaxed,
        filter,
      },
      [],
      [],
    );

    return result === true ? {} : result;
  };
}

export function check(assertion, message) {
  return function () {
    return assertion.apply(undefined, arguments) || message;
  };
}

export default {
  validators,
  build,

  array: arrayValidator,
  object: objectValidator,

  check,
};
