import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import i18n from '../localization/i18n-wrapper';

import CommonFormField from '../common/commonFormField';
import { getNeed } from '../redux/reducers/needReducer';
import { setNeedField } from '../redux/actions/needActions';
import NeedCalculatorModel from '../models/needCalculatorModel';

export class FormField extends React.Component {
  static displayName = 'FormField';

  static propTypes = {
    fieldName: PropTypes.string.isRequired,
    need: PropTypes.object.isRequired,
    inputSymbol: PropTypes.string.isRequired,
    setField: PropTypes.func.isRequired,
  };

  handleChange = (change) => {
    const { setField } = this.props;
    setField(change);
  };

  handleFocus = () => {
    this.field.selectContent();
  };

  render() {
    const { need, fieldName, inputSymbol } = this.props;
    const id = fieldName;
    const label = i18n.t(`needCalculator_${fieldName}Label`);
    const path = fieldName;
    const needModel = new NeedCalculatorModel(need);
    const errors = needModel.validate(false);

    return (
      <CommonFormField
        ref={(self) => (this.field = self)}
        onFocus={this.handleFocus}
        key={id}
        id={id}
        label={label}
        path={path}
        root={need}
        errors={errors}
        handleChange={this.handleChange}
        inputSymbol={inputSymbol}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  need: getNeed(state),
});

const mapDispatchToProps = (dispatch) => ({
  setField: (field) => {
    dispatch(setNeedField(field));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormField);
