import http from '../util/http';

export const loadPostOffices = async (store) => {
  const postOfficesResponse = await http.get('postOffices');
  const postOffices = postOfficesResponse.data;
  store.dispatch({
    type: 'SET_POST_OFFICES',
    postOffices,
  });
};
