import * as React from 'react';
import * as PropTypes from 'prop-types';
import * as classNames from 'classnames';

import { handleScroll } from '../util/handleScroll';

export default class SearchSelector extends React.Component {
  static displayName = 'SearchSelector';

  static propTypes = {
    children: PropTypes.any,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      filterString: '',
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    // filterstring changed
    if (this.state.filterString !== nextState.filterString) {
      return true;
    }

    // dataSrc changed
    if (this.props.dataSrc !== nextProps.dataSrc) {
      return true;
    }

    // selection changed
    if (this.props.selection !== nextProps.selection) {
      return true;
    }

    // placeholder changed
    if (this.props.placeholder !== nextProps.placeholder) {
      return true;
    }

    return false;
  }

  onShow() {
    this.searchFilterInput.focus();
  }

  handleClick(event) {
    const el = event.currentTarget;

    let id = el.getAttribute('data-id');

    if (this.props.idFormatter) {
      id = this.props.idFormatter(id);
    }

    this.props.onChange(id);
  }

  handleSearch(event) {
    this.setState({
      filterString: event.target.value,
    });
  }

  handleScroll(event) {
    handleScroll(event, this.scrollElement);
  }

  handleKeyDown(event) {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      const el = event.currentTarget;

      let id = el.getAttribute('data-id');
      if (this.props.idFormatter) {
        id = this.props.idFormatter(id);
      }
      this.props.onChange(id);
    }
  }

  render() {
    const value = this.props.selection;

    const { idPropName } = this.props;
    const { displayPropName } = this.props;
    const { hiddenPropName } = this.props;

    const filterString = this.state.filterString.toLowerCase().trim();
    let previousLetter;

    const selections = this.props.dataSrc
      .filter((property) => {
        const displayField = property[displayPropName];
        let hiddenField = false;

        if (hiddenPropName) {
          hiddenField = property[hiddenPropName];

          if (hiddenField && displayField && displayField.toLowerCase() === filterString) {
            return true;
          }
        }

        return (
          !hiddenField && (!displayField || !filterString || displayField.toLowerCase().indexOf(filterString) > -1)
        );
      })
      .map((property) => {
        const idValue = property[idPropName];

        const classes = classNames({
          selected: idValue === value,
        });

        const displayValue = property[displayPropName];

        const letter = displayValue.toUpperCase().charAt(0);

        const content = [];

        if (filterString.length) {
          const subName = displayValue.toLowerCase();
          let previous = 0;
          let index;
          while ((index = subName.indexOf(filterString, previous)) >= 0) {
            const pre = displayValue.substring(previous, index);
            const m = displayValue.substr(index, filterString.length);

            if (pre.length) {
              content.push(pre);
            }

            content.push(
              <span key={`match${index}`} className="match">
                {m}
              </span>,
            );
            previous = index + filterString.length;
          }

          if (previous < displayValue.length) {
            content.push(displayValue.substr(previous));
          }
        } else {
          content.push(displayValue);
        }

        if (letter !== previousLetter) {
          previousLetter = letter;
          content.unshift(
            <span key="index" className="index">
              {letter}
            </span>,
          );
        }

        return (
          <li
            data-id={idValue}
            key={idValue}
            onClick={(event) => this.handleClick(event)}
            className={classes}
            title={displayValue}
            tabIndex={0}
            onKeyDown={(event) => this.handleKeyDown(event)}
          >
            {content}
          </li>
        );
      });

    return (
      <div onWheel={(event) => this.handleScroll(event)} className="searchPicker">
        <div className="input-group searchFilter">
          <div className="input-group-addon">
            <span className="glyphicon glyphicon-search" aria-hidden="true" />
          </div>
          <input
            className="form-control"
            ref={(self) => (this.searchFilterInput = self)}
            placeholder={this.props.placeholder}
            onChange={(event) => this.handleSearch(event)}
            value={this.state.filterString}
            aria-labelledby="searchFilterLabel"
          />
          <span className="visually-hidden" id="searchFilterLabel">
            {this.props.searchFilterLabel}
          </span>
        </div>
        <div className="listContainer">
          <ul ref={(self) => (this.scrollElement = self)}>
            {this.props.children}
            {selections}
          </ul>
        </div>
      </div>
    );
  }
}

SearchSelector.propTypes = {
  selection: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dataSrc: PropTypes.array.isRequired,
  idPropName: PropTypes.string.isRequired,
  displayPropName: PropTypes.string.isRequired,
  hiddenPropName: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  idFormatter: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  searchFilterLabel: PropTypes.string,
};
