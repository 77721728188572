import get from 'lodash/get';

import * as actionTypes from '../actionTypes';

const initialState = {
  loan: 0,
  income: 0,
  children: 0,
  taxableProperty: 0,
  taxPercentage: 0,
};

const need = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_NEED_FIELD: {
      const { field } = action;
      return {
        ...state,
        ...field,
      };
    }
    case actionTypes.SET_RESULT: {
      const { result } = action;
      return {
        ...state,
        result,
      };
    }
    default: {
      return state;
    }
  }
};

export default need;

// Selectors

export const getNeed = (state) => state.need;

export const getResult = (state) => get(state, 'need.result', 0);
