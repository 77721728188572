import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import { navigate } from 'react-mini-router';
import ReviewDetails from './reviewDetails';
import InsuranceDetails from './insuranceDetails/insuranceDetails';
import InsuranceApproval from './insuranceApproval';
import Vastus from './vastus';
import i18n from '../localization/i18n-wrapper';

import BackButton from '../common/backButton';
import { getNormalTotalPerMonth } from '../redux/reducers/priceReducer';
import { getPurchase, getApplicationState } from '../redux/reducers/purchaseReducer';
import { getOrganization, isMemberOfOrganization } from '../redux/reducers/selectedReducer';
import { setPageAndPriceVisibility } from '../redux/actions/purchaseActions';

class SummaryPage extends React.Component {
  getUnansweredQuestions = (purchaseState) => purchaseState.approvalQuestions.reduce((acc, q) => acc + (q.answer ? 0 : 1), 0);

  handleBack = (event) => {
    event.preventDefault();
    event.stopPropagation();
    navigate('/2a');
  };

  handleCancel = (event) => {
    event.preventDefault();
    event.stopPropagation();
    window.location.href = '/ota-yhteytta';
  };

  componentDidMount = () => {
    const { applicationState } = this.props;
    if (applicationState.indexOf('CUSTOMER') === 0) {
      this.scrollUntilVastusVisible(0);
    } else {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
    setPageAndPriceVisibility();
  };

  scrollUntilVastusVisible = (retry, prevScrollTop) => {
    const self = this;

    document.body.scrollTop = document.documentElement.scrollTop = this.vastus.offsetTop;

    if (document.body.scrollTop !== prevScrollTop && retry < 10) {
      setTimeout(() => {
        self.scrollUntilVastusVisible(++retry, document.body.scrollTop);
      }, 100);
    }
  };

  _getAdditionalProducts = () => {
    const { organization } = this.props;
    const additionalProducts = organization ? organization.products : [];
    return additionalProducts;
  };

  render = () => {
    const {
      normalTotalPerMonth, purchase, organization, memberOfOrganization,
    } = this.props;
    let error = {};

    if (normalTotalPerMonth === 0) {
      error = i18n.t('missingProduct');
    }
    const unansweredQuestions = this.getUnansweredQuestions(purchase);
    if (unansweredQuestions > 0) {
      error = i18n.t('missingApprovalAnswer');
    }

    const orgSelected = organization !== undefined && organization.id !== undefined && (!organization.defaultOrganization || !memberOfOrganization);
    const vastusOrSelectOrg = orgSelected
      ? (
        <Vastus
          ref={(self) => (this.vastus = self)}
          title="insuranceApprovalTitle"
          info="insuranceApprovalInfo"
          prompt="chooseBank"
          vastusCallbackUrl="vastus"
          error={error}
        />
      )
      : (
        <div className="orgSelectionNotification">
          <div>{i18n.t('orgSelectionIsMissing')}</div>
          <a href="/vakuutuksen-osto">{i18n.t('backToOrgSelection')}</a>
        </div>
      );
    return (
      <div className="summaryPage">
        <InsuranceDetails additionalProducts={this._getAdditionalProducts()} />
        <ReviewDetails />
        <InsuranceApproval />
        {vastusOrSelectOrg}
        <BackButton onClick={this.handleBack} />
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  normalTotalPerMonth: getNormalTotalPerMonth(state),
  applicationState: getApplicationState(state),
  organization: getOrganization(state),
  memberOfOrganization: isMemberOfOrganization(state),
  purchase: getPurchase(state),
});

const mapDispatchToProps = (dispatch) => ({
  setPageAndPriceVisibility: dispatch(setPageAndPriceVisibility('summary', true)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SummaryPage);

SummaryPage.displayName = 'SummaryPage';

SummaryPage.propTypes = {
  purchase: PropTypes.object.isRequired,
  applicationState: PropTypes.string.isRequired,
  organization: PropTypes.object.isRequired,
  memberOfOrganization: PropTypes.bool.isRequired,
  normalTotalPerMonth: PropTypes.number.isRequired,
};
