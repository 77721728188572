import http from '../util/http';

import { defaultOrg } from '../models/organizationModel';
import { setOrganizationList } from '../redux/actions/dataActions';
import { setOrganization } from '../redux/actions/selectedActions';
import { getOrganization } from '../redux/reducers/selectedReducer';

const loadOrganizations = async (store) => {
  const organizationsResponse = await http.get('organizations');
  const organizations = organizationsResponse.data;

  store.dispatch(setOrganizationList(organizations));

  setSelectedOrganization(store, organizations);
};

const setSelectedOrganization = (store, organizations) => {
  const currentOrg = getOrganization(store.getState());
  const selectedOrg = (currentOrg && currentOrg.id) || defaultOrg;
  if (organizations.length) {
    store.dispatch(setOrganization(selectedOrg));
  }
};

export default loadOrganizations;
