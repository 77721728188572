import * as React from 'react';
import i18n from 'i18next-client';
import I18n from './I18n';

export default {
  t(key, options) {
    // eslint-disable-line react/display-name
    return <I18n key={key} i18nKey={key} args={options} />;
  },

  raw: i18n.t.bind(i18n),
};
