import * as actionTypes from '../actionTypes';

const initialState = ['LIFE', 'DAB', 'DAE', 'DAI', 'DAL', 'CI'];

const spouseAvailableProducts = (state = initialState, action) => {
  switch (action && action.type) {
    case actionTypes.SET_SPOUSE_AVAILABLE_PRODUCTS: {
      const { spouseAvailableProducts } = action;
      return [...spouseAvailableProducts];
    }
    default: {
      return state;
    }
  }
};

export default spouseAvailableProducts;

// Selectors

export const getSpouseAvailableProducts = (state) => state.spouseAvailableProducts || [];

export const isProductAvailableForSpouse = (state, product) => (state.spouseAvailableProducts || []).includes(product);
