import * as React from 'react';
import * as classNames from 'classnames';
import * as PropTypes from 'prop-types';

import PriceDisplay from '../common/priceDisplay';
import Info from '../common/info';

import i18n from '../localization/i18n-wrapper';
import I18n from '../localization/I18n';

class ProductRow extends React.Component {
  static displayName = 'ProductRow';

  static propTypes = {
    selected: PropTypes.bool.isRequired,
    analyticsIdentifier: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    infoKey: PropTypes.string,
    productCode: PropTypes.string,
    productDescription: PropTypes.object,
    price: PropTypes.number,
    additionalContent: PropTypes.any,
    beneficiaryInfo: PropTypes.any,
    onChange: PropTypes.func.isRequired,
  };

  render() {
    const classes = classNames({
      product: true,
      selected: this.props.selected,
    });

    const priceSelected = this.props.selected ? 'priceSelected' : '';

    let info;
    if (this.props.infoKey) {
      info = (
        <Info>
          <I18n i18nKey={this.props.infoKey} />
        </Info>
      );
    }

    const ariaDescription = this.props.productCode;

    /**
     * additional content=extra content like couple insurance
     * productDescription = sum selector or turvasumma
     */
    return (
      <div className={classes} onChange={this.props.onChange} data-product={this.props.productCode}>
        <div className="productCheckbox">
          <input
            type="checkbox"
            checked={this.props.selected}
            data-analytics={this.props.analyticsIdentifier}
            aria-labelledby={ariaDescription}
            onChange={this.props.onChange}
          />
        </div>
        <div className="productDescription">
          <h4 id={ariaDescription}>{this.props.label}</h4>
          {this.props.productDescription}
        </div>
        <div className="infoContainer">{info}</div>
        <PriceDisplay
          className={`productPrice ${priceSelected}`}
          price={this.props.price}
          priceDisplayUnit={i18n.t('priceDisplayUnit')}
          decimals
        />
        {this.props.additionalContent}
        <div className="beneficiaryInfo">
          {this.props.beneficiaryInfo}
        </div>
      </div>
    );
  }
}

export default ProductRow;
