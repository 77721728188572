import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import i18n from '../localization/i18n-wrapper';
import { postState } from '../api/purchaseApi';

import YesNoQuestion from '../purchase/yesNoQuestion';

import { isCustomerSmoking } from '../redux/reducers/selectedReducer';
import { setCustomerSmoking } from '../redux/actions/selectedActions';

const CiSmoke = (props) => {
  const handleChange = (checked) => {
    props.updateCustomerSmoking(checked);
    props.postState();
  };

  return (
    <div className="smokeSection">
      <div className="yesNoWrapper">
        <YesNoQuestion marker="test" value={props.customerSmoking} onChange={handleChange}>
          {i18n.raw('smokeQuestionKey')}
        </YesNoQuestion>
      </div>
    </div>
  );
};

CiSmoke.displayName = 'CiSmokeQuestions';

CiSmoke.propTypes = {
  customerSmoking: PropTypes.bool.isRequired,
  updateCustomerSmoking: PropTypes.func.isRequired,
  postState: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  customerSmoking: isCustomerSmoking(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateCustomerSmoking: (smoking) => {
    dispatch(setCustomerSmoking(smoking));
  },
  postState: () => {
    postState(dispatch);
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CiSmoke);
