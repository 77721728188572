import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import range from 'lodash/range';

import utils from '../util/utils';
import I18n from '../localization/I18n';
import ApprovalCheckboxQuestion from './approvalCheckboxQuestion';
import { getPurchase, getApprovalQuestions } from '../redux/reducers/purchaseReducer';

class InsuranceApproval extends React.Component {
  static displayName = 'InsuranceApproval';

  static propTypes = {
    purchase: PropTypes.object.isRequired,
    approvalQuestions: PropTypes.array.isRequired,
  };

  render = () => {
    const { approvalQuestions, purchase } = this.props;
    const content = [];

    content.push(
      <h2 key="insuranceApprovalHeader" className="insuranceApprovalHeader">
        <I18n i18nKey="insuranceApprovalUpperTitle" />
      </h2>,
    );

    utils.extendArray(
      content,
      range(0, approvalQuestions.length).map(
        (index) => (
          <ApprovalCheckboxQuestion
            key={approvalQuestions[index].key.questionKey}
            path={['approvalQuestions', index]}
            purchaseState={purchase}
            π
          />
        ),
        this,
      ),
    );

    return (
      <div className="insuranceApproval">
        <div className="purchaseSection">{content}</div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  approvalQuestions: getApprovalQuestions(state),
  purchase: getPurchase(state),
});

export default connect(mapStateToProps)(InsuranceApproval);
