import * as React from 'react';
import * as PropTypes from 'prop-types';

import i18n from 'i18next-client';

export default class extends React.Component {
  static displayName = 'I18n';

  static propTypes = {
    i18nKey: PropTypes.string.isRequired,
    args: PropTypes.object,
  };

  render() {
    return (
      <span
        key={`i18n_${this.props.i18nKey}`}
        data-i18n={this.props.i18nKey}
        dangerouslySetInnerHTML={{
          __html: i18n.t(this.props.i18nKey, this.props.args),
        }}
      />
    );
  }
}
