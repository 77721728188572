export const appendMainWindowUrlParams = (url) => {
  const loc = window.location.toString();
  const params = loc.split('?')[1];

  if (params) {
    url = url + (url.indexOf('?') > -1 ? '&' : '?') + params;
  }

  return url;
};
