import * as React from 'react';
import * as PropTypes from 'prop-types';

const Heads = (props) => {
  const {
    label, selected, headCount, id,
  } = props;

  let heads;
  if (headCount === 'single') {
    const headClasses = selected ? 'individualHeadSelected' : 'individualHeadUnselected';
    heads = (
      <div className="headGroup">
        <span role="img" aria-label="Asiakaskuvake" className={headClasses} />
      </div>
    );
  } else if (headCount === 'double') {
    heads = (
      <div className="headGroup">
        <span role="img" aria-label="Asiakaskuvake" className="individualHeadSelected" />
        <span role="img" aria-label="Asiakaskuvake" className="individualHeadSelected" />
      </div>
    );
  } else {
    heads = (<div className="headGroup" />);
  }

  return (
    <header>
      {heads}
      <span id={id}>{label}</span>
    </header>
  );
};

const CustomerAgeInput = (props) => {
  const {
    id, label, minAge, maxAge, age, selected, onChange, headCount,
  } = props;

  const classNames = selected ? 'customerAgeInputContainer selected' : 'customerAgeInputContainer';
  const labelId = `${id}-label`;

  return (
    <section className={classNames} id={id}>
      <Heads
        selected={selected}
        label={label}
        headCount={headCount}
        id={labelId}
      />
      <main className="ageInputFieldContainer">
        <input
          className="ageInput"
          type="number"
          value={age || ''}
          min={minAge}
          max={maxAge}
          pattern="[0-9]*"
          inputMode="numeric"
          onChange={(e) => onChange(e)}
          aria-labelledby={labelId}
        />
      </main>
    </section>
  );
};

CustomerAgeInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.object.isRequired,
  minAge: PropTypes.number.isRequired,
  maxAge: PropTypes.number.isRequired,
  age: PropTypes.number,
  selected: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  headCount: PropTypes.oneOf(['single', 'double', 'none']).isRequired,
};

CustomerAgeInput.defaultProps = {
  age: undefined,
};

export default CustomerAgeInput;
