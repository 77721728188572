import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';

import range from 'lodash/range';

import PriceDisplay from '../common/priceDisplay';

import i18n from '../localization/i18n-wrapper';
import utils from '../util/utils';
import { setChildren } from '../redux/actions/selectedActions';
import purchaseApi from '../api/purchaseApi';

class ChildAgePicker extends React.Component {
  static displayName = 'ChildAgePicker';

  static propTypes = {
    setChildren: PropTypes.func.isRequired,
    postState: PropTypes.func.isRequired,
  };

  handleChange = (e) => {
    const { propertyName } = this.props;
    const intendedAmount = utils.safeNum(e.target.value);
    const obj = {};
    obj[propertyName] = intendedAmount;
    this.props.setChildren(obj);
    this.props.postState();
  };

  render = () => {
    const {
      amount, price, propertyName, label,
    } = this.props;
    const amounts = range(0, 10, 1).map((a) => (
      <option key={a} value={a}>
        {a}
      </option>
    ));

    return (
      <div className="section">
        <div className="form-group" data-marker={propertyName}>
          <label className="control-label" htmlFor={propertyName}>{label}</label>
          <select className="amount form-control" id={propertyName} onChange={(event) => this.handleChange(event)} value={amount}>
            {amounts}
          </select>
          <PriceDisplay price={price} priceDisplayUnit={i18n.t('priceDisplayUnit')} decimals />
        </div>
      </div>
    );
  };
}

ChildAgePicker.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  propertyName: PropTypes.string.isRequired,
  amount: PropTypes.number,
  price: PropTypes.number,
};

const mapDispatchToProps = (dispatch) => ({
  setChildren: (obj) => dispatch(setChildren(obj)),
  postState: () => purchaseApi.postState(dispatch),
});

export default connect(
  undefined,
  mapDispatchToProps,
)(ChildAgePicker);
