import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as classNames from 'classnames';
import * as CryptoJS from 'crypto-js';
import i18n from '../localization/i18n-wrapper';

import { postState } from '../api/purchaseApi';
import formatNumber from '../util/formatNumber';
import { zeroPad, formatPrice } from '../util/utils';
import { post } from '../util/http';

import CompensationGauge from './compensationGauge';
import AgeInput from './ageInput';

import {
  getLang,
  getCustomerAge,
  getSpouseAge,
  getCustomerCompensation,
  getSpouseCompensation,
  getOrganizationId,
  isCoupleInsurance,
  getCustomerAgePrevious,
  getSpouseAgePrevious,
  defaultAge,
} from '../redux/reducers/selectedReducer';
import { getOrganizationRedirects } from '../redux/reducers/dataReducer';
import {
  setCustomerAge,
  setSpouseAge,
  setCustomerCompensation,
  setSpouseCompensation,
  setCoupleInsurance,
  setOrganization,
  addCustomerProduct,
  addSpouseProduct,
  customerHasProduct,
  spouseHasProduct,
} from '../redux/actions/selectedActions';
import { getNormalTotalPerMonth, getTotalPerMonthNoOrg } from '../redux/reducers/priceReducer';

const ARROW_LEFT = 37;
const ARROW_RIGHT = 39;
const ENTER = 13;

class EmbeddedCalculator extends React.Component {
  static displayName = 'EmbeddedCalculator';

  static propTypes = {
    organizationId: PropTypes.number,
    customerAge: PropTypes.number,
    customerAgePrevious: PropTypes.number,
    spouseAge: PropTypes.number,
    spouseAgePrevious: PropTypes.number,
    coupleInsurance: PropTypes.bool,
    customerCompensation: PropTypes.number,
    spouseCompensation: PropTypes.number,
    totalPerMonth: PropTypes.number,
    totalPerMonthNoOrg: PropTypes.number,
    event: PropTypes.object,
    setCustomerAge: PropTypes.func.isRequired,
    setSpouseAge: PropTypes.func.isRequired,
    addCustomerProduct: PropTypes.func.isRequired,
    addSpouseProduct: PropTypes.func.isRequired,
    setCustomerCompensation: PropTypes.func.isRequired,
    setSpouseCompensation: PropTypes.func.isRequired,
    setCoupleInsurance: PropTypes.func.isRequired,
    postState: PropTypes.func.isRequired,
    customerHasProduct: PropTypes.func.isRequired,
    spouseHasProduct: PropTypes.func.isRequired,
  };

  DEFAULT_ORGANIZATION = 176;

  DEFAULT_COMPENSATION = 100000;

  DEFAULT_CUSTOMER_AGE = defaultAge;

  DEFAULT_SPOUSE_AGE = defaultAge;

  MAX_AGE = 69;

  MIN_AGE = 15;

  SPOUSE_MIN_AGE = 18;

  COUPLE_INSURANCE_MIN_AGE = 18;

  PURCHASE_URL = '/vakuutuksen-osto?purchase=';

  HEALTH_QUESTION_REDIRECT = '#!/2a';

  COMPENSATIONS = [50000, 100000, 150000, 200000, 250000];

  MAX_COMPENSATION_INDEX = this.COMPENSATIONS.length - 1;

  DEFAULT_SLIDER_INDEX = Math.floor((this.COMPENSATIONS.length - 1) / 2);

  constructor(props) {
    super(props);

    const { spouseAge } = props;
    this.state = {
      customerIsSelected: true,
      spouseIsSelected: spouseAge && spouseAge !== 0,
    };
  }

  isAgeOk = (age, minAge, maxAge) => {
    if (age && age >= minAge && age <= maxAge) {
      return true;
    }
    return false;
  }

  isSpouseAgeOk = (age) => this.isAgeOk(age, this.SPOUSE_MIN_AGE, this.MAX_AGE);

  isCustomerAgeOk = (age, coupleInsurance) => {
    const minAge = coupleInsurance ? this.COUPLE_INSURANCE_MIN_AGE : this.MIN_AGE;
    return this.isAgeOk(age, minAge, this.MAX_AGE);
  }

  isCoupleOk = (age, spouse, coupleInsurance) => {
    if (this.isCustomerAgeOk(age, coupleInsurance) && this.isSpouseAgeOk(spouse)) {
      return true;
    }
    return false;
  };

  getCompensation = () => {
    const { customerCompensation, spouseCompensation } = this.props;

    if (customerCompensation && customerCompensation > 0) {
      return customerCompensation;
    }

    return spouseCompensation;
  };

  getPurchaseParams = () => {
    const {
      organizationId, customerAge, spouseAge, coupleInsurance,
    } = this.props;

    const params = {
      organizationId,
      coupleInsurance,
      customerCompensation: this.getCompensation(),
    };
    if (this.isSpouseAgeOk(spouseAge)) {
      params.spouseAge = spouseAge;
    }
    if (this.isCustomerAgeOk(customerAge, coupleInsurance)) {
      params.customerAge = customerAge;
    }

    return JSON.stringify(params);
  };

  processCustomerAge = (newAge) => {
    const { coupleInsurance } = this.props;

    this.props.setCustomerAge(newAge);

    if (this.isCustomerAgeOk(newAge, coupleInsurance) && !this.props.customerHasProduct('LIFE')) {
      this.props.addCustomerProduct('LIFE');
    }

    this.props.postState();
  };

  processSpouseAge = (newAge) => {
    if (!newAge) {
      this.deselectSpouse();
      this.props.setCoupleInsurance(false);
    }

    if (this.isSpouseAgeOk(newAge) && !this.state.spouseIsSelected) {
      this.selectSpouse();
    }

    const { customerAge, coupleInsurance } = this.props;

    this.props.setSpouseAge(newAge);

    if (this.isCoupleOk(customerAge, newAge, coupleInsurance) && !this.props.spouseHasProduct('LIFE')) {
      this.props.addSpouseProduct('LIFE');
    }

    this.props.postState();
  };

  handleCoupleInsuranceCheckbox = () => {
    this.toggleCoupleInsurance();
  };

  handleCoupleInsuranceKeyDown = (event) => {
    const { coupleInsurance } = this.props;
    if (coupleInsurance && (event.keyCode === ARROW_LEFT || event.keyCode === ENTER)) {
      this.toggleCoupleInsurance();
    } else if (!coupleInsurance && (event.keyCode === ARROW_RIGHT || event.keyCode === ENTER)) {
      this.toggleCoupleInsurance();
    }
  }

  toggleCoupleInsurance = () => {
    const { customerAge, spouseAge, coupleInsurance } = this.props;

    if (!this.isCoupleOk(customerAge, spouseAge, coupleInsurance)) {
      return;
    }

    this.props.setCoupleInsurance(!coupleInsurance);
    this.props.postState();
  }

  handleAgeChange = (event) => {
    const { value } = event.target;

    // input is empty
    if (!value) {
      this.props.setCustomerAge(undefined);
      return;
    }

    const age = parseInt(value, 10);
    this.processCustomerAge(age);
  };

  handleAgeChangeSpouse = (event) => {
    const { value } = event.target;

    // input is empty
    if (!value) {
      this.processSpouseAge(undefined);
    } else {
      const age = parseInt(value, 10);
      this.processSpouseAge(age);
    }
  };

  setCompensation = (compensation) => {
    const { coupleInsurance } = this.props;
    if (this.isCustomerAgeOk(this.props.customerAge, coupleInsurance)) {
      this.props.setCustomerCompensation(compensation);
    }
    if (this.isSpouseAgeOk(this.props.spouseAge)) {
      this.props.setSpouseCompensation(compensation);
    }
    this.props.postState();
  };

  handleCompensationChange = (event) => {
    const index = event.target.value;
    this.setCompensation(this.COMPENSATIONS[index]);
  };

  selectCustomer = () => {
    const { customerAgePrevious } = this.props;
    this.setState({
      customerIsSelected: true,
    });
    if (customerAgePrevious) {
      this.processCustomerAge(customerAgePrevious);
    } else {
      this.processCustomerAge(this.DEFAULT_CUSTOMER_AGE);
    }
  };

  deselectCustomer = () => {
    this.setState({
      customerIsSelected: false,
    });
    this.processCustomerAge(undefined);
    this.processSpouseAge(undefined);
    // spouse is not selected and customer is being toggled off
  };

  selectSpouse = () => {
    this.setState({
      spouseIsSelected: true,
    });
  };

  deselectSpouse = () => {
    this.setState({
      spouseIsSelected: false,
    });
  };

  doSubmit = () => {
    const { organizationRedirects, organizationId } = this.props;
    const defaultTarget = organizationRedirects.default;
    this.logValues(true, this.getPurchaseParams());

    if (organizationRedirects[organizationId] !== undefined) {
      window.top.location.href = organizationRedirects[organizationId];
    } else {
      window.top.location.href = defaultTarget;
      // Old implementation sent calculator values to purhcase flow
      // Keeping the old implementation here to make it easier to copy it for new purchase flow
      // const self = this;
      // post('get-key').then((response) => {
      //   const val = self.getPurchaseParams();
      //   const rkEncryptionKey = CryptoJS.enc.Base64.parse(response.data.substr(0, 24));
      //   const rkEncryptionIv = CryptoJS.enc.Base64.parse(response.data.substr(25));

      //   const utf8Stringified = CryptoJS.enc.Utf8.parse(val);
      //   const encrypted = CryptoJS.AES.encrypt(utf8Stringified.toString(), rkEncryptionKey, {
      //     mode: CryptoJS.mode.CBC,
      //     padding: CryptoJS.pad.Pkcs7,
      //     iv: rkEncryptionIv,
      //   });
      //   const encryptedStr = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
      //   const uriEncryptedString = encodeURIComponent(encryptedStr);
      //   window.top.location.href = self.PURCHASE_URL + uriEncryptedString + self.HEALTH_QUESTION_REDIRECT;
      // });
    }
  };

  logValues = (log, calcParams) => {
    if (!log) {
      return;
    }

    const {
      customerCompensation, spouseCompensation, totalPerMonth, customerAge, spouseAge,
    } = this.props;

    /* eslint-disable no-console */
    console.log(this.props);
    console.log(
      `cust:${customerAge} spouse: ${spouseAge} turva: ${customerCompensation} spouseCompensation: ${spouseCompensation} totalPerMonth: ${totalPerMonth}`,
    );
    console.log(calcParams);
    /* eslint-enable no-console */
  };

  render = () => {
    const {
      coupleInsurance, totalPerMonth, totalPerMonthNoOrg, lang,
      customerAge, spouseAge,
    } = this.props;

    const uiLang = (!lang || lang === 'fi') ? '' : '-sv';
    let decimals = 0;
    let price = 0;

    if (totalPerMonth > 0) {
      price = Math.floor(totalPerMonth);
      decimals = (totalPerMonth - Math.floor(totalPerMonth)) * 100;
    }

    const compensation = this.getCompensation();

    let compensationText = '';
    if (compensation > 0) {
      compensationText = `${compensation / 1000} 000`;
    }

    const { customerIsSelected, spouseIsSelected } = this.state;

    const purchaseDisabled = !this.isCustomerAgeOk(customerAge, coupleInsurance)
      || (spouseIsSelected && !this.isSpouseAgeOk(spouseAge))
      || purchaseApiMaintenance;

    const coupleIsSelectable = this.isCoupleOk(customerAge, spouseAge, coupleInsurance);

    const coupleLabelClasses = {
      coupleLabel: true,
      labelDisabled: !coupleIsSelectable,
    };

    const sliderClasses = {
      slider: true,
      round: true,
      //   sliderVisible: coupleIsSelectable || customerIsSelected,
      disabled: !coupleIsSelectable,
    };

    let priceElement = (
      <div className="priceContainer">
        <div className="sumMajor"><span>{formatNumber(price, false)}</span></div>
        <div className="sumMinor">
          {`,${zeroPad(formatNumber(decimals, false), 2)}`}
          {' '}
          {i18n.t(`priceDisplayUnit${uiLang}`)}
        </div>
      </div>
    );

    let infoTextSection;
    if (purchaseApiMaintenance) {
      priceElement = (<div />);
      infoTextSection = <div className="infoTextError">{i18n.t('purchaseApiMaintenanceShort')}</div>;
    } else if (!customerIsSelected || totalPerMonth == 0 || purchaseDisabled) {
      priceElement = (<div />);
      const errorKey = coupleInsurance && !coupleIsSelectable
        ? 'embeddedCalcCoupleError'
        : 'embeddedCalcPriceError';
      infoTextSection = <div className="infoTextError">{i18n.t(`${errorKey}${uiLang}`)}</div>;
    } else {
      const formattedTotalPerMonthNoOrg = formatPrice(totalPerMonthNoOrg, 2);
      const noOrgPrice = i18n.t(`priceWithoutMembership${uiLang}`, { totalPerMonthNoOrg: formattedTotalPerMonthNoOrg });
      infoTextSection = <div className="priceless">{noOrgPrice}</div>;
    }

    const sliderIndex = this.COMPENSATIONS.findIndex((c) => c === compensation);

    return (
      <div className="calcLayout">
        <section className="col1">
          <div className="col1-row1">
            <div className="labelTitle">{i18n.t(`embeddedCalcTitle${uiLang}`)}</div>
          </div>
          <div className="col1-row2">
            <AgeInput
              customerAge={customerAge}
              spouseAge={spouseAge}
              customerSelected={customerIsSelected}
              spouseSelected={spouseIsSelected}
              onCustomerAgeChange={this.handleAgeChange}
              onSpouseAgeChange={this.handleAgeChangeSpouse}
              customerMinAge={coupleInsurance ? this.COUPLE_INSURANCE_MIN_AGE : this.MIN_AGE}
              spouseMinAge={this.SPOUSE_MIN_AGE}
              customerMaxAge={this.MAX_AGE}
              spouseMaxAge={this.MAX_AGE}
              lang={lang}
              combined={coupleInsurance}
            />
          </div>
          <section className="col1-row3">
            <div className="coupleInsuranceGroup">
              <div className={classNames(coupleLabelClasses)} id="coupleInsuranceLabel">
                {i18n.t(`embeddedProductCouple${uiLang}`)}
              </div>
              <div
                role="checkbox"
                aria-checked={coupleInsurance > 0}
                aria-labelledby="coupleInsuranceLabel"
                className="switch"
                tabIndex="0"
                onKeyDown={this.handleCoupleInsuranceKeyDown}
              >
                <input
                  type="checkbox"
                  id="coupleInsurance"
                  aria-labelledby="coupleInsuranceLabel"
                  onClick={this.handleCoupleInsuranceCheckbox}
                  onChange={this.handleCoupleInsuranceCheckbox}
                  checked={coupleInsurance > 0}
                  disabled={!coupleIsSelectable}
                />
                <div className={classNames(sliderClasses)} onClick={this.handleCoupleInsuranceCheckbox} />
              </div>
            </div>
          </section>
        </section>
        <section className="col2">
          <section className="col2-row1">
            <CompensationGauge
              label={i18n.t(`embeddedSafety${uiLang}`)}
              maxCompensation={this.MAX_COMPENSATION_INDEX}
              defaultIndex={sliderIndex}
              onChange={(e) => this.handleCompensationChange(e)}
              disabled={!this.props.customerAge && !this.props.spouseAge}
              selectedCompensation={compensationText}
              id={`embeddedSafety${uiLang}`}
            />
          </section>
          <section className="col2-row2">
            {priceElement}
            <div className="buttonContainer">
              <button className="buttonBuy" onClick={this.doSubmit} disabled={purchaseDisabled}>
                {i18n.t(`embeddedStartPurchaseFlow${uiLang}`)}
              </button>
            </div>
          </section>
          <section className="col2-row3">{infoTextSection}</section>
        </section>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  customerAge: getCustomerAge(state),
  customerAgePrevious: getCustomerAgePrevious(state),
  spouseAge: getSpouseAge(state),
  spouseAgePrevious: getSpouseAgePrevious(state),
  customerCompensation: getCustomerCompensation(state),
  spouseCompensation: getSpouseCompensation(state),
  coupleInsurance: isCoupleInsurance(state),
  organizationId: getOrganizationId(state),
  totalPerMonth: getNormalTotalPerMonth(state),
  totalPerMonthNoOrg: getTotalPerMonthNoOrg(state),
  lang: getLang(state),
  organizationRedirects: getOrganizationRedirects(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCustomerAge: (age) => dispatch(setCustomerAge(age)),
  setSpouseAge: (age) => dispatch(setSpouseAge(age)),
  setCustomerCompensation: (compensation) => dispatch(setCustomerCompensation(compensation)),
  setSpouseCompensation: (compensation) => dispatch(setSpouseCompensation(compensation)),
  setCoupleInsurance: (coupleInsurance) => dispatch(setCoupleInsurance(coupleInsurance)),
  setOrganization: (id) => dispatch(setOrganization(id)),
  addSpouseProduct: (prod) => dispatch(addSpouseProduct(prod)),
  addCustomerProduct: (prod) => dispatch(addCustomerProduct(prod)),
  spouseHasProduct: (prod) => dispatch(spouseHasProduct(prod)),
  customerHasProduct: (prod) => dispatch(customerHasProduct(prod)),
  postState: () => postState(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmbeddedCalculator);
