import includes from 'lodash/includes';

import CalculatorModel from '../../models/calculatorModel';
import * as actionTypes from '../actionTypes';
import {
  sampoGroupPersonnel, findOrganization, defaultOrg, getDefaultOrganization, getMandatumLifeOrganization, getMaxCompensation,
} from '../../models/organizationModel';
import { getOrganizations } from './dataReducer';

export const defaultProducts = ['LIFE'];
export const defaultCompensation = 100000;
const defaultBillsPerYear = 4;
const defaultCompensations = [50000, 100000, 150000, 200000, 250000];
export const defaultCICompensation = 30000;
export const ciCompensations = [20000, 30000, 40000];
export const defaultAge = 30;

// TODO: split 'selected' to smaller sub-reducers

export const initialState = new CalculatorModel({
  lang: 'fi',
  customerAge: defaultAge,
  customerAgePrevious: undefined,
  spouseAge: undefined,
  spouseAgePrevious: defaultAge,
  childrenFrom0to9: 0,
  childrenFrom10to12: 0,
  childrenFrom13to17: 0,
  organizationId: undefined,
  customerCompensation: defaultCompensation,
  spouseCompensation: undefined,
  compensations: defaultCompensations,
  customerProducts: defaultProducts,
  spouseProducts: [],
  billsPerYear: defaultBillsPerYear,
  coupleInsurance: false,
  coupleInsuranceCompensation: 0,
  memberOfOrganization: true,
  campaignCode: '',
  campaignDescription: undefined,
  campaignCodeValid: false,
  shortBuyChain: false,
  childDABCompensation: 20000,
  childDAICompensation: 100000,
  childDALCompensation: 5000,
  customerCICompensation: defaultCICompensation,
  spouseCICompensation: undefined,
  customerSmoking: false,
  spouseSmoking: false,
  ciCompensations,

  // TODO: refactor this 'internal' to something else
  internal: {},
});

const selected = (state = initialState, action) => {
  switch (action && action.type) {
    case actionTypes.SET_LANG: {
      const { lang } = action;
      return {
        ...state,
        lang,
      };
    }
    case actionTypes.SET_CUSTOMER_AGE: {
      const { customerAge } = action;
      return {
        ...state,
        customerAge,
      };
    }
    case actionTypes.SET_SPOUSE_AGE: {
      const { spouseAge } = action;
      return {
        ...state,
        spouseAge,
      };
    }
    case actionTypes.SET_CUSTOMER_AGE_PREVIOUS: {
      const { customerAge } = action;
      return {
        ...state,
        customerAgePrevious: customerAge,
      };
    }
    case actionTypes.SET_SPOUSE_AGE_PREVIOUS: {
      const { spouseAge } = action;
      return {
        ...state,
        spouseAgePrevious: spouseAge,
      };
    }
    case actionTypes.SET_ORGANIZATION: {
      const { organizationId } = action;
      return {
        ...state,
        organizationId,
      };
    }
    case actionTypes.SET_MEMBER_OF_ORGANIZATION: {
      const { memberOfOrganization } = action;
      return {
        ...state,
        memberOfOrganization,
      };
    }
    case actionTypes.SET_COUPLE_INSURANCE: {
      const { coupleInsurance } = action;
      return {
        ...state,
        coupleInsurance,
      };
    }
    case actionTypes.SET_CUSTOMER_PRODUCTS: {
      const { customerProducts } = action;
      return {
        ...state,
        customerProducts,
      };
    }
    case actionTypes.SET_SPOUSE_PRODUCTS: {
      const { spouseProducts } = action;
      return {
        ...state,
        spouseProducts,
      };
    }
    case actionTypes.SET_COMPENSATION: {
      const { customerCompensation, spouseCompensation, compensations } = action;
      return {
        ...state,
        customerCompensation,
        spouseCompensation,
        compensations,
      };
    }
    case actionTypes.SET_CHILDREN: {
      const { childrenFrom0to9, childrenFrom10to12, childrenFrom13to17 } = { ...state, ...action };
      return {
        ...state,
        childrenFrom0to9,
        childrenFrom10to12,
        childrenFrom13to17,
      };
    }
    case actionTypes.SET_CAMPAIGN_ACTIVE: {
      const { campaignActive } = action;
      return {
        ...state,
        campaignActive,
      };
    }
    case actionTypes.SET_CAMPAIGN_DESCRIPTION: {
      const { campaignDescription } = action;
      return {
        ...state,
        campaignDescription,
      };
    }
    case actionTypes.SET_CAMPAIGN_CODE_VALID: {
      const { campaignCodeValid } = action;
      return {
        ...state,
        campaignCodeValid,
      };
    }
    case actionTypes.SET_CUSTOMER_MUNICIPALITY: {
      // Used for something?  Or only from purchase side?
      const { municipality } = action;
      return {
        ...state,
        municipality,
      };
    }
    case actionTypes.SET_CUSTOMER_COMPENSATION: {
      const { customerCompensation } = action;
      return {
        ...state,
        customerCompensation,
      };
    }
    case actionTypes.SET_SPOUSE_COMPENSATION: {
      const { spouseCompensation } = action;
      return {
        ...state,
        spouseCompensation,
      };
    }
    case actionTypes.ADD_CUSTOMER_PRODUCT: {
      const { newProducts } = action;
      return {
        ...state,
        customerProducts: newProducts,
      };
    }
    case actionTypes.ADD_SPOUSE_PRODUCT: {
      const { newProducts } = action;
      return {
        ...state,
        spouseProducts: newProducts,
      };
    }
    case actionTypes.REMOVE_CUSTOMER_PRODUCT: {
      const { newProducts } = action;
      return {
        ...state,
        customerProducts: newProducts,
      };
    }
    case actionTypes.REMOVE_SPOUSE_PRODUCT: {
      const { newProducts } = action;
      return {
        ...state,
        spouseProducts: newProducts,
      };
    }
    case actionTypes.REMOVE_ALL_CUSTOMER_PRODUCTS: {
      return {
        ...state,
        customerProducts: [],
      };
    }
    case actionTypes.REMOVE_ALL_SPOUSE_PRODUCTS: {
      return {
        ...state,
        spouseProducts: [],
      };
    }
    case actionTypes.SET_BILLS_PER_YEAR: {
      const { billsPerYear } = action;
      return {
        ...state,
        billsPerYear,
      };
    }
    case actionTypes.SET_CUSTOMER_SMOKING: {
      const { smoking } = action;
      return {
        ...state,
        customerSmoking: smoking,
      };
    }
    case actionTypes.SET_SPOUSE_SMOKING: {
      const { smoking } = action;
      return {
        ...state,
        spouseSmoking: smoking,
      };
    }
    case actionTypes.SET_CUSTOMER_CI_COMPENSATION: {
      const { customerCICompensation } = action;
      return {
        ...state,
        customerCICompensation,
      };
    }
    case actionTypes.SET_SPOUSE_CI_COMPENSATION: {
      const { spouseCICompensation } = action;
      return {
        ...state,
        spouseCICompensation,
      };
    }
    case actionTypes.SET_CI_COMPENSATIONS: {
      const { ciCompensations } = action;
      return {
        ...state,
        ciCompensations,
      };
    }

    default: {
      return state;
    }
  }
};

export default selected;

// Selectors
export const getLang = (state) => state.selected.lang;
export const getCustomerAge = (state) => state.selected.customerAge;
export const getCustomerAgePrevious = (state) => state.selected.customerAgePrevious;
export const getSpouseAge = (state) => state.selected.spouseAge;
export const getSpouseAgePrevious = (state) => state.selected.spouseAgePrevious;
export const hasCustomer = (state) => !!state.selected.customerAge;
export const hasSpouse = (state) => !!state.selected.spouseAge;
export const getCustomerProducts = (state) => state.selected.customerProducts || [];
export const getSpouseProducts = (state) => state.selected.spouseProducts || [];
export const customerHasProduct = (state, product) => {
  const products = state.selected.customerProducts || [];
  const match = products.find((p) => p === product);
  return match !== undefined;
};
export const spouseHasProduct = (state, product) => {
  const products = state.selected.spouseProducts || [];
  const match = products.find((p) => p === product);
  return match !== undefined;
};
export const getCustomerCompensation = (state) => state.selected.customerCompensation;
export const getSpouseCompensation = (state) => state.selected.spouseCompensation;
export const getCompensations = (state) => {
  const organizations = getOrganizations(state);
  // initialization still going on
  if (organizations.length === 0 || !state.selected.compensations) {
    return [];
  }
  const organization = isMemberOfOrganization(state)
    ? getOrganization(state) : getMandatumLifeOrganization(organizations);
  const lifeProduct = organization.products.find((prod) => prod.productType === 'LIFE');
  const { maxCompensation } = lifeProduct;
  return state.selected.compensations.filter((compensation) => compensation <= maxCompensation);
};
export const getOrganizationId = (state) => state.selected.organizationId;
export const getOrganization = (state) => {
  const { organizationId } = state.selected;
  const organizations = getOrganizations(state);
  const orgId = organizationId || defaultOrg;
  return findOrganization(orgId, organizations) || getDefaultOrganization(organizations);
};
export const isDefaultOrganization = (state) => state.selected.organizationId === defaultOrg;
export const isMemberOfOrganization = (state) => state.selected.memberOfOrganization;
export const getBillsPerYear = (state) => state.selected.billsPerYear;
export const isCoupleInsurance = (state) => state.selected.coupleInsurance;
export const isCoupleInsurancePossible = (state) => {
  const agesValid = state !== undefined && getSpouseAge(state) >= 18 && getCustomerAge(state) >= 18;
  return agesValid && isMemberOfOrganization(state);
};
export const isShortBuyChain = (state) => state.selected.shortBuyChain;
export const isCampaignCodeValid = (state) => state.selected.campaignCodeValid;
export const isCampaignActive = (state) => state.selected.campaignActive;
export const getCampaignDescription = (state) => state.selected.campaignDescription;
export const getChildrenFrom0to9 = (state) => state.selected.childrenFrom0to9;
export const getChildrenFrom10to12 = (state) => state.selected.childrenFrom10to12;
export const getChildrenFrom13to17 = (state) => state.selected.childrenFrom13to17;
export const getChildDABCompensation = (state) => state.selected.childDABCompensation;
export const getChildDAICompensation = (state) => state.selected.childDAICompensation;
export const getChildDALCompensation = (state) => state.selected.childDALCompensation;
export const isChildInsurancePossible = (state) => isMemberOfOrganization(state) && getOrganizationId(state) !== sampoGroupPersonnel;
export const getChildCount = (state) => getChildrenFrom0to9(state) + getChildrenFrom10to12(state) + getChildrenFrom13to17(state);
export const hasChildren = (state) => getChildCount(state) > 0;
export const isAccidentalInsurancePossible = (state) => isMemberOfOrganization(state) && getOrganizationId(state) !== sampoGroupPersonnel;
export const isCustomerLifeSelected = (state) => includes(state.selected.customerProducts, 'LIFE');
export const isSpouseLifeSelected = (state) => includes(state.selected.spouseProducts, 'LIFE');
export const isCustomerCISelected = (state) => includes(state.selected.customerProducts, 'CI');
export const isSpouseCISelected = (state) => includes(state.selected.spouseProducts, 'CI');

export const isCustomerSmoking = (state) => state.selected.customerSmoking;
export const isSpouseSmoking = (state) => state.selected.spouseSmoking;
export const getCICompensations = (state) => state.selected.ciCompensations;
export const getCustomerCICompensation = (state) => state.selected.customerCICompensation;
export const getSpouseCICompensation = (state) => state.selected.spouseCICompensation;
export const getCoupleInsuranceCompensation = (state) => state.selected.coupleInsuranceCompensation;
export const getMaximumCompensation = (state) => {
  const organizations = getOrganizations(state);
  const memberOfOrganization = isMemberOfOrganization(state);
  const organizationId = getOrganizationId(state);

  return getMaxCompensation(organizationId, organizations, memberOfOrganization);
};

/**
 * selected.coupleInsurance means the radio button value in CoupleInsurance component.
 * So it can be true even if there is no spouse.
 * If we want to know if coupleInsurance is actually valid choice
 * the statement has to be true.
 */
export const isCoupleInsuranceSelected = (state) => state !== undefined
  && isCoupleInsurancePossible(state)
  && isCustomerLifeSelected(state)
  && isSpouseLifeSelected(state)
  && state.selected.coupleInsurance;

export const allProducts = (state) => [...state.selected.customerProducts, ...state.selected.spouseProducts];
export const hasCi = (state) => allProducts(state).includes('CI');
export const hasLife = (state) => allProducts(state).includes('LIFE');
export const hasMLLife = (state) => hasLife(state) && !isMemberOfOrganization(state);
export const hasPrimusLife = (state) => hasLife(state) && isMemberOfOrganization(state);
export const hasAccidentalProduct = (state) => hasChildren(state)
  || allProducts(state).filter((p) => p !== 'CI' && p !== 'LIFE').length > 0;
export const hasPrimus = (state) => hasPrimusLife(state) || hasAccidentalProduct(state);

// TODO:  remove this selector and replace with more specific ones
export const getSelected = (state) => state.selected;
