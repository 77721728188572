import assign from 'lodash/assign';
import reduce from 'lodash/reduce';

import validate from '../util/validate';
import { safeNum, safeFloat } from '../util/utils';

const { check } = validate;
const v = validate.validators;

export function NeedCalculatorModel(properties) {
  assign(this, {}, properties);
}

const ValidFloat = check(v.and(v.required, v.min(0)), 'validationPositiveNumber');
const ValidNumber = check(v.and(v.required, v.min(0)), 'validationPositiveNumber');

const schema = {
  loan: ValidFloat,
  income: ValidFloat,
  children: ValidNumber,
  taxableProperty: ValidFloat,
  taxPercentage: ValidFloat,
};

const modelValidator = validate.build(schema);

NeedCalculatorModel.prototype = {
  clean() {
    return reduce(
      this,
      (acc, value, property) => {
        acc[property] = (value || '0')
          .toString()
          .replace(/ /g, '')
          .replace(/,/g, '.');
        return acc;
      },
      {},
    );
  },

  validate(relaxed, filter) {
    return modelValidator(this.clean(), relaxed, filter);
  },

  calculateNeed() {
    const cleaned = this.clean();
    return (
      safeNum(cleaned.children) * 20000
      + safeFloat(cleaned.income) * 12
      + safeFloat(cleaned.loan)
      + safeFloat(cleaned.taxableProperty) * (safeFloat(cleaned.taxPercentage) / 100)
    );
  },
};

export default NeedCalculatorModel;
