import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import LoneAndIncome from './needCalculator/loanAndIncome';
import Children from './needCalculator/children';
import TaxablePropertyAndTaxPercentage from './needCalculator/taxablePropertyAndTaxPercentage';
import Result from './needCalculator/result';
// import localization from './localization/loader';

const needCalculator = (store) => {
  const loanAndIncome = document.getElementById('loneAndIncome');
  if (loanAndIncome) {
    ReactDOM.render(
      <Provider store={store}>
        <LoneAndIncome />
      </Provider>,
      loanAndIncome,
    );
  }

  const children = document.getElementById('children');
  if (children) {
    ReactDOM.render(
      <Provider store={store}>
        <Children />
      </Provider>,
      children,
    );
  }

  const taxablePropertyAndTaxPercentage = document.getElementById('taxablePropertyAndTaxPercentage');
  if (taxablePropertyAndTaxPercentage) {
    ReactDOM.render(
      <Provider store={store}>
        <TaxablePropertyAndTaxPercentage />
      </Provider>,
      taxablePropertyAndTaxPercentage,
    );
  }

  const needResult = document.getElementById('needResult');
  if (needResult) {
    ReactDOM.render(
      <Provider store={store}>
        <Result />
      </Provider>,
      needResult,
    );
  }
};

export default needCalculator;
