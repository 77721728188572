import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import NavBar, { pageIndex } from './navBar';

import i18n from '../localization/i18n-wrapper';
import { getCurrentPage } from '../redux/reducers/purchaseReducer';
import { isMemberOfOrganization } from '../redux/reducers/selectedReducer';

class PageHeader extends React.Component {
  static displayName = 'PageHeader';

  static propTypes = {
    memberOfOrganization: PropTypes.bool.isRequired,
    currentPage: PropTypes.string.isRequired,
  };

  onChange = () => {
    this.forceUpdate();
  };

  getPurchaseText = (index) => {
    const { memberOfOrganization } = this.props;
    if (index === 1 && !memberOfOrganization) {
      return `purchaseTextNoOrg${index}`;
    }
    return `purchaseText${index}`;
  };

  render = () => {
    const { currentPage } = this.props;
    const index = pageIndex(currentPage) + 1;

    const title = `purchaseTitle${index}`;
    const text = this.getPurchaseText(index);

    return (
      <div>
        <h1>{i18n.t(title)}</h1>
        <p>{i18n.t(text)}</p>
        <div id="progressDisplay">
          <NavBar />
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  memberOfOrganization: isMemberOfOrganization(state),
  currentPage: getCurrentPage(state),
});

export default connect(mapStateToProps)(PageHeader);
