import get from 'lodash/get';
import merge from 'lodash/merge';

import * as actionTypes from '../actionTypes';
import { getHealthQuestions, getOptionalHealthQuestions, clearAnswers } from '../../models/healthQuestionModel';
import {
  updateApprovalQuestions,
  updateCIApprovalQuestions,
} from '../../models/approvalQuestionModel';
import { getPostOfficeByCode } from '../reducers/dataReducer';
import {
  getOptionalCustomerQuestions, getOptionalSpouseQuestions, getSpouseQuestions,
} from '../reducers/purchaseReducer';
import {
  spouseHasProduct, getCustomerAge, getSpouseAge, isMemberOfOrganization,
} from '../reducers/selectedReducer';
import { removeSpouseProduct, setCustomerAge, setSpouseAge } from './selectedActions';
import { validateSsn, calculateAge } from '../../util/utils';
import { postState } from '../../api/purchaseApi';

export const setYesNoQuestionAnswer = (category, index, answer) => ({
  type: actionTypes.SET_YESNOQUESTION_ANSWER,
  category,
  index,
  answer,
});

export const setCheckboxAnswer = (category, index, answer) => ({
  type: actionTypes.SET_CHECKBOX_ANSWER,
  category,
  index,
  answer,
});

export const setSpouseVastusStatus = (spouseVastusOk) => ({
  type: actionTypes.SET_SPOUSE_VASTUS_STATUS,
  spouseVastusOk,
});

export const setCustomerBeneficiary = (beneficiary) => ({
  type: actionTypes.SET_CUSTOMER_BENEFICIARY,
  beneficiary,
});

export const setSpouseBeneficiary = (beneficiary) => ({
  type: actionTypes.SET_SPOUSE_BENEFICIARY,
  beneficiary,
});

// Spouse present to fill health questions
export const setSpousePresent = (spousePresent) => (dispatch, getState) => {
  if (!spousePresent) {
    dispatch(clearSpouseHealthQuestionAnswers());
    dispatch(clearSpouseOptionalQuestionAnswers());
    dispatch(setSpouseVastusStatus(false));
    const state = getState();
    if (spouseHasProduct(state, 'CI')) { // drop CI
      dispatch(removeSpouseProduct('CI'));
    }
    if (spouseHasProduct(state, 'LIFE') && !isMemberOfOrganization(state)) { // drop LIFE ML
      dispatch(removeSpouseProduct('LIFE'));
    }
  }
  dispatch({
    type: actionTypes.SET_SPOUSE_PRESENT,
    spousePresent,
  });
};

export const setCustomerMunicipality = (municipality) => ({
  type: actionTypes.SET_CUSTOMER_MUNICIPALITY,
  municipality,
});

export const setSpouseMunicipality = (municipality) => ({
  type: actionTypes.SET_SPOUSE_MUNICIPALITY,
  municipality,
});

export const setQuestions = () => (dispatch) => {
  dispatch(setHealthQuestions());
  dispatch(setApprovalQuestions());
};

export const setHealthQuestions = () => (dispatch, getState) => {
  const {
    customerProducts,
    spouseProducts,
    memberOfOrganization,
  } = getState().selected;

  const {
    customerQuestions,
    spouseQuestions,
    optionalCustomerQuestions,
    optionalSpouseQuestions,
  } = getState().purchase;
  const newCustomerQuestions = getHealthQuestions(
    customerProducts,
    memberOfOrganization,
    customerQuestions,
    getOptionalHealthQuestions(customerProducts, optionalCustomerQuestions),
  );
  const newSpouseQuestions = getHealthQuestions(
    spouseProducts,
    memberOfOrganization,
    spouseQuestions,
    getOptionalHealthQuestions(spouseProducts, optionalSpouseQuestions),
  );
  const newOptionalCustomerQuestions = getOptionalHealthQuestions(customerProducts, optionalCustomerQuestions);
  const newOptionalSpouseQuestions = getOptionalHealthQuestions(spouseProducts, optionalSpouseQuestions);
  dispatch({
    type: actionTypes.SET_HEALTH_QUESTIONS,
    customerQuestions: newCustomerQuestions,
    spouseQuestions: newSpouseQuestions,
    optionalCustomerQuestions: newOptionalCustomerQuestions,
    optionalSpouseQuestions: newOptionalSpouseQuestions,
  });
};

export const setCustomerOptionalQuestions = (optionalCustomerQuestions) => ({
  type: actionTypes.SET_CUSTOMER_OPTIONAL_HEALTH_QUESTIONS,
  optionalCustomerQuestions,
});

export const setSpouseOptionalQuestions = (optionalSpouseQuestions) => ({
  type: actionTypes.SET_SPOUSE_OPTIONAL_HEALTH_QUESTIONS,
  optionalSpouseQuestions,
});

export const clearCustomerOptionalQuestionAnswers = () => (dispatch, getState) => {
  const optionalQuestions = getOptionalCustomerQuestions(getState());
  const clearedQuestions = clearAnswers(optionalQuestions);
  dispatch({
    type: actionTypes.SET_CUSTOMER_OPTIONAL_HEALTH_QUESTIONS,
    optionalCustomerQuestions: clearedQuestions,
  });
};

export const clearSpouseOptionalQuestionAnswers = () => (dispatch, getState) => {
  const optionalQuestions = getOptionalSpouseQuestions(getState());
  const clearedQuestions = clearAnswers(optionalQuestions);
  dispatch({
    type: actionTypes.SET_SPOUSE_OPTIONAL_HEALTH_QUESTIONS,
    optionalSpouseQuestions: clearedQuestions,
  });
};

export const clearSpouseHealthQuestionAnswers = () => (dispatch, getState) => {
  const healthQuestions = getSpouseQuestions(getState());
  const clearedQuestions = clearAnswers(healthQuestions);
  dispatch({
    type: actionTypes.SET_SPOUSE_HEALTH_QUESTIONS,
    spouseQuestions: clearedQuestions,
  });
};

export const setApprovalQuestions = () => (dispatch, getState) => {
  const updatedApprovalQuestions = updateApprovalQuestions(getState());
  dispatch({
    type: actionTypes.SET_APPROVAL_QUESTIONS,
    approvalQuestions: updatedApprovalQuestions,
  });
};

export const resetApprovalQuestionAnswers = () => (dispatch, getState) => {
  const { approvalQuestions } = getState().purchase;
  const updatedApprovalQuestions = approvalQuestions.map((question) => ({
    key: { ...question.key },
  }));
  dispatch({
    type: actionTypes.SET_APPROVAL_QUESTIONS,
    approvalQuestions: updatedApprovalQuestions,
  });
};

export const setDataField = (change) => (dispatch, getState) => {
  const state = getState();
  const newData = merge(change, fixCustomerCity(change, state), fixSpouseCity(change, state));

  dispatch({
    type: actionTypes.SET_DATA_FIELD,
    ...newData,
  });

  const spouseDataUpdate = change.spouse !== undefined;
  if (spouseDataUpdate) {
    dispatch(setSpouseVastusStatus(false));
  }

  fixCustomerAge(change, dispatch, state);
  fixSpouseAge(change, dispatch, state);
};

const fixCustomerCity = (change, state) => fixCity('customer', change, state);

const fixSpouseCity = (change, state) => fixCity('spouse', change, state);

const fixCity = (personType, change, state) => {
  const customerPostCode = get(change, `${personType}.address.postCode`);
  if (customerPostCode) {
    const city = getPostOfficeByCode(state, customerPostCode);
    if (city) {
      return { [`${personType}`]: { address: { city: city.name } } };
    }
  }
  return undefined;
};

const fixCustomerAge = (change, dispatch, state) => {
  const socialSecurityNumber = get(change, 'customer.socialSecurityNumber');
  const customerAge = getCustomerAge(state);
  if (customerAge && socialSecurityNumber && validateSsn(socialSecurityNumber)) {
    const calculatedAge = calculateAge(socialSecurityNumber);
    if (calculatedAge !== customerAge) {
      dispatch(setCustomerAge(calculatedAge));
      postState(dispatch);
    }
  }
};

const fixSpouseAge = (change, dispatch, state) => {
  const socialSecurityNumber = get(change, 'spouse.socialSecurityNumber');
  if (socialSecurityNumber && validateSsn(socialSecurityNumber)) {
    const calculatedAge = calculateAge(socialSecurityNumber);
    if (calculatedAge !== getSpouseAge(state)) {
      dispatch(setSpouseAge(calculatedAge));
      postState(dispatch);
    }
  }
};

export const setPageAndPriceVisibility = (currentPage, priceVisible) => ({
  type: actionTypes.SET_CURRENT_PAGE_AND_PRICE_VISIBILITY,
  currentPage,
  priceVisible,
});

export const setCiApprovalQuestions = () => (dispatch, getState) => {
  const { ciApprovalQuestions } = getState().purchase;
  const updatedApprovalQuestions = updateCIApprovalQuestions(ciApprovalQuestions);
  dispatch({
    type: actionTypes.SET_CI_APPROVAL_QUESTIONS,
    ciApprovalQuestions: updatedApprovalQuestions,
  });
};

export const setCampaignCode = (campaignCode) => ({
  type: actionTypes.SET_CAMPAIGN_CODE,
  campaignCode,
});

export const setCampaignType = (campaignType) => ({
  type: actionTypes.SET_CAMPAIGN_TYPE,
  campaignType,
});
