import * as React from 'react';
import * as PropTypes from 'prop-types';

import OrgPicker from '../calculator/orgPicker';
import FieldBase from '../common/fieldBase';

export default class OrgField extends React.Component {
  state = { open: false };

  onVisibilityChange = (visible) => {
    this.setState({ open: visible });
  };

  render() {
    const p = this.props;
    const self = this;

    function inputOrReadOnly() {
      if (p.readOnly) {
        let displayValue = '';
        if (p.organization) {
          displayValue = p.organization.name;
        }

        return <p className="form-control-static">{displayValue}</p>;
      }
      return <OrgPicker onVisibilityChange={self.onVisibilityChange} open={self.state.open} />;
    }

    return <FieldBase {...p}>{inputOrReadOnly()}</FieldBase>;
  }
}

OrgField.displayName = 'OrgField';

OrgField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  organization: PropTypes.object,
  readOnly: PropTypes.bool,
};

OrgField.defaultProps = {
  organization: {},
  readOnly: false,
};
