import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import i18n from '../localization/i18n-wrapper';

import ProductRow from './productRow';
import CoupleInsurance from './coupleInsurance';
import { CompensationRows } from './compensationRow';

import {
  isMemberOfOrganization,
  getSpouseProducts,
  getCustomerProducts,
  getSpouseCompensation,
  getCustomerCompensation,
  getCompensations,
  isCoupleInsuranceSelected,
  isCustomerLifeSelected,
  isSpouseLifeSelected,
  hasSpouse,
  hasCustomer,
  defaultCompensation,
  isCoupleInsurancePossible,
} from '../redux/reducers/selectedReducer';
import {
  getNormalTotalAdultLifePerMonth,
  getNormalCoupleInsurancePerMonth,
  getNormalSpouseLifePerMonth,
  getNormalCustomerLifePerMonth,
} from '../redux/reducers/priceReducer';
import {
  setSpouseCompensation,
  setCustomerCompensation,
  addCustomerProduct,
  addSpouseProduct,
  removeSpouseProduct,
  removeCustomerProduct,
} from '../redux/actions/selectedActions';
import purchaseApi from '../api/purchaseApi';
import { isSpouseDifferent, isSpousePresent } from '../redux/reducers/purchaseReducer';
import { setSpousePresent, setSpouseVastusStatus } from '../redux/actions/purchaseActions';

class LifeProduct extends React.Component {
  static displayName = 'LifeProduct';

  static propTypes = {
    context: PropTypes.string,
    identifier: PropTypes.string,
    analyticsIdentifier: PropTypes.string,
    showInsuranceTitle: PropTypes.bool,
    customerAge: PropTypes.number,
    spouseAge: PropTypes.number,
    customerLifeSelected: PropTypes.bool.isRequired,
    spouseLifeSelected: PropTypes.bool.isRequired,
    customerCompensation: PropTypes.number,
    spouseCompensation: PropTypes.number,
    compensations: PropTypes.array,
    spouseDifferent: PropTypes.bool.isRequired,
    coupleInsuranceSelected: PropTypes.bool.isRequired,
    coupleInsurancePossible: PropTypes.bool.isRequired,
    memberOfOrganization: PropTypes.bool.isRequired,
    lifePerMonth: PropTypes.number.isRequired,
    coupleInsurancePerMonth: PropTypes.number.isRequired,
    customerLifePerMonth: PropTypes.number,
    spouseLifePerMonth: PropTypes.number,
    customerProducts: PropTypes.array.isRequired,
    spouseProducts: PropTypes.array.isRequired,
    childrenFrom0to9: PropTypes.number,
    childrenFrom10to12: PropTypes.number,
    childrenFrom13to17: PropTypes.number,
    updateCustomerCompensation: PropTypes.func.isRequired,
    updateSpouseCompensation: PropTypes.func.isRequired,
    addCustomerLifeProduct: PropTypes.func.isRequired,
    addSpouseLifeProduct: PropTypes.func.isRequired,
    removeCustomerLifeProduct: PropTypes.func.isRequired,
    removeSpouseLifeProduct: PropTypes.func.isRequired,
    hasCustomer: PropTypes.bool.isRequired,
    postState: PropTypes.func.isRequired,
  };

  static productCode = 'LIFE';

  handleSelectAmount = (event) => {
    event.stopPropagation();
    this.updateCompensations(event.target.value);
    this.props.postState();
  };

  handleChange = (event) => {
    event.stopPropagation();
    if (event.target.checked) {
      this.addLifeProducts();
    } else {
      this.removeLifeProducts();
    }
    this.props.postState();
  };

  updateCompensations = (compensation) => {
    const { updateCustomerCompensation, updateSpouseCompensation } = this.props;
    if (this.isForCustomer()) {
      updateCustomerCompensation(compensation);
    }
    if (this.isForSpouse()) {
      updateSpouseCompensation(compensation);
    }
  };

  addLifeProducts = () => {
    const { addCustomerLifeProduct, addSpouseLifeProduct } = this.props;
    if (this.isForCustomer()) {
      addCustomerLifeProduct();
    }
    if (this.isForSpouse()) {
      addSpouseLifeProduct();
    }
  };

  removeLifeProducts = () => {
    const { removeCustomerLifeProduct, removeSpouseLifeProduct } = this.props;
    if (this.isForCustomer()) {
      removeCustomerLifeProduct();
    }
    if (this.isForSpouse()) {
      removeSpouseLifeProduct();
    }
  };

  isForCustomer = () => !!(this.props.hasCustomer && this.props.identifier !== 'spouse');

  isForSpouse = () => {
    const p = this.props;
    const spouseSameAsCustomer = p.hasSpouse && !p.spouseDifferent;
    const spouseSpecific = p.hasSpouse && p.spouseDifferent && p.identifier === 'spouse';
    const coupleInsurance = p.coupleInsuranceSelected;
    return !!(spouseSameAsCustomer || spouseSpecific || coupleInsurance);
  };

  render() {
    const {
      customerLifeSelected, spouseLifeSelected, spouseDifferent, coupleInsurancePossible, showInsuranceTitle,
    } = this.props;
    const { customerCompensation, spouseCompensation, compensations } = this.props;
    const { coupleInsuranceSelected, memberOfOrganization, identifier } = this.props;
    const {
      coupleInsurancePerMonth, lifePerMonth, spouseLifePerMonth, customerLifePerMonth,
    } = this.props;
    const { context } = this.props;
    const isSelected = this.isForCustomer() ? customerLifeSelected : spouseLifeSelected;
    const isDisabled = (this.isForCustomer() && !customerLifeSelected) || (this.isForSpouse() && !spouseLifeSelected);
    const isCoupleInsurance = coupleInsurancePossible && coupleInsuranceSelected;
    const infoKey = `${memberOfOrganization ? LifeProduct.productCode : 'LIFEML'}InfoKey`;
    let compensation = this.isForCustomer() ? customerCompensation : spouseCompensation;
    if (!compensation) {
      compensation = defaultCompensation;
    }

    let price = coupleInsurancePerMonth;
    if (!isCoupleInsurance) {
      if (spouseDifferent) {
        price = identifier === 'spouse' ? spouseLifePerMonth : customerLifePerMonth;
      } else {
        price = lifePerMonth;
      }
    }

    const sumRows = <CompensationRows compensations={compensations} />;
    const lifeSumSelector = (
      <span className="sumSelector">
        <span id="lifeAmountSelectorLabel">{i18n.t('productSelectorLifeAmount')}</span>
        <select
          disabled={isDisabled}
          className="form-control"
          aria-labelledby="lifeAmountSelectorLabel"
          onChange={this.handleSelectAmount}
          value={compensation}
        >
          {sumRows}
        </select>
      </span>
    );

    const coupleInsuranceElement = coupleInsurancePossible && identifier !== 'spouse'
      ? [<CoupleInsurance key="coupleInsurance" context={context} />]
      : [];

    const lifeInsuranceTitle = showInsuranceTitle
      ? (
        <h4 className="additionalTitle" key="productSelectorLifeTitle">
          {i18n.t('productSelectorLifeTitle')}
        </h4>
      )
      : [];
    return (
      <div>
        {lifeInsuranceTitle}
        <ProductRow
          key="LIFE"
          label={i18n.t('productSelectorLIFETitle')}
          onChange={this.handleChange}
          infoKey={infoKey}
          productCode={LifeProduct.productCode}
          price={price}
          selected={isSelected}
          analyticsIdentifier={this.props.analyticsIdentifier}
          additionalContent={coupleInsuranceElement}
          productDescription={lifeSumSelector}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lifePerMonth: getNormalTotalAdultLifePerMonth(state),
  customerLifePerMonth: getNormalCustomerLifePerMonth(state),
  spouseLifePerMonth: getNormalSpouseLifePerMonth(state),
  coupleInsurancePerMonth: getNormalCoupleInsurancePerMonth(state),
  memberOfOrganization: isMemberOfOrganization(state),
  compensations: getCompensations(state),
  spouseProducts: getSpouseProducts(state),
  customerProducts: getCustomerProducts(state),
  customerCompensation: getCustomerCompensation(state),
  spouseCompensation: getSpouseCompensation(state),
  hasCustomer: hasCustomer(state),
  hasSpouse: hasSpouse(state),
  isSpousePresent: isSpousePresent(state),
  spouseDifferent: isSpouseDifferent(state),
  coupleInsuranceSelected: isCoupleInsuranceSelected(state),
  customerLifeSelected: isCustomerLifeSelected(state),
  spouseLifeSelected: isSpouseLifeSelected(state),
  coupleInsurancePossible: isCoupleInsurancePossible(state),
});

const mapDispatchToProps = (dispatch) => ({
  addCustomerLifeProduct: () => {
    dispatch(addCustomerProduct(LifeProduct.productCode));
  },
  addSpouseLifeProduct: () => {
    dispatch(addSpouseProduct(LifeProduct.productCode));
  },
  removeCustomerLifeProduct: () => {
    dispatch(removeCustomerProduct(LifeProduct.productCode));
  },
  removeSpouseLifeProduct: () => {
    dispatch(removeSpouseProduct(LifeProduct.productCode));
  },
  updateCustomerCompensation: (value) => {
    dispatch(setCustomerCompensation(value));
  },
  updateSpouseCompensation: (value) => {
    dispatch(setSpouseCompensation(value));
  },
  updateBothCompensations: (value) => {
    dispatch(setCustomerCompensation(value));
    dispatch(setSpouseCompensation(value));
  },
  setSpousePresent: (value) => {
    dispatch(setSpousePresent(value));
  },
  postState: () => purchaseApi.postState(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LifeProduct);
