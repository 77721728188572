import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import i18n from '../localization/i18n-wrapper';
import purchaseApi from '../api/purchaseApi';
import ProductRow from './productRow';
import YesNoQuestion from '../purchase/yesNoQuestion';
import { CompensationRows } from './compensationRow';
import {
  isMemberOfOrganization,
  isCustomerSmoking,
  isSpouseSmoking,
  getCICompensations,
  getCustomerCICompensation,
  getSpouseCICompensation,
  isSpouseCISelected,
  isCustomerCISelected,
  hasSpouse,
  hasCustomer,
} from '../redux/reducers/selectedReducer';
import {
  getNormalTotalAdultCiPerMonth,
  getNormalCustomerCiPerMonth,
  getNormalSpouseCiPerMonth,
} from '../redux/reducers/priceReducer';
import {
  setCustomerSmoking,
  setSpouseSmoking,
  setCustomerCICompensation,
  setSpouseCICompensation,
  removeSpouseProduct,
  removeCustomerProduct,
  addCustomerProduct,
  addSpouseProduct,
} from '../redux/actions/selectedActions';
import { isSpouseDifferent } from '../redux/reducers/purchaseReducer';
import { setCustomerOptionalQuestions, setSpouseOptionalQuestions } from '../redux/actions/purchaseActions';

class CIProduct extends React.Component {
  static displayName = 'CIProduct';

  static propTypes = {
    context: PropTypes.string,
    identifier: PropTypes.string,
    analyticsIdentifier: PropTypes.string,
    customerSmoking: PropTypes.bool.isRequired,
    spouseSmoking: PropTypes.bool.isRequired,
    spouseDifferent: PropTypes.bool.isRequired,
    customerCISelected: PropTypes.bool.isRequired,
    spouseCISelected: PropTypes.bool.isRequired,
    customerCICompensation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    spouseCICompensation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    compensations: PropTypes.array.isRequired,
    spouseAge: PropTypes.number,
    customerProducts: PropTypes.array,
    spouseProducts: PropTypes.array,
    addCustomerCIProduct: PropTypes.func.isRequired,
    addSpouseCIProduct: PropTypes.func.isRequired,
    removeCustomerCIProduct: PropTypes.func.isRequired,
    removeSpouseCIProduct: PropTypes.func.isRequired,
    clearCustomerOptionalQuestions: PropTypes.func.isRequired,
    clearSpouseOptionalQuestions: PropTypes.func.isRequired,
    updateCustomerCICompensation: PropTypes.func.isRequired,
    updateSpouseCICompensation: PropTypes.func.isRequired,
    hasCustomer: PropTypes.bool.isRequired,
    setCustomerSmoking: PropTypes.func.isRequired,
    setSpouseSmoking: PropTypes.func.isRequired,
    postState: PropTypes.func.isRequired,
  };

  static productCode = 'CI';

  handleChange = (event) => {
    event.stopPropagation();
    if (event.target.checked) {
      this.addCIProducts();
    } else {
      this.removeCIProducts();
    }
    this.props.postState();
  };

  handleSelectAmount = (event) => {
    event.stopPropagation();
    this.updateCICompensations(event.target.value);
    this.props.postState();
  };

  handleSmokingChanged = (checked, personType) => {
    const { setCustomerSmoking, setSpouseSmoking, postState } = this.props;
    if (personType === 'customer') {
      setCustomerSmoking(checked);
    }
    if (personType === 'spouse') {
      setSpouseSmoking(checked);
    }
    postState();
  };

  addCIProducts = () => {
    const { addCustomerCIProduct, addSpouseCIProduct } = this.props;
    if (this.isForCustomer()) {
      addCustomerCIProduct();
    }
    if (this.isForSpouse()) {
      addSpouseCIProduct();
    }
  };

  removeCIProducts = () => {
    const { removeCustomerCIProduct, removeSpouseCIProduct } = this.props;
    const { clearCustomerOptionalQuestions, clearSpouseOptionalQuestions } = this.props;
    if (this.isForCustomer()) {
      removeCustomerCIProduct();
      clearCustomerOptionalQuestions();
    }
    if (this.isForSpouse()) {
      removeSpouseCIProduct();
      clearSpouseOptionalQuestions();
    }
  };

  updateCICompensations = (compensation) => {
    const {
      spouseDifferent, identifier, updateCustomerCICompensation, updateSpouseCICompensation,
    } = this.props;
    if (!spouseDifferent || identifier !== 'spouse') {
      updateCustomerCICompensation(compensation);
    }
    if (!spouseDifferent || identifier === 'spouse') {
      updateSpouseCICompensation(compensation);
    }
  };

  isForCustomer = () => !!(this.props.hasCustomer && this.props.identifier !== 'spouse');

  isForSpouse = () => {
    const p = this.props;
    const spouseSameAsCustomer = p.hasSpouse && !p.spouseDifferent;
    const spouseSpecific = p.hasSpouse && p.spouseDifferent && p.identifier === 'spouse';
    return !!(spouseSameAsCustomer || spouseSpecific);
  };

  getCriticalIllnessLink = () => (
    <div key="criticalIllnessLink">
      <div className="productCheckbox">{/* just a placeholder for styling purposes */}</div>
      <div className="ciProductLink">
        <a href="/vakava-sairaus" target="_blank">
          {i18n.t('ciProductLinkDescription')}
        </a>
      </div>
    </div>
  );

  smokeQuestion = (personType, value, textKey) => (
    <div className="yesNoSmokeWrapper" key={`ciYesNoQuestion${personType}`}>
      <div className="productCheckbox">{/* just a placeholder for styling purposes */}</div>
      <YesNoQuestion marker="test" value={value} onChange={(checked) => this.handleSmokingChanged(checked, personType)}>
        {i18n.raw(textKey)}
      </YesNoQuestion>
    </div>
  );

  getSmokeQuestions = () => {
    const { customerSmoking, spouseSmoking, spouseDifferent } = this.props;
    const smokingQuestions = [];
    const customerSmokeQuestion = this.smokeQuestion('customer', customerSmoking, 'smokeQuestionKey');
    const spouseSmokeQuestion = this.smokeQuestion('spouse', spouseSmoking, 'smokeQuestionSpouseKey');

    if (this.isForCustomer() && this.isForSpouse() && !spouseDifferent) {
      smokingQuestions.push(
        <div key="smokingQuestions">
          {customerSmokeQuestion}
          {spouseSmokeQuestion}
        </div>,
      );
    } else if (this.isForCustomer()) {
      smokingQuestions.push(customerSmokeQuestion);
    } else if (this.isForSpouse()) {
      if (hasCustomer) {
        smokingQuestions.push(spouseSmokeQuestion);
      } else {
        // ask from spouse smoking using primary (direct) text
        const spouseSmokeQuestionWithPrimaryText = this.smokeQuestion('spouse', spouseSmoking, 'smokeQuestionKey');
        smokingQuestions.push(spouseSmokeQuestionWithPrimaryText);
      }
    }
    return smokingQuestions;
  };

  getPricePerMonth = () => {
    const p = this.props;
    if (p.spouseDifferent) {
      return this.isForCustomer() ? p.customerCiPrice : p.spouseCiPrice;
    }
    return p.totalAdultCiPrice;
  };

  render() {
    const {
      customerCISelected,
      spouseCISelected,
      customerCICompensation,
      spouseCICompensation,
      compensations,
      context,
    } = this.props;
    const isSelected = customerCISelected || spouseCISelected;
    const compensation = this.isForCustomer() ? customerCICompensation : spouseCICompensation;
    const infoKey = `${CIProduct.productCode}InfoKey`;

    const sumRows = <CompensationRows compensations={compensations} />;
    const ciSumSelector = (
      <span className="sumSelector">
        <span id="ciAmountSelectorLabel">{i18n.t('productSelectorLifeAmount')}</span>
        <select
          className="form-control"
          aria-labelledby="ciAmountSelectorLabel"
          onChange={this.handleSelectAmount}
          disabled={!isSelected}
          value={compensation}
        >
          {sumRows}
        </select>
      </span>
    );

    const additionalContent = [];
    additionalContent.push(this.getSmokeQuestions());
    if (context !== 'calculator') {
      additionalContent.push(this.getCriticalIllnessLink());
    }
    const ciPerMonth = this.getPricePerMonth();

    return (
      <div>
        <h4 className="additionalTitle" key="additionalCITitle">
          {i18n.t('productSelectorAdditionalCIProductTitle')}
        </h4>
        <ProductRow
          key="CI"
          label={i18n.t('productSelectorCITitle')}
          onChange={this.handleChange}
          infoKey={infoKey}
          productCode={CIProduct.productCode}
          price={ciPerMonth}
          selected={isSelected}
          analyticsIdentifier={this.props.analyticsIdentifier}
          additionalContent={additionalContent}
          productDescription={ciSumSelector}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  memberOfOrganization: isMemberOfOrganization(state),
  customerSmoking: isCustomerSmoking(state),
  spouseSmoking: isSpouseSmoking(state),
  compensations: getCICompensations(state),
  customerCICompensation: getCustomerCICompensation(state),
  spouseCICompensation: getSpouseCICompensation(state),
  customerCISelected: isCustomerCISelected(state),
  spouseCISelected: isSpouseCISelected(state),
  spouseDifferent: isSpouseDifferent(state),
  totalAdultCiPrice: getNormalTotalAdultCiPerMonth(state),
  customerCiPrice: getNormalCustomerCiPerMonth(state),
  spouseCiPrice: getNormalSpouseCiPerMonth(state),
  hasCustomer: hasCustomer(state),
  hasSpouse: hasSpouse(state),
});

const mapDispatchToProps = (dispatch) => ({
  addCustomerCIProduct: () => {
    dispatch(addCustomerProduct(CIProduct.productCode));
  },
  addSpouseCIProduct: () => {
    dispatch(addSpouseProduct(CIProduct.productCode));
  },
  removeCustomerCIProduct: () => {
    dispatch(removeCustomerProduct(CIProduct.productCode));
  },
  removeSpouseCIProduct: () => {
    dispatch(removeSpouseProduct(CIProduct.productCode));
  },
  updateCustomerCICompensation: (compensation) => {
    dispatch(setCustomerCICompensation(compensation));
  },
  updateSpouseCICompensation: (compensation) => {
    dispatch(setSpouseCICompensation(compensation));
  },
  setCustomerSmoking: (smoking) => {
    dispatch(setCustomerSmoking(smoking));
  },
  setSpouseSmoking: (smoking) => {
    dispatch(setSpouseSmoking(smoking));
  },
  clearCustomerOptionalQuestions: () => {
    dispatch(setCustomerOptionalQuestions([]));
  },
  clearSpouseOptionalQuestions: () => {
    dispatch(setSpouseOptionalQuestions([]));
  },
  postState: () => {
    purchaseApi.postState(dispatch);
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CIProduct);
