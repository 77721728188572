// Setup i18next

import i18n from 'i18next-client';

import { appendMainWindowUrlParams } from './util/httpUtil';
import { md2html } from './util/markdown';

i18n.addPostProcessor('keyReturningProcessor', (value /* , key, options */) => value);

i18n.addPostProcessor('md2htmlProcessor', md2html);

export const i18n_options = {
  resGetPath: appendMainWindowUrlParams('locales/resources.json?locale=__lng__'),
  dynamicLoad: false,
  lng: 'fi',
  load: 'current',
  fallbackOnNull: false,
  fallbackLng: false,
  postProcess: 'md2htmlProcessor',
};

export const beneficiaries = [
  'NEXT_OF_KIN',
  'SPOUSE_AND_CHILDREN',
  'CHILDREN',
  'SPOUSE',
  'COHABITANT',
  'PARENTS',
  'NAMED',
];

export const approvalQuestionsPrimus = [
  { key: 'approvalConfirmQuestion' },
  { key: 'approvalFamiliarWithInsuranceGuideQuestion-Primus' },
];
export const approvalQuestionsPrimusCi = [
  { key: 'approvalConfirmQuestion' },
  { key: 'approvalFamiliarWithInsuranceGuideQuestion-PrimusCi' },
];
export const approvalQuestionsLifeMl = [
  { key: 'approvalConfirmQuestion' },
  { key: 'approvalFamiliarWithInsuranceGuideQuestion-LifeMl' },
];
export const approvalQuestionsLifeMlCi = [
  { key: 'approvalConfirmQuestion' },
  { key: 'approvalFamiliarWithInsuranceGuideQuestion-LifeMlCi' },
];
export const approvalQuestionsCi = [
  { key: 'approvalConfirmQuestion' },
  { key: 'ciApprovalSicknessQuestion' },
];

export const ciApprovalQuestions = [
  { key: 'automaticProcessingQuestionML' },
  { key: 'ciApprovalConfirmQuestion' },
  { key: 'ciApprovalFamiliarWithInsuranceGuideQuestion' },
  { key: 'ciApprovalSicknessQuestion' },
]; // CI after Primus

export const automaticProcessingQuestionOrgCI = [
  { key: 'automaticProcessingQuestionOrgCI' },
];

export const automaticProcessingQuestionOrgNoCI = [
  { key: 'automaticProcessingQuestionOrgNoCI' },
];

export const automaticProcessingQuestionML = [
  { key: 'automaticProcessingQuestionML' },
];

export default {
  i18n: i18n_options,
  beneficiaries,
  approvalQuestionsPrimus,
  approvalQuestionsPrimusCi,
  approvalQuestionsLifeMl,
  approvalQuestionsLifeMlCi,
  approvalQuestionsCi,
  ciApprovalQuestions,
  automaticProcessingQuestionOrgCI,
  automaticProcessingQuestionOrgNoCI,
  automaticProcessingQuestionML,
};
