import * as React from 'react';
import * as PropTypes from 'prop-types';

import formatNumber from '../util/formatNumber';
import { zeroPad } from '../util/utils';

export default function PriceDisplay(props) {
  const content = [];

  const { price, priceDisplayUnit, className } = props;
  let { decimals } = props;
  if (price || price === 0) {
    const euroPrice = Math.floor(price);
    if (decimals) {
      decimals = (price - Math.floor(price)) * 100;

      content.push(
        <span key={`mainUnit_${euroPrice}`} className="mainUnit">
          {formatNumber(euroPrice, false)}
        </span>,
      );
      content.push(
        <span key={`subUnit_${decimals}`} className="subUnit">
          {`,${zeroPad(formatNumber(decimals, false), 2)}`}
        </span>,
      );
    } else {
      content.push(
        <span key={`mainUnit_${euroPrice}`} className="mainUnit">
          {formatNumber(euroPrice, false)}
        </span>,
      );
    }
    content.push(
      <span key={`displayUnit_${priceDisplayUnit}`} className="displayUnit">
        {priceDisplayUnit}
      </span>,
    );
  }
  return <div className={className || 'price'}>{content}</div>;
}

PriceDisplay.propTypes = {
  price: PropTypes.number,
  decimals: PropTypes.bool,
  className: PropTypes.string,
  priceDisplayUnit: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

PriceDisplay.displayName = 'PriceDisplay';
