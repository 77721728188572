import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import i18n from 'i18next-client';
import get from 'lodash/get';
import find from 'lodash/find';

import Picker from '../calculator/picker';
import SearchSelector from '../calculator/searchSelector';

import { setCustomerMunicipality, setSpouseMunicipality } from '../redux/actions/purchaseActions';
import { getPostOffices } from '../redux/reducers/dataReducer';

class PostOfficePicker extends React.Component {
  static displayName = 'PostOfficePicker';

  static propTypes = {
    path: PropTypes.string.isRequired,
    postOffices: PropTypes.array.isRequired,
    open: PropTypes.bool,
    onVisibilityChange: PropTypes.func.isRequired,
    setCustomerMunicipality: PropTypes.func.isRequired,
    setSpouseMunicipality: PropTypes.func.isRequired,
  };

  handleChange = (value) => {
    if (this.props.path == 'customer.address.city') {
      this.props.setCustomerMunicipality(value);
    } else {
      this.props.setSpouseMunicipality(value);
    }
    this.picker.doClose();
  };

  onVisibilityChange = (visible) => {
    if (visible) {
      this.selector.onShow();
    }
    if (this.props.onVisibilityChange) {
      this.props.onVisibilityChange(visible);
    }
  };

  render() {
    const p = this.props;
    const value = get(p.root, p.path);
    const { postOffices } = this.props;

    let text = '';

    if (value && postOffices) {
      const postOffice = find(postOffices, (postOffice) => postOffice.name === value);

      if (postOffice) {
        text = postOffice.name;
      }
    }

    const placeholder = i18n.t('postOfficeSelectorPlaceholder');

    return (
      <Picker
        value={text}
        className="attachLeft"
        role="button"
        ref={(self) => (this.picker = self)}
        onVisibilityChange={this.onVisibilityChange}
        open={this.props.open}
      >
        <SearchSelector
          ref={(self) => (this.selector = self)}
          selection={value}
          dataSrc={postOffices}
          idPropName="name"
          displayPropName="name"
          placeholder={placeholder}
          onChange={this.handleChange}
          searchFilterLabel={placeholder}
        />
      </Picker>
    );
  }
}

const mapStateToProps = (state) => ({
  postOffices: getPostOffices(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCustomerMunicipality: (m) => {
    dispatch(setCustomerMunicipality(m));
  },
  setSpouseMunicipality: (m) => {
    dispatch(setSpouseMunicipality(m));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PostOfficePicker);
