import * as actionTypes from '../actionTypes';

const initialState = ['LIFE', 'DAB', 'DAE', 'DAI', 'DAL', 'CI'];

const customerAvailableProducts = (state = initialState, action) => {
  switch (action && action.type) {
    case actionTypes.SET_CUSTOMER_AVAILABLE_PRODUCTS: {
      const { customerAvailableProducts } = action;
      return [...customerAvailableProducts];
    }
    default: {
      return state;
    }
  }
};

export default customerAvailableProducts;

// Selectors

export const getCustomerAvailableProducts = (state) => state.customerAvailableProducts || [];

export const isProductAvailableForCustomer = (state, product) => (state.customerAvailableProducts || []).includes(product);
