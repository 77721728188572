import { hasProduct } from './productModel';
import { createQuestionsWithAnswers, questionToObject } from './questionModel';
import { getAutomaticProcessingQuestions } from './automaticProcessingQuestionModel';

export const getHealthQuestions = (products, memberOfOrganization, oldQuestions, optionalQuestions, hasChildren) => {
  const hasOptionalQuestions = optionalQuestions && optionalQuestions.length > 0;
  const questionLocalizationKeys = determineQuestions(products, memberOfOrganization, hasOptionalQuestions, hasChildren);
  const healthQuestionsAndAnswers = createQuestionsWithAnswers(questionLocalizationKeys, oldQuestions || []);
  return healthQuestionsAndAnswers;
};

// health questions where 'yes' answer is not mandatory. Currently for CI product only.
export const getOptionalHealthQuestions = (products, oldQuestions) => {
  if (!products || !products.includes('CI')) {
    return [];
  }

  // if has old questions with possible 'yes' or 'no' answers -> keep them
  if (oldQuestions && oldQuestions.length > 0) {
    return oldQuestions;
  }

  return initialOptionalQuestions();
};

const initialOptionalQuestions = () => {
  const questions = [{
    key: 'ciApprovalConfirmQuestionPurchasseFlow',
  },
  {
    key: 'ciApprovalNoDoctorThreeMonthsPurchaseFlow',
  }];

  return questions.map((question) => questionToObject(question.key, question.infoKey));
};

export const unansweredQuestionCount = (questions) => {
  questions.reduce((acc, q) => acc + (q.answer ? 0 : 1), 0);
};

export const clearAnswers = (questions) => {
  const clearedQuestions = questions.map((q) => ({ ...q, ...{ answer: undefined } }));
  return clearedQuestions;
};

const determineQuestions = (products, memberOfOrganization, hasOptionalQuestions) => {
  const questions = [];

  questions.push(...getAutomaticProcessingQuestions(products, memberOfOrganization));

  const genericQuestions = [{
    key: 'livesInFinlandAndHasKelaCardHealthQuestion',
    infoKey: 'livesInFinlandAndHasKelaCardHealthQuestionInfo',
  }];

  if ((products && products.length > 0) || hasOptionalQuestions) {
    genericQuestions.forEach((q) => {
      questions.push(q);
    });
  }

  const hasLifeOrCi = hasProduct(products, 'LIFE') || hasProduct(products, 'CI');
  if (hasLifeOrCi || hasOptionalQuestions) {
    questions.push({ key: 'twoLastYearsInFinlandHealthQuestion', infoKey: 'twoLastYearsInFinlandHealthQuestionInfo' });
    if (memberOfOrganization) {
      questions.push({ key: 'customerLifeHealthQuestion', infoKey: 'customerLifeHealthQuestionInfo' });
    } else {
      questions.push({ key: 'customerLifeMLHealthQuestion', infoKey: 'customerLifeMLHealthQuestionInfo' });
    }
    questions.push({ key: 'customerHealthQuestion', infoKey: 'customerHealthQuestionInfo' });
  }
  return questions;
};
