import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import i18n from '../localization/i18n-wrapper';

import HealthYesNoQuestion from './healthYesNoQuestion';
import { getPurchase, getCustomerQuestions, getOptionalCustomerQuestions } from '../redux/reducers/purchaseReducer';
import OptionalHealthYesNoQuestion from './optionalHealthYesNoQuestion';

CustomerAndChildQuestions.displayName = 'CustomerAndChildQuestions';

CustomerAndChildQuestions.propTypes = {
  purchase: PropTypes.object.isRequired,
  customerQuestions: PropTypes.array.isRequired,
  optionalCustomerQuestions: PropTypes.array.isRequired,
};

function CustomerAndChildQuestions(props) {
  const content = [];

  const { customerQuestions, optionalCustomerQuestions } = props;
  const customerQuestionCount = customerQuestions.length;

  if (customerQuestionCount) {
    content.push(<h2 key="customerHeader">{i18n.t('healthCheckCustomerHeader')}</h2>);

    customerQuestions.forEach((question, index) => {
      const path = ['customerQuestions', index];
      content.push(<HealthYesNoQuestion question={question} path={path} />);
    });
  }

  optionalCustomerQuestions.forEach((question, index) => {
    const path = ['optionalCustomerQuestions', index];
    content.push(
      <OptionalHealthYesNoQuestion question={question} path={path} personType="customer" />,
    );
  });

  return !content.length ? null : <div className="purchaseSection">{content}</div>;
}

const mapStateToProps = (state) => ({
  purchase: getPurchase(state),
  customerQuestions: getCustomerQuestions(state),
  optionalCustomerQuestions: getOptionalCustomerQuestions(state),
});

export default connect(mapStateToProps)(CustomerAndChildQuestions);
