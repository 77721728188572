import * as React from 'react';
import * as PropTypes from 'prop-types';
import * as classNames from 'classnames';

import i18n from 'i18next-client';
import purchaseApi from '../api/purchaseApi';

export default class PurchaseButton extends React.Component {
  static displayName = 'PurchaseButton';

  static contextTypes = {
    store: PropTypes.object,
  };

  doSubmit = () => {
    if (!purchaseApiMaintenance) {
      const { store } = this.context;
      purchaseApi.startPurchase(store);
    }
  };

  render() {
    const buttonClasses = {
      btn: true,
      'btn-active': !purchaseApiMaintenance,
    };

    let info;

    if (purchaseApiMaintenance) {
      info = <div className="error">{i18n.t('purchaseApiMaintenance')}</div>;
    }

    return (
      <div className="purchase-button-container">
        <button id="purchaseButton" type="button" onClick={this.doSubmit} className={classNames(buttonClasses)}>
          {i18n.t('startPurchaseFlow')}
        </button>
        {info}
      </div>
    );
  }
}
