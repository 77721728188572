import * as React from 'react';

import FormField from './formField';

export default class LoneAndIncome extends React.Component {
  static displayName = 'LoneAndIncome';

  render() {
    return (
      <div>
        {[
          <FormField key="loan" fieldName="loan" inputSymbol="€" />,
          <FormField key="income" fieldName="income" inputSymbol="€" />,
        ]}
      </div>
    );
  }
}
