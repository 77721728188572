import * as React from 'react';
import * as PropTypes from 'prop-types';

class CompensationGauge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focus: false,
      mouseDown: false,
    };
  }

  onInputFocus() {
    const { mouseDown } = this.state;
    if (!mouseDown) {
      this.setState({
        focus: true,
      });
    }
  }

  onInputBlur() {
    this.setState({
      focus: false,
    });
  }

  onMouseDown() {
    this.setState({
      mouseDown: true,
    });
  }

  onMouseUp() {
    this.setState({
      mouseDown: false,
    });
  }

  render() {
    const {
      label, maxCompensation, defaultIndex, disabled, onChange, selectedCompensation,
      id,
    } = this.props;
    const { focus } = this.state;
    const focusClass = (focus) ? 'focused' : '';
    return (
      <section className={`compensationGauge ${focusClass}`}>
        <header className="gaugeHeader">
          <div id={id} className="safeLabel">{label}</div>
          <div className="selectedSafety">
            {selectedCompensation}
            {' €'}
          </div>
        </header>
        <main className="rangeWrapper">
          <input
            className="gauge"
            type="range"
            min="0"
            max={maxCompensation}
            step="1"
            onChange={onChange}
            value={defaultIndex}
            disabled={disabled}
            aria-labelledby={id}
            onFocus={() => this.onInputFocus()}
            onBlur={() => this.onInputBlur()}
            onMouseDown={() => this.onMouseDown()}
            onMouseUp={() => this.onMouseUp()}
          />
        </main>
      </section>
    );
  }
}

CompensationGauge.propTypes = {
  label: PropTypes.object.isRequired,
  maxCompensation: PropTypes.number.isRequired,
  selectedCompensation: PropTypes.string.isRequired,
  defaultIndex: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default CompensationGauge;
