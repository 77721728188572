import calculator from './calculatorLoader';
import purchase from './purchaseLoader';
import ci from './ciLoader';
import life from './lifeLoader';

require('./other'); // accordion open/close handler

require('../less/main.less');

window.hvk = {
  calculator,
  purchase,
  ci,
  life,
};

require('./translationKeysWidget');

/* if (!isProductionEnv) {
  var browserInfo = document.createElement('div');

  browserInfo.className = 'browserInfo';
  browserInfo.innerHTML = 'documentMode: ' + document.documentMode + ', compatMode: ' + document.compatMode;

  document.body.appendChild(browserInfo);
} */
