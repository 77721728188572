import * as React from 'react';
import * as ReactDOM from 'react-dom';

import localizationLoader from './localization/loader';

export default class TranslationKeysWidget extends React.Component {
  static displayName = 'TranslationKeysWidget';

  state = {
    showingKeys: false,
  };

  handleClick = () => {
    this.setState({
      showingKeys: !this.state.showingKeys,
    });

    if (this.state.showingKeys) {
      localizationLoader.initI18N();
    } else {
      // disable
      localizationLoader.initI18N({});
    }
  };

  render() {
    const string = this.state.showingKeys ? 'Piilota käännösavaimet' : 'Näytä käännösavaimet';

    return (
      <div>
        <button onClick={this.handleClick}>{string}</button>
      </div>
    );
  }
}

const elementById = document.getElementById('translationKeysWidget');

if (elementById) {
  ReactDOM.render(<TranslationKeysWidget />, elementById);
}
