import get from 'lodash/get';
import merge from 'lodash/merge';
import PurchaseModel from '../../models/purchaseModel';
import * as actionTypes from '../actionTypes';

const initialState = new PurchaseModel({
  // TODO: refactor this 'internal' to something else
  internal: {
    currentPage: 'product',
    priceVisible: true,
  },

  customer: {},
  spouse: {},

  children: [],

  spousePresent: true,

  customerQuestions: [],
  spouseQuestions: [],
  optionalCustomerQuestions: [],
  optionalSpouseQuestions: [],
  approvalQuestions: [],
  spouseDifferent: false,
  ciApprovalQuestions: [],
  lang: 'fi',
  campaignCode: undefined,
});

const purchase = (state = initialState, action) => {
  switch (action && action.type) {
    case actionTypes.SET_LANG: {
      const { lang } = action;
      return {
        ...state,
        lang: lang ? state.lang : 'fi',
      };
    }
    case actionTypes.SET_CUSTOMER_AGE: {
      const { customerAge } = action;
      return {
        ...state,
        customer: customerAge ? state.customer : undefined,
      };
    }
    case actionTypes.SET_SPOUSE_AGE: {
      const { spouseAge } = action;
      return {
        ...state,
        spouse: spouseAge ? state.spouse : undefined,
        spouseVastusOk: false,
      };
    }
    case actionTypes.SET_SPOUSE_DIFFERENT: {
      const { spouseDifferent } = action;
      return {
        ...state,
        spouseDifferent,
      };
    }
    case actionTypes.SET_MEMBER_OF_ORGANIZATION: {
      const { memberOfOrganization } = action;
      return {
        ...state,
        memberOfOrganization,
      };
    }
    // TODO: test that this works.  Needs questions initializations to see if it works.
    case actionTypes.SET_YESNOQUESTION_ANSWER: {
      const { category, index, answer } = action;
      const [...categoryQuestions] = state[category];
      categoryQuestions[index].answer = answer;
      return {
        ...state,
        [category]: categoryQuestions,
      };
    }
    case actionTypes.SET_CHECKBOX_ANSWER: {
      const { category, index, answer } = action;
      const [...categoryQuestions] = state[category];
      categoryQuestions[index].answer = answer;
      return {
        ...state,
        [category]: categoryQuestions,
      };
    }
    case actionTypes.SET_CUSTOMER_BENEFICIARY: {
      const { beneficiary } = action;
      return {
        ...state,
        customerBeneficiary: beneficiary,
      };
    }
    case actionTypes.SET_SPOUSE_BENEFICIARY: {
      const { beneficiary } = action;
      return {
        ...state,
        spouseBeneficiary: beneficiary,
      };
    }
    case actionTypes.SET_SPOUSE_PRESENT: {
      const { spousePresent } = action;
      const resetSpouseVastus = spousePresent ? {} : { spouseVastusOk: false };
      return {
        ...state,
        spousePresent,
        ...resetSpouseVastus,
      };
    }
    case actionTypes.SET_CUSTOMER_CI_COMPENSATION: {
      const { customerCICompensation } = action;
      const ciPriceVariables = {
        ...state.ciPriceVariables,
        insuranceSum: customerCICompensation, // TODO: need to separate customer and spouse compensations
      };
      return {
        ...state,
        ciPriceVariables,
      };
    }
    case actionTypes.SET_SPOUSE_CI_COMPENSATION: {
      const { spouseCICompensation } = action;
      const ciPriceVariables = {
        ...state.ciPriceVariables,
        insuranceSum: spouseCICompensation, // TODO: need to separate customer and spouse compensations
      };
      return {
        ...state,
        ciPriceVariables,
      };
    }
    case actionTypes.SET_CUSTOMER_SMOKING: {
      const { smoking } = action;
      const ciPriceVariables = {
        ...state.ciPriceVariables,
        smoke: smoking, // TODO: need to separate customer and spouse smoking
      };
      return {
        ...state,
        ciPriceVariables,
      };
    }
    case actionTypes.SET_SPOUSE_SMOKING: {
      const { smoking } = action;
      const ciPriceVariables = {
        ...state.ciPriceVariables,
        smoke: smoking, // TODO: need to separate customer and spouse smoking
      };
      return {
        ...state,
        ciPriceVariables,
      };
    }
    case actionTypes.SET_DATA_FIELD: {
      const { type, ...payload } = action; // eslint-disable-line no-unused-vars
      let { spouseVastusOk } = state;
      const spouseDataUpdate = payload.spouse !== undefined;
      if (spouseDataUpdate) {
        spouseVastusOk = false;
      }
      const newState = merge({}, state, payload);
      return {
        ...newState,
      };
    }
    case actionTypes.SET_SPOUSE_VASTUS_STATUS: {
      const { spouseVastusOk } = action;
      return {
        ...state,
        spouseVastusOk,
      };
    }
    case actionTypes.SET_CUSTOMER_MUNICIPALITY: {
      const { municipality } = action;
      const newMunicipality = {
        customer: {
          address: {
            city: municipality,
          },
        },
      };
      const newState = merge({}, state, newMunicipality);
      return {
        ...newState,
      };
    }
    case actionTypes.SET_SPOUSE_MUNICIPALITY: {
      const { municipality } = action;
      const newMunicipality = {
        ...state,
        spouse: {
          address: {
            city: municipality,
          },
        },
      };
      const newState = merge({}, state, newMunicipality);
      return {
        ...newState,
      };
    }
    case actionTypes.SET_HEALTH_QUESTIONS: {
      const {
        customerQuestions, spouseQuestions, optionalCustomerQuestions, optionalSpouseQuestions,
      } = action;
      return {
        ...state,
        customerQuestions,
        spouseQuestions,
        optionalCustomerQuestions,
        optionalSpouseQuestions,
      };
    }
    case actionTypes.SET_SPOUSE_HEALTH_QUESTIONS: {
      const { spouseQuestions } = action;
      return {
        ...state,
        spouseQuestions,
      };
    }
    case actionTypes.SET_CUSTOMER_OPTIONAL_HEALTH_QUESTIONS: {
      const { optionalCustomerQuestions } = action;
      return {
        ...state,
        optionalCustomerQuestions,
      };
    }
    case actionTypes.SET_SPOUSE_OPTIONAL_HEALTH_QUESTIONS: {
      const { optionalSpouseQuestions } = action;
      return {
        ...state,
        optionalSpouseQuestions,
      };
    }
    case actionTypes.SET_APPROVAL_QUESTIONS: {
      const { approvalQuestions } = action;
      return {
        ...state,
        approvalQuestions,
      };
    }
    case actionTypes.SET_CURRENT_PAGE_AND_PRICE_VISIBILITY: {
      const { currentPage, priceVisible } = action;
      return {
        ...state,
        internal: {
          currentPage,
          priceVisible,
        },
      };
    }
    case actionTypes.SET_CI_APPROVAL_QUESTIONS: {
      const { ciApprovalQuestions } = action;
      return {
        ...state,
        ciApprovalQuestions,
      };
    }
    case actionTypes.SET_CHILDREN: {
      const { resetDetails } = action;
      if (resetDetails) {
        return {
          ...state,
          children: [],
        };
      }
      return state;
    }
    case actionTypes.SET_CAMPAIGN_CODE: {
      const { campaignCode } = action;
      return {
        ...state,
        campaignCode,
      };
    }
    case actionTypes.SET_CAMPAIGN_TYPE: {
      const { campaignType } = action;
      return {
        ...state,
        campaignType,
      };
    }

    default: {
      return state;
    }
  }
};

export default purchase;

// Selectors

export const getCustomer = (state) => get(state, 'purchase.customer', {});
export const getSpouse = (state) => get(state, 'purchase.spouse', {});
export const getCustomerAddress = (state) => get(state, 'purchase.customer.address', {});
export const getSpouseAddress = (state) => get(state, 'purchase.spouse.address', {});
export const getCustomerSocialSecurityNumber = (state) => get(state, 'purchase.customer.socialSecurityNumber');
export const getSpouseSocialSecurityNumber = (state) => get(state, 'purchase.spouse.socialSecurityNumber');
export const getCustomerBeneficiary = (state) => state.purchase.customerBeneficiary;
export const getSpouseBeneficiary = (state) => state.purchase.spouseBeneficiary;
export const getCustomerBeneficiaryDetails = (state) => state.purchase.customerBeneficiaryDetails;
export const getSpouseBeneficiaryDetails = (state) => state.purchase.spouseBeneficiaryDetails;
export const isSpousePresent = (state) => state.purchase.spousePresent;
export const isSpouseDifferent = (state) => state.purchase.spouseDifferent;
export const isSpouseVastusOk = (state) => state.purchase.spouseVastusOk;
export const getChildren = (state) => state.purchase.children || [];
export const getCustomerQuestions = (state) => state.purchase.customerQuestions || [];
export const getSpouseQuestions = (state) => state.purchase.spouseQuestions || [];
export const getOptionalCustomerQuestions = (state) => state.purchase.optionalCustomerQuestions || [];
export const getOptionalSpouseQuestions = (state) => state.purchase.optionalSpouseQuestions || [];
export const getApprovalQuestions = (state) => state.purchase.approvalQuestions;
export const getApplicationState = (state) => state.purchase.applicationState;
export const getCurrentPage = (state) => state.purchase.internal.currentPage;
export const getPriceVisible = (state) => state.purchase.internal.priceVisible;
export const getPurchase = (state) => state.purchase;
export const getCIApprovalQuestions = (state) => state.purchase.ciApprovalQuestions;
export const getCampaignCode = (state) => state.purchase.campaignCode;
