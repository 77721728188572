import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import FormField from './formField';
import LanguageField from './languageField';
import BeneficiaryField from './beneficiaryField';
import OrgField from './orgField';
import PostOfficeField from './postOfficeField';
import i18n from '../localization/i18n-wrapper';

import { setDataField } from '../redux/actions/purchaseActions';
import { getCustomerAddress, getPurchase } from '../redux/reducers/purchaseReducer';
import {
  customerHasProduct, getChildCount, getOrganization, isMemberOfOrganization,
} from '../redux/reducers/selectedReducer';
import {
  getEffectiveOrganization,
} from '../models/organizationModel';
import { getOrganizations } from '../redux/reducers/dataReducer';

import Info from '../common/info';
import I18n from '../localization/I18n';

class PersonDetails extends React.Component {
  copyAddress = () => {
    const change = {
      spouse: {
        address: this.props.customerAddress,
      },
    };
    this.props.setDataField(change);
  };

  prefixPath = (property) => `${this.props.path}.${property}`;

  prefixId = (id) => `${this.props.idPrefix}.${id}`;

  render() {
    const p = this.props;

    const { root } = p;
    const { errors } = p;

    const { beneficiaryPath } = this.props;
    const { beneficiaryDetailsPath } = this.props;

    const fields = [
      formField(this.prefixId('Firstname'), i18n.raw('personFirstname'), this.prefixPath('firstName')),
      formField(this.prefixId('Surname'), i18n.raw('personLastname'), this.prefixPath('lastName')),
      formField(this.prefixId('PersonId'), i18n.raw('personSSN'), this.prefixPath('socialSecurityNumber'), true),
    ];

    if (p.showOrganization) {
      const orgId = this.prefixId('Organization');
      const displayOrg = getEffectiveOrganization(p.organization, p.memberOfOrganization, p.organizations);

      fields.push(
        <OrgField
          key={orgId}
          id={orgId}
          label={i18n.raw('personOrganization')}
          organization={displayOrg}
          readOnly
        />,
      );
      if (p.organization && p.organization.membershipNumberMaxLength > 0) {
        fields.push(
          formFieldWithInfo(
            this.prefixId('MemberNo'),
            i18n.raw('personMemberNo'),
            this.prefixPath('membershipNumber'),
            undefined,
            'orgNumberInfoKey',
          ),
        );
      }
    }

    if (p.showAddress) {
      if (p.showCopyButton) {
        fields.push(
          <div key="copy" className="form-group">
            {' '}
            <input
              id="copyAddress"
              className="bnt btn-active"
              type="button"
              value="Tuo sama osoite"
              onClick={this.copyAddress}
            />
            {' '}
          </div>,
        );
      }

      const postOfficeId = this.prefixId('City');
      fields.push(
        formField(this.prefixId('StreetAddress'), i18n.raw('personAddress'), this.prefixPath('address.streetAddress')),
      );
      fields.push(
        formField(this.prefixId('PostCode'), i18n.raw('personPostCode'), this.prefixPath('address.postCode')),
      );
      fields.push(
        <PostOfficeField
          key={postOfficeId}
          id={postOfficeId}
          label={i18n.raw('personPostOffice')}
          path={this.prefixPath('address.city')}
          root={root}
          errors={errors}
          readOnly={p.readOnly}
        />,
      );
    }

    if (p.showPhoneAndEmail) {
      fields.push(formField(this.prefixId('Phone'), i18n.raw('personPhone'), this.prefixPath('phoneNumber')));
      fields.push(formField(this.prefixId('Email'), i18n.raw('personEmail'), this.prefixPath('email')));
    }

    if (p.showLanguage) {
      const langId = this.prefixId('Language');
      fields.push(
        <LanguageField
          key={langId}
          label={i18n.raw('personLanguage')}
          path={this.prefixPath('languageCode')}
          root={root}
          id={langId}
          readOnly={p.readOnly}
        />,
      );
    }

    if (p.showBeneficiary) {
      const benId = this.prefixId('Beneficiary');
      fields.push(
        <BeneficiaryField
          key={benId}
          root={root}
          errors={errors}
          label={i18n.raw('personBeneficiary')}
          path={beneficiaryPath}
          detailsPath={beneficiaryDetailsPath}
          id={benId}
          readOnly={p.readOnly}
        />,
      );
    }

    let errorContainer;

    if (p.errorText) {
      errorContainer = <p className="error">{p.errorText}</p>;
    }

    return (
      <div className="personDetails" data-marker={this.props.marker} data-marker2={this.idPrefix}>
        <div className="form-horizontal">{fields}</div>
        {errorContainer}
      </div>
    );

    function formField(id, label, path, trim) {
      return (
        <FormField
          key={id}
          id={id}
          label={label}
          path={path}
          root={root}
          trim={trim}
          errors={errors}
          readOnly={p.readOnly}
        />
      );
    }

    function formFieldWithInfo(id, label, path, trim, infoKey) {
      return (
        <FormField
          key={id}
          id={id}
          label={label}
          path={path}
          root={root}
          trim={trim}
          errors={errors}
          readOnly={p.readOnly}
          infoKey={infoKey}
        />
      );
    }
  }
}

PersonDetails.displayName = 'PersonDetails';

PersonDetails.propTypes = {
  root: PropTypes.object.isRequired,
  errors: PropTypes.object,
  errorText: PropTypes.string,

  showOrganization: PropTypes.bool,
  showLanguage: PropTypes.bool,
  showBeneficiary: PropTypes.bool,
  showAddress: PropTypes.bool,
  path: PropTypes.string.isRequired,
  beneficiaryPath: PropTypes.string,
  marker: PropTypes.string,
  beneficiaryDetailsPath: PropTypes.string,
  idPrefix: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,

  purchase: PropTypes.object.isRequired,
  customerHasLife: PropTypes.bool.isRequired,
  childCount: PropTypes.number.isRequired,
  organization: PropTypes.object,
  organizations: PropTypes.array.isRequired,
  memberOfOrganization: PropTypes.bool.isRequired,
  customerAddress: PropTypes.object.isRequired,
  setDataField: PropTypes.func.isRequired,
};

PersonDetails.defaultProps = {
  errors: {},
  errorText: undefined,
  organization: {},
  showOrganization: false,
  showLanguage: false,
  showBeneficiary: false,
  showAddress: false,
  beneficiaryPath: undefined,
  marker: undefined,
  beneficiaryDetailsPath: undefined,
  readOnly: false,
};

const mapStateToProps = (state) => ({
  purchase: getPurchase(state),
  customerHasLife: customerHasProduct(state, 'LIFE'),
  childCount: getChildCount(state),
  organization: getOrganization(state),
  organizations: getOrganizations(state),
  memberOfOrganization: isMemberOfOrganization(state),
  customerAddress: getCustomerAddress(state),
});

const mapDispatchToProps = (dispatch) => ({
  setDataField: (f) => dispatch(setDataField(f)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonDetails);
