import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import range from 'lodash/range';

import i18n from '../localization/i18n-wrapper';
import utils from '../util/utils';
import { setCustomerAge, setSpouseAge } from '../redux/actions/selectedActions';
import {
  getCustomerAge,
  getCustomerAgePrevious,
  getSpouseAge,
  getSpouseAgePrevious,
  getCustomerProducts,
  getSpouseProducts,
  defaultAge,
} from '../redux/reducers/selectedReducer';
import purchaseApi from '../api/purchaseApi';

class AgeSelector extends React.Component {
  static displayName = 'AgeSelector';

  static propTypes = {
    customerAge: PropTypes.number,
    customerAgePrevious: PropTypes.number,
    spouseAge: PropTypes.number,
    spouseAgePrevious: PropTypes.number,
    customerProducts: PropTypes.array,
    spouseProducts: PropTypes.array,
    setCustomerAge: PropTypes.func,
    setSpouseAge: PropTypes.func,
    postState: PropTypes.func.isRequired,
  };

  handleAgeChange = (event) => {
    this.props.setCustomerAge(utils.gtZeroOrNull(event.target.value));
    this.props.postState();
  };

  handleSpouseAgeChange = (event) => {
    this.props.setSpouseAge(utils.gtZeroOrNull(event.target.value));
    this.props.postState();
  };

  handleAgeCheckbox = (event) => {
    const { customerAge, customerAgePrevious, spouseProducts } = this.props;
    let ageValue;
    if (event.target.checked && !customerAge) {
      ageValue = customerAgePrevious || defaultAge;
    } else if (!event.target.checked) {
      ageValue = undefined;
    }
    this.props.setCustomerAge(ageValue);
    this.props.postState();
  };

  handleSpouseAgeCheckbox = (event) => {
    const { spouseAge, spouseAgePrevious, customerProducts } = this.props;
    let ageValue;
    if (event.target.checked && !spouseAge) {
      ageValue = spouseAgePrevious || defaultAge;
    } else if (!event.target.checked) {
      ageValue = undefined;
    }
    this.props.setSpouseAge(ageValue);
    this.props.postState();
  };

  render() {
    const { customerAge, spouseAge } = this.props;

    const ages = map(range(15, 70, 1), (age) => <option key={age}>{age}</option>);

    ages.unshift(
      <option key="0" value="0">
        {i18n.raw('calculatorAgeSelectorChooseAge')}
      </option>,
    );

    return (
      <div className="ageSelector">
        <div className="section">
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                data-analytics="customer"
                onChange={this.handleAgeCheckbox}
                checked={customerAge > 0}
              />
              {' '}
              {i18n.t('calculatorAgeSelectorMe')}
            </label>
          </div>
          <select className="form-control" id="customerAgeSelect" onChange={this.handleAgeChange} value={customerAge}>
            {ages}
          </select>
        </div>
        <div className="section">
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                data-analytics="spouse"
                onChange={this.handleSpouseAgeCheckbox}
                checked={spouseAge > 0}
              />
              {' '}
              {i18n.t('calculatorAgeSelectorSpouse')}
            </label>
          </div>
          <select className="form-control" id="spouseAgeSelect" onChange={this.handleSpouseAgeChange} value={spouseAge}>
            {ages}
          </select>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  customerAge: getCustomerAge(state),
  customerAgePrevious: getCustomerAgePrevious(state),
  spouseAge: getSpouseAge(state),
  spouseAgePrevious: getSpouseAgePrevious(state),
  customerProducts: getCustomerProducts(state),
  spouseProducts: getSpouseProducts(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCustomerAge: (age) => dispatch(setCustomerAge(age)),
  setSpouseAge: (age) => dispatch(setSpouseAge(age)),
  postState: () => purchaseApi.postState(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AgeSelector);
