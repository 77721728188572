import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import get from 'lodash/get';
import set from 'lodash/set';

import FieldBase from '../common/fieldBase';
import i18n from '../localization/i18n-wrapper';

import { setDataField } from '../redux/actions/purchaseActions';
import LanguageButton from '../common/languageButton';

class LanguageField extends React.Component {
  static displayName = 'LanguageField';

  static propTypes = {
    path: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    root: PropTypes.object.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    readOnly: PropTypes.bool,
    setDataField: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    if (this.getValue() === undefined) {
      this.setFI(); // set default language
    }
  };

  getValue = () => get(this.props.root, this.props.path);

  setValue = (value) => {
    const languageObj = set({}, this.props.path, value);
    this.props.setDataField(languageObj);
  };

  setFI = () => this.setValue('FI');

  setSE = () => this.setValue('SE');

  render() {
    const p = this.props;
    const value = this.getValue();

    const optionFI = i18n.t('languageOptionFI');
    const optionSE = i18n.t('languageOptionSE');
    const displayValue = value == 'FI' ? optionFI : optionSE;

    const content = p.readOnly ? (
      <p className="form-control-static">{displayValue}</p>
    ) : (
      <div className="languageSelection">
        <LanguageButton role="FI" selected={value === 'FI'} onClick={this.setFI} />
        <LanguageButton role="SE" selected={value === 'SE'} onClick={this.setSE} />
      </div>
    );

    return <FieldBase {...p}>{content}</FieldBase>;
  }
}

const mapDispatchToProps = (dispatch) => ({
  setDataField: (f) => dispatch(setDataField(f)),
});

export default connect(
  undefined,
  mapDispatchToProps,
)(LanguageField);
