import sanitizer from 'sanitizer';

export function md2html(text) {
  return convertMdLinks2html(escapeHTML(text));
}

function convertMdLinks2html(text) {
  const linkRegexp = /(\[([^\]]+)\]\(([^)]+)\))+/gm;
  let match = linkRegexp.exec(text);

  // XSS prevention by design. Try to come up with proper escaping.
  while (match && match.length >= 4) {
    const matchedString = match[0];
    const linkText = match[2];
    const linkAddress = match[3];
    const htmlLink = `<a href="${sanitizer.escape(linkAddress)}" target="_blank" rel="noopener">${sanitizer.escape(
      linkText,
    )}</a>`;
    text = text.replace(matchedString, htmlLink);
    match = linkRegexp.exec(text);
  }

  return text;
}

function escapeHTML(text) {
  return text
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;');
}
