import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import i18n from '../localization/i18n-wrapper';

import PersonDetails from './personDetails';
import { getPurchase } from '../redux/reducers/purchaseReducer';
import { customerHasProduct, getChildCount, getOrganization } from '../redux/reducers/selectedReducer';

class CustomerAndChildDetails extends React.Component {
  render() {
    const content = [];
    const {
      purchase, customerHasLife, childCount, organization, errors,
    } = this.props;

    content.push(<h2 key="customerDetailsHeader">{i18n.t('insuredPersonsTitle')}</h2>);
    content.push(
      <h4 className="centered" key="requiredFieldsHeader">
        {i18n.t('unansweredQuestionsBelow')}
      </h4>,
    );

    content.push(
      <PersonDetails
        key="customer"
        marker="customer"
        root={purchase}
        errors={errors}
        organization={organization}
        showOrganization
        showAddress
        showLanguage
        showBeneficiary={customerHasLife}
        idPrefix="customer"
        path="customer"
        showPhoneAndEmail
        beneficiaryPath="customerBeneficiary"
        beneficiaryDetailsPath="customerBeneficiaryDetails"
        readOnly={false}
      />,
    );

    const childErrors = this.props.childErrors || {};

    if (childCount > 0) {
      for (let i = 0, c = childCount; i < c; i++) {
        if (childCount === 1) {
          content.push(<h2 key="childDetailsHeader">{i18n.t('childDetailsTitle')}</h2>);
        } else {
          content.push(
            <h2 key={`childDetailsHeader_${i + 1}`}>
              {i + 1}
              .
              {i18n.t('childDetailsTitle')}
            </h2>,
          );
        }

        content.push(
          <PersonDetails
            marker="child"
            errorText={childErrors[i]}
            key={`child${i}`}
            root={purchase}
            errors={errors}
            idPrefix={`child${i}`}
            path={`children.${i}`}
            readOnly={false}
          />,
        );
      }
    }

    return <div className="purchaseSection">{content}</div>;
  }
}

CustomerAndChildDetails.displayName = 'CustomerAndChildDetails';

CustomerAndChildDetails.propTypes = {
  errors: PropTypes.object,
  childErrors: PropTypes.object,
  purchase: PropTypes.object.isRequired,
  organization: PropTypes.object,
  childCount: PropTypes.number.isRequired,
  customerHasLife: PropTypes.bool.isRequired,
};

CustomerAndChildDetails.defaultProps = {
  errors: {},
  childErrors: {},
  organization: {},
};

const mapStateToProps = (state) => ({
  purchase: getPurchase(state),
  customerHasLife: customerHasProduct(state, 'LIFE'),
  childCount: getChildCount(state),
  organization: getOrganization(state),
});

export default connect(mapStateToProps)(CustomerAndChildDetails);
