const normal = 'normal';
const childInsurancesFirst = 'childInsurancesFirst';
const accidentalInsurancesFirst = 'accidentalInsurancesFirst';

export class ProductLayout {
  static get normal() {
    return normal;
  }

  static get childInsurancesFirst() {
    return childInsurancesFirst;
  }

  static get accidentalInsurancesFirst() {
    return accidentalInsurancesFirst;
  }
}
