import * as React from 'react';
import * as PropTypes from 'prop-types';
import * as classNames from 'classnames';

import CustomerAgeInput from './customerAgeInput';
import i18n from '../localization/i18n-wrapper';

const AgeInput = (props) => {
  const {
    customerAge, customerMinAge, customerMaxAge, customerSelected, onCustomerAgeChange,
    spouseAge, spouseMinAge, spouseMaxAge, spouseSelected, onSpouseAgeChange,
    lang, combined,
  } = props;

  const uiLang = lang === 'sv' ? '-sv' : '';

  const customerLabel = i18n.t(`embeddedCustomerAge${uiLang}`);
  const spouseLabel = i18n.t(`embeddedSpouseAge${uiLang}`);

  const customerHeadCount = combined ? 'double' : 'single';
  const spouseHeadCount = combined ? 'none' : 'single';

  const containerClassNames = classNames({
    ageInputContainer: true,
    combined,
  });

  return (
    <section className={containerClassNames}>
      <CustomerAgeInput
        id="customerAgeInput"
        label={customerLabel}
        minAge={customerMinAge}
        maxAge={customerMaxAge}
        age={customerAge}
        selected={customerSelected}
        onChange={onCustomerAgeChange}
        headCount={customerHeadCount}
      />
      <CustomerAgeInput
        id="spouseAgeInput"
        label={spouseLabel}
        minAge={spouseMinAge}
        maxAge={spouseMaxAge}
        age={spouseAge}
        selected={spouseSelected}
        onChange={onSpouseAgeChange}
        headCount={spouseHeadCount}
      />
    </section>
  );
};

AgeInput.propTypes = {
  customerAge: PropTypes.number,
  spouseAge: PropTypes.number,
  customerSelected: PropTypes.bool.isRequired,
  spouseSelected: PropTypes.bool.isRequired,
  onCustomerAgeChange: PropTypes.func.isRequired,
  onSpouseAgeChange: PropTypes.func.isRequired,
  customerMinAge: PropTypes.number.isRequired,
  customerMaxAge: PropTypes.number.isRequired,
  spouseMinAge: PropTypes.number.isRequired,
  spouseMaxAge: PropTypes.number.isRequired,
  lang: PropTypes.string,
  combined: PropTypes.bool.isRequired,
};

AgeInput.defaultProps = {
  customerAge: undefined,
  spouseAge: undefined,
  lang: 'fi',
};

export default AgeInput;
