import * as React from 'react';

import i18n from '../localization/i18n-wrapper';
import formatNumber from '../util/formatNumber';

import FormField from './formField';

export default class Children extends React.Component {
  static displayName = 'Children';

  render() {
    return (
      <div>
        <FormField fieldName="children" />
        <div>
          {i18n.t('needCalculator_increasePerChild', {
            amount: formatNumber(20000),
          })}
        </div>
      </div>
    );
  }
}
