import * as React from 'react';
import * as PropTypes from 'prop-types';
import * as classNames from 'classnames';
import Info from './info';
import I18n from '../localization/I18n';

export default function FieldBase(props) {
  const formGroupClasses = classNames({
    'form-group': true,
    'read-only': !!props.readOnly,
  });

  const labelClasses = classNames({
    'control-label': true,
    error: !!props.hasError,
  });

  return (
    <div className={formGroupClasses} data-marker={props.id}>
      <label className={labelClasses} htmlFor={props.id}>
        <span>{props.label}</span>
      </label>
      <div className="form-entry">{props.children}</div>
      { props.infoKey && !props.readOnly && <Info><I18n i18nKey={props.infoKey} /></Info>}

    </div>
  );
}

FieldBase.displayName = 'FieldBase';

FieldBase.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  hasError: PropTypes.bool,
  readOnly: PropTypes.bool,
  children: PropTypes.any,
  infoKey: PropTypes.string,
};
