import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import range from 'lodash/range';

import i18n from '../localization/i18n-wrapper';
import I18n from '../localization/I18n';

import Info from '../common/info';

import purchaseApi from '../api/purchaseApi';
import { getBillsPerYear } from '../redux/reducers/selectedReducer';
import { setBillsPerYear } from '../redux/actions/selectedActions';

const ThankYouFrequency = function (props) {
  const handleFrequencyChange = (event) => {
    event.stopPropagation();
    const frequency = event.target.value;
    props.updatePaymentFrequency(frequency);
    props.postState();
  };

  const freqs = range(1, 5, 1).map((num) => <option key={num}>{num}</option>);

  return (
    <div className="form-group">
      <label className="col-sm-6 control-label" htmlFor="billingFrequencySelect">
        {i18n.t('thankYouBillingFrequency')}
      </label>
      <div className="col-sm-6 form-entry">
        <select
          className="form-control"
          id="billingFrequencySelect"
          onChange={handleFrequencyChange}
          value={props.paymentFrequency}
        >
          {freqs}
        </select>
      </div>
      <div className="infoContainer">
        <Info>
          <I18n i18nKey="thankYouFrequencyInfoKey" />
        </Info>
      </div>
    </div>
  );
};

ThankYouFrequency.displayName = 'ThankYouFrequency';

ThankYouFrequency.propTypes = {
  paymentFrequency: PropTypes.number.isRequired,
  updatePaymentFrequency: PropTypes.func.isRequired,
  postState: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  paymentFrequency: getBillsPerYear(state),
});

const mapDispatchToProps = (dispatch) => ({
  updatePaymentFrequency: (frequency) => {
    dispatch(setBillsPerYear(frequency));
  },
  postState: () => {
    purchaseApi.postState(dispatch);
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ThankYouFrequency);
