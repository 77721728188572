import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import get from 'lodash/get';

import I18n from '../localization/I18n';
import { setCheckboxAnswer } from '../redux/actions/purchaseActions';

class Checkbox extends React.Component {
  static displayName = 'Checkbox';

  static propTypes = {
    value: PropTypes.bool.isRequired,
    marker: PropTypes.string,
    children: PropTypes.any,
    onChange: PropTypes.func,
  };

  handleChange = (event) => {
    if (this.props.onChange) {
      this.props.onChange(event.target.checked);
    }
  };

  render = () => {
    const { value } = this.props;

    return (
      <div className="checkboxQuestion" data-marker={this.props.marker}>
        <input type="checkbox" checked={value} onChange={this.handleChange} aria-labelledby={this.props.marker} />
        <p>{this.props.children}</p>
      </div>
    );
  };
}

class ApprovalCheckboxQuestion extends React.Component {
  static displayName = 'ApprovalCheckboxQuestion';

  static propTypes = {
    path: PropTypes.array.isRequired,
    purchaseState: PropTypes.object.isRequired,
    setCheckboxAnswer: PropTypes.func.isRequired,
  };

  handleChange = (answer) => {
    this.props.setCheckboxAnswer(...this.props.path, answer);
  };

  render = () => {
    const { purchaseState } = this.props;
    const q = get(purchaseState, this.props.path);

    return (
      <Checkbox marker={q.key.questionKey} value={!!q.answer} onChange={this.handleChange}>
        <span id={q.key.questionKey}><I18n i18nKey={q.key.questionKey} /></span>
      </Checkbox>
    );
  };
}

const mapDispatchToProps = (dispatch) => ({
  setCheckboxAnswer: (category, index, answer) => dispatch(setCheckboxAnswer(category, index, answer)),
});

export default connect(
  undefined,
  mapDispatchToProps,
)(ApprovalCheckboxQuestion);
