import * as React from 'react';
import * as PropTypes from 'prop-types';
import * as classNames from 'classnames';
import FocusLock from 'react-focus-lock';

import debounce from 'lodash/debounce';

export default class Picker extends React.Component {
  static displayName = 'Picker';

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.props.onVisibilityChange(true);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
  }

  toggleVisibility = () => {
    this.props.onVisibilityChange(!this.props.open);
  };

  delayedToggleDisplay() {
    debounce(this.toggleVisibility, 200, {
      leading: true,
      trailing: false,
    })();
  }

  doClose = () => {
    this.props.onVisibilityChange(false);
  };

  handleDocumentClick = (e) => {
    const isThisComponent = this.node.contains(e.target);
    if (!isThisComponent) {
      this.doClose();
    }
  };

  toggleDisplay = (e) => {
    if (!this.props.disabled && e.type !== 'focus') {
      this.delayedToggleDisplay();
    }
  };

  handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      this.toggleDisplay(e);
    }
  };

  render() {
    const {
      open, disabled, className, id, value, onWheel, children, i18nKey, memberOfOrganization, highlight, role,
    } = this.props;

    let classes = classNames({
      hvkPicker: true,
      open,
      disabled,
    });

    if (className) {
      classes += ` ${className}`;
    }

    const highlightClassname = highlight && memberOfOrganization && !open ? 'highlight' : '';

    return (
      <FocusLock disabled={!this.props.open}>
        <div className={classes} ref={(node) => (this.node = node)}>
          <div
            className={`pickerValue ${highlightClassname}`}
            onFocus={this.toggleDisplay}
            onClick={this.toggleDisplay}
            data-i18n={i18nKey}
            title={value}
            tabIndex={0}
            onKeyDown={this.handleKeyDown}
            role={role}
            aria-expanded={this.props.open}
            id={id}
          >
            {value}
          </div>
          <div className="pickerPopover" onWheel={onWheel}>
            <div className="pickerPopoverBody">{children}</div>
            <div className="section buttonContainer">
              <button type="button" onClick={this.doClose} className="closePicker btn btn-active">
                OK
              </button>
            </div>
          </div>
        </div>
      </FocusLock>
    );
  }
}

Picker.propTypes = {
  open: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  highlight: PropTypes.bool,
  memberOfOrganization: PropTypes.bool,
  id: PropTypes.string,
  i18nKey: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  className: PropTypes.string,
  children: PropTypes.any,
  onWheel: PropTypes.func,
  onVisibilityChange: PropTypes.func,
};

Picker.defaultProps = {
  disabled: false,
  highlight: false,
  className: '',
  children: [],
  onWheel() {},
  onVisibilityChange() {},
  memberOfOrganization: undefined,
  id: undefined,
  i18nKey: undefined,
};
