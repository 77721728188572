import without from 'lodash/without';
import * as actionTypes from '../actionTypes';
import { getCustomerProducts, getOrganization, isMemberOfOrganization } from '../reducers/selectedReducer';
import { removeCustomerProduct } from './selectedActions';
import { availableProducts } from '../../models/productModel';
import { getMandatumLifeOrganization } from '../../models/organizationModel';
import { getOrganizations } from '../reducers/dataReducer';

export const setCustomerAvailableProducts = () => (dispatch, getState) => {
  const state = getState();
  const { customerAge } = state.selected;
  const organization = isMemberOfOrganization(state)
    ? getOrganization(state)
    : getMandatumLifeOrganization(getOrganizations(state));
  const products = (organization && organization.products) || [];
  const customerAvailableProducts = availableProducts(products, customerAge);

  dispatch({
    type: actionTypes.SET_CUSTOMER_AVAILABLE_PRODUCTS,
    customerAvailableProducts,
  });

  const removeCustomerProducts = without(getCustomerProducts(state), ...customerAvailableProducts);
  removeCustomerProducts.forEach((product) => {
    dispatch(removeCustomerProduct(product));
  });
};
