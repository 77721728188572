import {
  automaticProcessingQuestionOrgCI,
  automaticProcessingQuestionOrgNoCI,
  automaticProcessingQuestionML,
} from '../config';

export const getAutomaticProcessingQuestions = (products, memberOfOrganization) => {
  const hasPrimusLife = products.includes('LIFE') && memberOfOrganization;
  const hasAccidentalProduct = products.filter((p) => p !== 'CI' && p !== 'LIFE').length > 0;
  const hasCI = products.includes('CI');
  const hasML = hasCI || (products.includes('LIFE') && !memberOfOrganization);
  if (hasPrimusLife || hasAccidentalProduct) {
    if (hasCI) {
      return automaticProcessingQuestionOrgCI;
    }
    return automaticProcessingQuestionOrgNoCI;
  }
  if (hasML) {
    return automaticProcessingQuestionML;
  }
  return [];
};
