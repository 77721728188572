import * as React from 'react';
import * as PropTypes from 'prop-types';

import I18n from '../localization/I18n';

import YesNoButton from '../common/yesNoButton';
import Info from '../common/info';

export default class extends React.Component {
  static displayName = 'YesNoQuestion';

  static propTypes = {
    value: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    marker: PropTypes.string.isRequired,
    children: PropTypes.any,
    infoBubble: PropTypes.string,
  };

  static defaultProps = {
    value: undefined,
    children: undefined,
    infoBubble: undefined,
  }

  handleYes = (event) => {
    event.preventDefault();
    if (this.props.onChange) {
      this.props.onChange(true);
    }
  };

  handleNo = (event) => {
    event.preventDefault();
    if (this.props.onChange) {
      this.props.onChange(false);
    }
  };

  render() {
    const {
      value, infoBubble, marker, children,
    } = this.props;

    const isYes = value === true;
    const isNo = value === false;

    const infosection = infoBubble
      ? (
        <div className="yesNo__infoContainer">
          <Info>
            <I18n i18nKey={infoBubble} />
          </Info>
        </div>
      )
      : null;
    return (
      <div className="yesNo" data-marker={marker}>
        {children}
        <div className="yesNoWrapper">
          <YesNoButton role="yes" selected={isYes} onClick={this.handleYes} />
          <YesNoButton role="no" selected={isNo} onClick={this.handleNo} />
          {infosection}
        </div>
      </div>
    );
  }
}
