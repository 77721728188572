import * as React from 'react';
import * as PropTypes from 'prop-types';

export default class Info extends React.Component {
  static displayName = 'Info';

  static propTypes = {
    children: PropTypes.any,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      visible: false,
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
  }

  handleDocumentClick = (e) => {
    if (!this.node.contains(e.target)) {
      this.hideInfo();
    }
  };

  hideInfo() {
    this.setState({
      visible: false,
    });
  }

  showInfo() {
    this.setState({
      visible: true,
    });
  }

  handleClick = (e) => {
    e.stopPropagation();
    if (this.state.visible) {
      this.hideInfo();
    } else {
      this.showInfo();
    }
  };

  render() {
    const styles = {
      display: this.state.visible ? 'block' : 'none',
    };

    return (
      <div className="info" ref={(node) => (this.node = node)} onClick={this.handleClick}>
        ?
        <div className="infoHover" style={styles}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
