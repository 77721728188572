import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import i18n from '../localization/i18n-wrapper';
import formatNumber from '../util/formatNumber';

import { getResult } from '../redux/reducers/needReducer';

class Result extends React.Component {
  static displayName = 'Result';

  static propTypes = {
    result: PropTypes.number.isRequired,
  };

  handleContinue() {
    window.location.href = './vakuutuksen-osto';
  }

  render() {
    const { result } = this.props;

    const text = [
      <div key="result" className="text">
        {i18n.t('needCalculator_result', { amount: formatNumber(result) })}
      </div>,
    ];

    if (result > 200000) {
      text.push(
        <div key="resultOver200k" className="small-text">
          {i18n.t('needCalculator_resultOver200k', {
            amount: formatNumber(result),
          })}
        </div>,
      );
    }

    return (
      <div className="result">
        {text}
        <button className="btn btn-active" onClick={this.handleContinue}>
          {i18n.t('startPurchaseFlow')}
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  result: getResult(state),
});

export default connect(mapStateToProps)(Result);
