import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';

import { navigate } from 'react-mini-router';
import SpouseQuestions from './spouseQuestions';
import SpouseDetails from './spouseDetails';

import i18n from '../localization/i18n-wrapper';

import SpouseHeader from './spouseHeader';
import BackButton from '../common/backButton';
import ContinueButton from '../common/continueButton';
import purchaseApi from '../api/purchaseApi';
import {
  getPurchase,
  getSpouseQuestions,
  isSpousePresent,
  isSpouseVastusOk,
  getApplicationState,
  getOptionalSpouseQuestions,
} from '../redux/reducers/purchaseReducer';
import PurchaseModel from '../models/purchaseModel';
import { setPageAndPriceVisibility } from '../redux/actions/purchaseActions';
import { getSelected, spouseHasProduct, getOrganization } from '../redux/reducers/selectedReducer';

import { SpouseAuthentication } from './spouseAuthentication';

function validationFilter(path) {
  return ['spouse', 'spouseBeneficiaryDetails'].indexOf(path[0]) > -1;
}

class SpousePage extends React.Component {
  static displayName = 'SpousePage';

  state = {
    strict: false,
  };

  getUnansweredQuestions = (questions = []) => {
    const result = questions.reduce((acc, q) => acc + (q.answer ? 0 : 1), 0);
    return result;
  };

  getProblems = () => {
    const { strict } = this.state;
    const {
      purchase,
      selected,
      spousePresent,
      spouseVastusOk,
      spouseQuestions,
      optionalSpouseQuestions,
      organization,
      hasCi,
    } = this.props;

    const purchaseModel = new PurchaseModel(purchase);
    const context = {
      purchase,
      selected,
      organization,
    };

    const relaxedErrors = purchaseModel.validate(context, !strict, validationFilter);
    const strictErrors = strict ? relaxedErrors : purchaseModel.validate(context, false, validationFilter);
    const unansweredQuestions = spousePresent ? this.getUnansweredQuestions(spouseQuestions) : 0;
    const unansweredOptionalQuestions = spousePresent && hasCi ? this.getUnansweredQuestions(optionalSpouseQuestions) : 0;

    return {
      relaxedErrors,
      strictErrors,
      unansweredQuestions,
      unansweredOptionalQuestions,
      spouseVastusOk: !spousePresent || spouseVastusOk,
    };
  };

  handleContinue = (event) => {
    event.preventDefault();
    event.stopPropagation();

    // are we valid?

    const problems = this.getProblems();

    // no!
    if (!isEmpty(problems.strictErrors)) {
      this.setState({
        strict: true,
      });
    } else if (problems.unansweredQuestions > 0) {
      // TODO visualize this somehow
    } else if (!problems.spouseVastusOk) {
      // TODO visualize this somehow
    } else {
      this.props.postState();
      navigate('/3');
    }
  };

  handleBack = (event) => {
    event.preventDefault();
    event.stopPropagation();
    navigate('/');
  };

  componentDidMount = () => {
    const { applicationState } = this.props;
    if (applicationState.indexOf('SPOUSE') === 0) {
      this.scrollUntilContinueButtonVisible();
    } else {
      document.getElementById('purchaseContent').scrollTop = 0;
    }
    setPageAndPriceVisibility();
  };

  scrollUntilContinueButtonVisible = () => {
    const continueButton = document.getElementById('continueButton');
    continueButton.scrollIntoView();
  };

  render() {
    let error;

    const problems = this.getProblems();

    const validationOk = isEmpty(problems.strictErrors);
    const healthQuestionsOk = problems.unansweredQuestions === 0 && problems.unansweredOptionalQuestions === 0;

    if (!validationOk) {
      error = i18n.t('unansweredQuestions');
    } else if (!healthQuestionsOk) {
      error = i18n.t('missingHealthCheckAnswer');
    } else if (!problems.spouseVastusOk) {
      error = i18n.t('missingSpouseVastus');
    }

    const { spousePresent, spouseVastusOk } = this.props;

    return (
      <div className="spousePage">
        <SpouseHeader spousePresent={spousePresent} spouseVastusOk={spouseVastusOk} />

        <SpouseDetails errors={problems.relaxedErrors} />
        <SpouseQuestions errors={problems.relaxedErrors} />

        <SpouseAuthentication
          enabled={validationOk && healthQuestionsOk}
          spousePresent={spousePresent}
          spouseVastusOk={spouseVastusOk}
        />

        <BackButton onClick={this.handleBack} />
        <ContinueButton disabled={spousePresent && !spouseVastusOk} error={error} onClick={this.handleContinue} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  purchase: getPurchase(state),
  selected: getSelected(state),
  spouseQuestions: getSpouseQuestions(state),
  optionalSpouseQuestions: getOptionalSpouseQuestions(state),
  spousePresent: isSpousePresent(state),
  spouseVastusOk: isSpouseVastusOk(state),
  hasCi: spouseHasProduct(state, 'CI'),
  applicationState: getApplicationState(state),
  organization: getOrganization(state),
});

const mapDispatchToProps = (dispatch) => ({
  setPageAndPriceVisibility: dispatch(setPageAndPriceVisibility('spouse', true)),
  postState: () => purchaseApi.postState(dispatch),
});

SpousePage.propTypes = {
  selected: PropTypes.object.isRequired,
  purchase: PropTypes.object.isRequired,
  spousePresent: PropTypes.bool.isRequired,
  spouseVastusOk: PropTypes.bool.isRequired,
  spouseQuestions: PropTypes.array.isRequired,
  optionalSpouseQuestions: PropTypes.array.isRequired,
  organization: PropTypes.object,
  applicationState: PropTypes.string.isRequired,
  hasCi: PropTypes.bool.isRequired,
  enabled: PropTypes.bool,
  postState: PropTypes.func.isRequired,
};

SpousePage.defaultProps = {
  organization: {},
  enabled: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SpousePage);
