import * as React from 'react';
import * as PropTypes from 'prop-types';
import * as classNames from 'classnames';

import i18n from 'i18next-client';

export default class BackButton extends React.Component {
  render() {
    const { className } = this.props;
    const classes = {
      btn: true,
      'btn-active': true,
    };

    return (
      <div className={`backButton ${className}`}>
        <button className={classNames(classes)} onClick={this.props.onClick}>
          {i18n.t('backButton')}
        </button>
      </div>
    );
  }
}

BackButton.displayName = 'BackButton';

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

BackButton.defaultProps = {
  className: '',
};
