import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import Info from '../common/info';
import { CompensationRows } from '../products/compensationRow';

import i18n from '../localization/i18n-wrapper';
import I18n from '../localization/I18n';

import { postState } from '../api/purchaseApi';

const ThankYouCompensationSum = function (props) {
  const handleSelectAmount = (event) => {
    event.stopPropagation();
    const newSum = event.target.value;
    props.updateCompensation(newSum);
    props.postState();
  };

  const sums = <CompensationRows compensations={props.compensations} />;

  const info = (
    <Info>
      <I18n i18nKey={props.sumInfoKey} />
    </Info>
  );

  return (
    <div className="form-group">
      <label className="control-label" htmlFor="customerSumSelect">
        {i18n.t('thankYouSumHeader')}
      </label>
      <div className="form-entry">
        <select
          className="form-control"
          id="customerSumSelect"
          onChange={handleSelectAmount}
          value={props.compensation}
        >
          {sums}
        </select>
      </div>
      <div className="infoContainer">{info}</div>
    </div>
  );
};

ThankYouCompensationSum.displayName = 'ThankYouCompensationSum';
ThankYouCompensationSum.propTypes = {
  sumInfoKey: PropTypes.string.isRequired,
  setCompensation: PropTypes.func.isRequired,
  getCurrentCompensation: PropTypes.func.isRequired,
  getCompensations: PropTypes.func.isRequired,
  compensation: PropTypes.number.isRequired,
  compensations: PropTypes.arrayOf(PropTypes.number).isRequired,
  updateCompensation: PropTypes.func.isRequired,
  postState: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  compensation: ownProps.getCurrentCompensation(state),
  compensations: ownProps.getCompensations(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateCompensation: (compensation) => {
    dispatch(ownProps.setCompensation(compensation));
  },
  postState: () => {
    postState(dispatch);
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ThankYouCompensationSum);
