import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import i18n from '../localization/i18n-wrapper';
import { setSpousePresent } from '../redux/actions/purchaseActions';
import { isSpousePresent } from '../redux/reducers/purchaseReducer';
import { isCoupleInsuranceSelected, isMemberOfOrganization } from '../redux/reducers/selectedReducer';
import purchaseApi from '../api/purchaseApi';
import OptionalQuestionConfirmationDialog from './optionalQuestionConfirmationDialog';

class SpouseHeader extends React.Component {
  static displayName = 'spouseHeader';

  static propTypes = {
    spousePresent: PropTypes.bool.isRequired,
    coupleInsurance: PropTypes.bool.isRequired,
    setSpousePresent: PropTypes.func.isRequired,
    postState: PropTypes.func.isRequired,
  };

  state = {
    showConfirmationDialog: false,
  };

  // toggle
  handleSpousePresent = (event) => {
    if (event.target.checked) {
      this.setState({ showConfirmationDialog: true });
    } else {
      this.props.setSpousePresent(true);
    }
    this.props.postState();
  };

  removeSpouse = () => {
    this.props.setSpousePresent(false);
    this.props.postState();
    this.closeDialog();
  };

  closeDialog = () => {
    this.setState({ showConfirmationDialog: false });
  };

  getDialog = (memberOfOrganization) => {
    let dialogText = 'spouseNotPresent-ml';
    if (memberOfOrganization) {
      dialogText = 'spouseNotPresent-if';
    }

    if (!this.state.showConfirmationDialog) {
      return [];
    }

    return (
      <OptionalQuestionConfirmationDialog
        title="ciConfirmationTitle"
        description={dialogText}
        okAction={this.removeSpouse}
        cancelAction={this.closeDialog}
      />
    );
  };

  render() {
    const { spousePresent, coupleInsurance, memberOfOrganization } = this.props;

    const spousePresentQuestion = coupleInsurance ? 'coupleInsuranceSpousePresentQuestion' : 'spousePresentQuestion';

    const dialog = this.getDialog(memberOfOrganization);

    return (

      <div className="purchaseSection spouseHeader">
        {dialog}
        <h2>Puolison Vakuutus</h2>

        <p className="spouseRequirement">{i18n.t('spouseQuestionsExplanation')}</p>

        <p className="spousePresent">
          <input
            type="checkbox"
            checked={!spousePresent}
            onChange={this.handleSpousePresent}
            aria-labelledby="spouseQuestionLabel"
          />
          {' '}
          <span id="spouseQuestionLabel">{i18n.t(spousePresentQuestion)}</span>
        </p>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  spousePresent: isSpousePresent(state),
  coupleInsurance: isCoupleInsuranceSelected(state),
  memberOfOrganization: isMemberOfOrganization(state),
});

const mapDispatchToProps = (dispatch) => ({
  setSpousePresent: (value) => dispatch(setSpousePresent(value)),
  postState: () => purchaseApi.postState(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpouseHeader);
