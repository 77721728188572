import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import i18n from '../localization/i18n-wrapper';

import YesNoQuestion from './yesNoQuestion';
import { setYesNoQuestionAnswer } from '../redux/actions/purchaseActions';
import { removeCustomerProduct, removeSpouseProduct } from '../redux/actions/selectedActions';
import OptionalQuestionConfirmationDialog from './optionalQuestionConfirmationDialog';
import { getCustomerProducts, getSpouseProducts } from '../redux/reducers/selectedReducer';
import purchaseApi from '../api/purchaseApi';

class OptionalHealthYesNoQuestion extends React.Component {
  static displayName = 'HealthYesNoQuestion';

  static propTypes = {
    path: PropTypes.array.isRequired,
    question: PropTypes.shape({
      answer: PropTypes.bool,
      infoKey: PropTypes.string,
      key: PropTypes.shape({
        questionKey: PropTypes.string.isRequired,
        ref: PropTypes.string,
      }),
    }).isRequired,
    customerProducts: PropTypes.array.isRequired,
    spouseProducts: PropTypes.array.isRequired,
    personType: PropTypes.string.isRequired,
    setYesNoQuestionAnswer: PropTypes.func.isRequired,
    removeCustomerProduct: PropTypes.func.isRequired,
    removeSpouseProduct: PropTypes.func.isRequired,
    postState: PropTypes.func.isRequired,
  };

  state = {
    showConfirmationDialog: false,
  };

  handleChange = (value) => {
    const answer = value;
    if (answer === false) {
      this.setState({ showConfirmationDialog: true });
    } else {
      this.props.setYesNoQuestionAnswer(...this.props.path, answer);
    }
  };

  removeCiProduct = () => {
    this.props.setYesNoQuestionAnswer(...this.props.path, false);
    if (this.props.personType === 'spouse') {
      this.props.removeSpouseProduct('CI');
    } else {
      this.props.removeCustomerProduct('CI');
    }
    this.props.postState();
    this.closeDialog();
  };

  closeDialog = () => {
    this.setState({ showConfirmationDialog: false });
  };

  getDialog = () => {
    if (!this.state.showConfirmationDialog) {
      return [];
    }

    const { customerProducts, spouseProducts, personType } = this.props;
    const productsList = personType === 'spouse' ? spouseProducts : customerProducts;
    const dialogText = productsList.length > 0 ? 'ciConfirmationDescription' : 'ciOnlyConfirmationDescription';

    return (
      <OptionalQuestionConfirmationDialog
        title="ciConfirmationTitle"
        description={dialogText}
        okAction={this.removeCiProduct}
        cancelAction={this.closeDialog}
      />
    );
  };

  getInfoMessage = (question) => (
    question.answer === false
      ? <div className="ci-info-message">{i18n.t('ciNotPossibleInfoText')}</div>
      : []
  );

  render() {
    const { question } = this.props;
    const infoMessage = this.getInfoMessage(question);
    const dialog = this.getDialog();
    return (
      <div className="yesNoWrapper">
        {dialog}
        <YesNoQuestion
          marker={question.key.questionKey}
          value={question.answer}
          infoBubble={question.infoKey}
          onChange={this.handleChange}
        >
          {i18n.t(question.key.questionKey)}
        </YesNoQuestion>
        {infoMessage}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  customerProducts: getCustomerProducts(state),
  spouseProducts: getSpouseProducts(state),
});

const mapDispatchToProps = (dispatch) => ({
  setYesNoQuestionAnswer: (path, index, answer) => {
    dispatch(setYesNoQuestionAnswer(path, index, answer));
  },
  removeCustomerProduct: (product) => dispatch(removeCustomerProduct(product)),
  removeSpouseProduct: (product) => dispatch(removeSpouseProduct(product)),
  postState: () => {
    purchaseApi.postState(dispatch);
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OptionalHealthYesNoQuestion);
