import { inRange } from '../util/utils';

function isAvailableForAge(age, prod) {
  return !age || inRange(age, prod.minAge, prod.maxAge);
}

export function availableProducts(products, age) {
  if (!products) return [];
  return products
    .filter((prod) => prod.maxCompensation > 0 && isAvailableForAge(age, prod))
    .map((prod) => prod.productType);
}

export const hasProduct = (products, product) => products && products.includes(product);
