import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import i18n from '../localization/i18n-wrapper';

import { getPurchase } from '../redux/reducers/purchaseReducer';
import { getSelected } from '../redux/reducers/selectedReducer';

import BeneficiaryField from '../purchase/beneficiaryField';

import PurchaseModel from '../models/purchaseModel';

const validationFilter = (path) => ['customerBeneficiaryDetails'].indexOf(path[0]) > -1;

const LifeBeneficiary = (props) => {
  const benId = 'thankYouBeneficiary';
  const { purchase, selected } = props;

  const purchaseModel = new PurchaseModel(purchase);
  const context = {
    purchase,
    selected,
  };
  const errors = purchaseModel.validate(context, true, validationFilter);

  return (
    <BeneficiaryField
      key={benId}
      root={purchase}
      errors={errors}
      label={i18n.raw('personBeneficiary')}
      path="customerBeneficiary"
      detailsPath="customerBeneficiaryDetails"
      id={benId}
      readOnly={false}
    />
  );
};

LifeBeneficiary.displayName = 'LifeBeneficiary';

LifeBeneficiary.propTypes = {
  purchase: PropTypes.object.isRequired,
  selected: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  purchase: getPurchase(state),
  selected: getSelected(state),
});

export default connect(mapStateToProps)(LifeBeneficiary);
