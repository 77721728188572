import assign from 'lodash/assign';
import cloneDeep from 'lodash/cloneDeep';

import { post } from '../util/http';

import { getSelected } from './reducers/selectedReducer';
import { getPurchase } from './reducers/purchaseReducer';
import HvkStore from './hvkStore';
import { changeBooleanAnswersToString } from '../models/purchaseModel';

function setupForCalculator() {
  this.update = function () {
    const state = HvkStore.getInstance().getState();
    const payload = getSelected(state);
    return post('calculatedPrice', payload);
  };
}

function setupForPurchase() {
  this.update = function () {
    const state = HvkStore.getInstance().getState();
    const purchaseState = convertPurchase(getPurchase(state));
    const selected = getSelected(state);

    const payload = assign({}, purchaseState, {
      priceVariables: selected,
      billsPerYear: 4, // TODO parameterize
    });

    return post('purchaseState', payload);
  };
}

const convertPurchase = (purchase) => {
  const copy = cloneDeep(purchase);
  modifyQuestions(copy.customerQuestions);
  modifyQuestions(copy.spouseQuestions);
  modifyQuestions(copy.approvalQuestions);
  modifyQuestions(copy.ciApprovalQuestions);
  modifyQuestions(copy.optionalCustomerQuestions);
  modifyQuestions(copy.optionalSpouseQuestions);
  return copy;
};

const modifyQuestions = (questions) => {
  stripInfoKeys(questions);
  changeBooleanAnswersToString(questions);
};

/**
 * Removes info bubble localization keys from questions so that they won't get sent
 * to server
 * @param {HealthQuestion[]} questions
 */
const stripInfoKeys = (questions) => questions.forEach((question) => (delete question.infoKey));

export default {
  setupForCalculator,
  setupForPurchase,
};
