import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import i18n from '../localization/i18n-wrapper';

import Vastus from './vastus';

export const SpouseAuthentication = (props) => {
  const {
    spousePresent,
    spouseVastusOk,
    enabled,
  } = props;

  if (spouseVastusOk) {
    return (
      <div className="purchaseSection" id="authTitle">
        <h2>{i18n.t('spouseAuthenticationTitle')}</h2>
        <p>{i18n.t('spouseAuthenticationOk')}</p>
      </div>
    );
  }

  if (spousePresent && enabled) {
    return <Vastus title="spouseAuthenticationTitle" prompt="chooseBank" vastusCallbackUrl="vastus" spouse />;
  }

  return <div />;
};

SpouseAuthentication.propTypes = {
  spousePresent: PropTypes.bool.isRequired,
  spouseVastusOk: PropTypes.bool.isRequired,
  enabled: PropTypes.bool.isRequired,
};
