import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import find from 'lodash/find';

import formatNumber from '../util/formatNumber';
import i18n from '../localization/i18n-wrapper';

import ProductRow from './productRow';

import {
  isMemberOfOrganization,
  getCustomerProducts,
  getSpouseProducts,
  isCoupleInsuranceSelected,
  hasCustomer,
  hasSpouse,
  getOrganization,
  isAccidentalInsurancePossible,
} from '../redux/reducers/selectedReducer';
import {
  getNormalTotalAdultDabPerMonth,
  getNormalTotalAdultDaePerMonth,
  getNormalTotalAdultDaiPerMonth,
  getNormalTotalAdultDalPerMonth,
  getNormalCustomerDabPerMonth,
  getNormalCustomerDaePerMonth,
  getNormalCustomerDaiPerMonth,
  getNormalCustomerDalPerMonth,
  getNormalSpouseDabPerMonth,
  getNormalSpouseDaePerMonth,
  getNormalSpouseDaiPerMonth,
  getNormalSpouseDalPerMonth,
} from '../redux/reducers/priceReducer';
import {
  addCustomerProduct,
  addSpouseProduct,
  removeSpouseProduct,
  removeCustomerProduct,
} from '../redux/actions/selectedActions';
import purchaseApi from '../api/purchaseApi';
import { isSpouseDifferent } from '../redux/reducers/purchaseReducer';

class AccidentProducts extends React.Component {
  handleChange = (productCode, event) => {
    if (event.target.checked) {
      this.addProduct(productCode);
    } else {
      this.removeProduct(productCode);
    }
    this.props.postState();
    event.stopPropagation();
  };

  addProduct = (productCode) => {
    const { addCustomerAccidentProduct, addSpouseAccidentProduct } = this.props;
    if (this.isForCustomer()) {
      addCustomerAccidentProduct(productCode);
    }
    if (this.isForSpouse()) {
      addSpouseAccidentProduct(productCode);
    }
  };

  removeProduct = (productCode) => {
    const { removeCustomerAccidentProduct, removeSpouseAccidentProduct } = this.props;
    if (this.isForCustomer()) {
      removeCustomerAccidentProduct(productCode);
    }
    if (this.isForSpouse()) {
      removeSpouseAccidentProduct(productCode);
    }
  };

  isForCustomer = () => !!(this.props.hasCustomer && this.props.identifier !== 'spouse');

  isForSpouse = () => {
    const p = this.props;
    const spouseSameAsCustomer = p.hasSpouse && !p.spouseDifferent;
    const spouseSpecific = p.hasSpouse && p.spouseDifferent && p.identifier === 'spouse';
    return !!(spouseSameAsCustomer || spouseSpecific);
  };

  maxAmount = (productCode) => {
    if (this.props.organization) {
      const product = find(
        (this.props.organization && this.props.organization.products) || [],
        (prod) => prod.productType === productCode,
      );

      if (product) {
        return formatNumber(product.maxCompensation);
      }
    }
    return '0';
  };

  isSelected = (productCode) => {
    const { customerProducts, spouseProducts } = this.props;
    const productList = this.isForCustomer() ? customerProducts : spouseProducts;
    return productList.includes(productCode);
  };

  isAvailable = (product) => this.props.availableProducts.indexOf(product) !== -1;

  getInfoKey = (productCode) => `${productCode}InfoKey`;

  getPricesPerMonth = () => {
    let dabPerMonth;
    let daePerMonth;
    let daiPerMonth;
    let dalPerMonth;
    const p = this.props;
    if (p.spouseDifferent) {
      const isForCustomer = this.isForCustomer();
      dabPerMonth = isForCustomer ? p.customerDabPerMonth : p.spouseDabPerMonth;
      daePerMonth = isForCustomer ? p.customerDaePerMonth : p.spouseDaePerMonth;
      daiPerMonth = isForCustomer ? p.customerDaiPerMonth : p.spouseDaiPerMonth;
      dalPerMonth = isForCustomer ? p.customerDalPerMonth : p.spouseDalPerMonth;
    } else {
      dabPerMonth = p.totalDabPerMonth;
      daePerMonth = p.totalDaePerMonth;
      daiPerMonth = p.totalDaiPerMonth;
      dalPerMonth = p.totalDalPerMonth;
    }
    return {
      dabPerMonth,
      daePerMonth,
      daiPerMonth,
      dalPerMonth,
    };
  };

  render() {
    const { availableProducts, memberOfOrganization, accidentialProductsAvailable } = this.props;

    const products = [];

    if (availableProducts.length > 1 && memberOfOrganization === true && accidentialProductsAvailable) {
      products.push(
        <h4 className="additionalTitle" key="additionalTitle">
          {i18n.t('productSelectorAdditionalProductTitle')}
        </h4>,
      );
    }

    const {
      dabPerMonth, daePerMonth, daiPerMonth, dalPerMonth,
    } = this.getPricesPerMonth();

    if (this.isAvailable('DAB')) {
      const productCode = 'DAB';
      const productAmount = (
        <p>
          {i18n.t('productSelectorMaxAmount', {
            maxAmount: this.maxAmount(productCode),
          })}
        </p>
      );
      products.push(
        <ProductRow
          key="DAB"
          label={i18n.t('productSelectorDABTitle')}
          onChange={(e) => this.handleChange(productCode, e)}
          infoKey={this.getInfoKey(productCode)}
          productCode={productCode}
          price={dabPerMonth}
          selected={this.isSelected(productCode)}
          analyticsIdentifier={this.props.analyticsIdentifier}
          productDescription={productAmount}
        />,
      );
    }

    if (this.isAvailable('DAE')) {
      const productCode = 'DAE';
      const productAmount = (
        <p>
          {i18n.t('productSelectorDAEMaxAmount', {
            maxAmount: this.maxAmount(productCode),
          })}
        </p>
      );
      products.push(
        <ProductRow
          key="DAE"
          label={i18n.t('productSelectorDAETitle')}
          onChange={(e) => this.handleChange(productCode, e)}
          infoKey={this.getInfoKey(productCode)}
          productCode={productCode}
          price={daePerMonth}
          selected={this.isSelected(productCode)}
          analyticsIdentifier={this.props.analyticsIdentifier}
          productDescription={productAmount}
        />,
      );
    }

    if (this.isAvailable('DAI')) {
      const productCode = 'DAI';
      const productAmount = (
        <p>
          {i18n.t('productSelectorMaxAmount', {
            maxAmount: this.maxAmount(productCode),
          })}
        </p>
      );
      products.push(
        <ProductRow
          key="DAI"
          label={i18n.t('productSelectorDAITitle')}
          onChange={(e) => this.handleChange(productCode, e)}
          infoKey={this.getInfoKey(productCode)}
          productCode={productCode}
          price={daiPerMonth}
          selected={this.isSelected(productCode)}
          analyticsIdentifier={this.props.analyticsIdentifier}
          productDescription={productAmount}
        />,
      );
    }

    if (this.isAvailable('DAL')) {
      const productCode = 'DAL';
      const productAmount = (
        <p>
          {i18n.t('productSelectorMaxAmount', {
            maxAmount: this.maxAmount(productCode),
          })}
        </p>
      );
      const beneficiaryInfo = (
        <p>
          {i18n.t('dalBeneficiaryInfo')}
        </p>
      );
      products.push(
        <ProductRow
          key="DAL"
          label={i18n.t('productSelectorDALTitle')}
          onChange={(e) => this.handleChange(productCode, e)}
          infoKey={this.getInfoKey(productCode)}
          productCode={productCode}
          price={dalPerMonth}
          selected={this.isSelected(productCode)}
          analyticsIdentifier={this.props.analyticsIdentifier}
          productDescription={productAmount}
          beneficiaryInfo={beneficiaryInfo}
        />,
      );
    }

    return <div>{products}</div>;
  }
}

AccidentProducts.displayName = 'AccidentProducts';

AccidentProducts.propTypes = {
  identifier: PropTypes.string,
  analyticsIdentifier: PropTypes.string,
  organization: PropTypes.object,
  spouseAge: PropTypes.number,
  customerProducts: PropTypes.array.isRequired,
  spouseProducts: PropTypes.array.isRequired,
  availableProducts: PropTypes.array.isRequired,
  accidentialProductsAvailable: PropTypes.bool,
  memberOfOrganization: PropTypes.bool.isRequired,
  addCustomerAccidentProduct: PropTypes.func.isRequired,
  addSpouseAccidentProduct: PropTypes.func.isRequired,
  removeCustomerAccidentProduct: PropTypes.func.isRequired,
  removeSpouseAccidentProduct: PropTypes.func.isRequired,
  hasCustomer: PropTypes.bool.isRequired,
  postState: PropTypes.func.isRequired,
};

AccidentProducts.defaultProps = {
  identifier: undefined,
  analyticsIdentifier: undefined,
  organization: {},
  spouseAge: undefined,
};

const mapStateToProps = (state) => ({
  organization: getOrganization(state),
  memberOfOrganization: isMemberOfOrganization(state),
  spouseProducts: getSpouseProducts(state),
  customerProducts: getCustomerProducts(state),
  totalDabPerMonth: getNormalTotalAdultDabPerMonth(state),
  totalDaePerMonth: getNormalTotalAdultDaePerMonth(state),
  totalDaiPerMonth: getNormalTotalAdultDaiPerMonth(state),
  totalDalPerMonth: getNormalTotalAdultDalPerMonth(state),
  customerDabPerMonth: getNormalCustomerDabPerMonth(state),
  customerDaePerMonth: getNormalCustomerDaePerMonth(state),
  customerDaiPerMonth: getNormalCustomerDaiPerMonth(state),
  customerDalPerMonth: getNormalCustomerDalPerMonth(state),
  spouseDabPerMonth: getNormalSpouseDabPerMonth(state),
  spouseDaePerMonth: getNormalSpouseDaePerMonth(state),
  spouseDaiPerMonth: getNormalSpouseDaiPerMonth(state),
  spouseDalPerMonth: getNormalSpouseDalPerMonth(state),
  hasCustomer: hasCustomer(state),
  hasSpouse: hasSpouse(state),
  spouseDifferent: isSpouseDifferent(state),
  accidentialProductsAvailable: isAccidentalInsurancePossible(state),
  coupleInsuranceSelected: isCoupleInsuranceSelected(state),
});

const mapDispatchToProps = (dispatch) => ({
  addCustomerAccidentProduct: (product) => dispatch(addCustomerProduct(product)),
  addSpouseAccidentProduct: (product) => dispatch(addSpouseProduct(product)),
  removeCustomerAccidentProduct: (product) => dispatch(removeCustomerProduct(product)),
  removeSpouseAccidentProduct: (product) => dispatch(removeSpouseProduct(product)),
  postState: () => purchaseApi.postState(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccidentProducts);
