import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Modal from 'react-modal';
import omit from 'lodash/omit';

import stateApiProvider from './redux/stateApiProvider';
import Router from './router';
import PriceBar from './purchase/priceBar';
import localizationsLoader from './localization/loader';
import loadOrganizations from './api/organizationsApi';
import { loadPostOffices } from './api/postofficesApi';
import { loadCampaign } from './api/campaignApi';
import { setHealthQuestions, setApprovalQuestions } from './redux/actions/purchaseActions';
import CalculatorModel from './models/calculatorModel';
import HvkStore from './redux/hvkStore';
import { setCICompensations } from './redux/actions/selectedActions';
import PurchaseModel from './models/purchaseModel';

export default async function (initialPriceVariables, calculationResult, localizations) {
  const calculatorModel = new CalculatorModel(initialPriceVariables.priceVariables);
  const purchaseModel = new PurchaseModel(omit(initialPriceVariables, 'priceVariables'));
  const initialState = {
    selected: calculatorModel,
    purchase: purchaseModel,
    price: calculationResult,
  };
  const hvkStore = HvkStore.getInstance(initialState).store;

  stateApiProvider.setupForPurchase();
  await loadOrganizations(hvkStore);
  localizationsLoader.initI18N(localizations);
  loadPostOffices(hvkStore);
  loadCampaign(hvkStore);

  hvkStore.dispatch(setHealthQuestions());
  hvkStore.dispatch(setApprovalQuestions());
  hvkStore.dispatch(setCICompensations());

  /*
    TODO: these are also initialized in calcStore
      if (hvkState.purchase.applicationState === 'SPOUSE_OK') {
        storeSpouseDetails();
      }
  */

  ReactDOM.render(
    <Provider store={hvkStore}>
      <Router />
    </Provider>,
    document.getElementById('purchaseContainer'),
  );
  ReactDOM.render(
    <Provider store={hvkStore}>
      <PriceBar />
    </Provider>,
    document.getElementById('priceContainer'),
  );

  Modal.setAppElement('#purchaseContent');
}
