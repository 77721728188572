import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import omit from 'lodash/omit';

import ThankYouCompensationSum from './thankYou/thankYouCompensationSum';
import ThankYouAge from './thankYou/thankYouAge';
import ThankYouFrequency from './thankYou/thankYouFrequency';
import ThankYouMonthlyPrice from './thankYou/thankYouMonthlyPrice';
import ThankYouApproval from './thankYou/thankYouApproval';
import { Footer, BottomFooter } from './thankYou/thankYouCommon';
import ThankYouPurchase from './thankYou/thankYouPurchase';
import LifeBeneficiary from './thankYou/lifeBeneficiary';

import stateApiProvider from './redux/stateApiProvider';
import localizationsLoader from './localization/loader';
import loadOrganizations from './api/organizationsApi';
import { postState } from './api/purchaseApi';
import CalculatorModel from './models/calculatorModel';
import HvkStore from './redux/hvkStore';
import {
  addCustomerProduct,
  setCompensations,
  setCustomerCompensation,
  setSpouseAge,
  removeAllCustomerProducts,
  removeAllSpouseProducts,
  setCustomerAge,
} from './redux/actions/selectedActions';
import {
  resetApprovalQuestionAnswers,
} from './redux/actions/purchaseActions';
import {
  getCustomerCompensation, getCompensations, getCustomerAge, defaultAge, defaultCompensation,
} from './redux/reducers/selectedReducer';
import { getApprovalQuestions, getCustomerQuestions, getCustomerSocialSecurityNumber } from './redux/reducers/purchaseReducer';
import { getNormalCustomerLifePerMonth } from './redux/reducers/priceReducer';
import { calculateAge } from './util/utils';

export default async function (initialPriceVariables, calculationResult, localizations) {
  const calculatorModel = new CalculatorModel(initialPriceVariables.priceVariables);
  const initialState = {
    selected: calculatorModel,
    purchase: omit(initialPriceVariables, 'priceVariables'),
    price: calculationResult,
  };
  const hvkStore = HvkStore.getInstance(initialState).store;
  const state = hvkStore.getState();

  stateApiProvider.setupForPurchase();

  await loadOrganizations(hvkStore);
  localizationsLoader.initI18N(localizations);

  const calculatePrices = async (hvkStore) => {
    await postState(hvkStore.dispatch);
  };

  hvkStore.dispatch(removeAllCustomerProducts());
  hvkStore.dispatch(removeAllSpouseProducts());
  hvkStore.dispatch(setSpouseAge());
  hvkStore.dispatch(addCustomerProduct('LIFE'));
  hvkStore.dispatch(setCompensations());
  hvkStore.dispatch(resetApprovalQuestionAnswers());

  if (getCustomerAge(state) == undefined) {
    hvkStore.dispatch(setCustomerAge(calculateAge(getCustomerSocialSecurityNumber(state)) || defaultAge));
  }
  if (getCustomerCompensation(state) == undefined) {
    hvkStore.dispatch(setCustomerCompensation(defaultCompensation));
  }

  await calculatePrices(hvkStore);

  stateApiProvider.setupForPurchase();

  const sum = document.getElementById('lifeSum');
  if (sum) {
    ReactDOM.render(
      <Provider store={hvkStore}>
        <ThankYouCompensationSum
          sumInfoKey="lifeSumInfoKey"
          setCompensation={setCustomerCompensation}
          getCurrentCompensation={getCustomerCompensation}
          getCompensations={getCompensations}
        />
      </Provider>,
      sum,
    );
  }

  const age = document.getElementById('lifeAge');
  if (age) {
    ReactDOM.render(
      <Provider store={hvkStore}>
        <ThankYouAge
          minAge={15}
          maxAge={70}
          infoKey="lifeAgeInfoKey"
        />
      </Provider>,
      age,
    );
  }

  const freq = document.getElementById('lifeFrequency');
  if (freq) {
    ReactDOM.render(
      <Provider store={hvkStore}>
        <ThankYouFrequency />
      </Provider>,
      freq,
    );
  }

  const beneficiary = document.getElementById('lifeBeneficiary');
  if (beneficiary) {
    ReactDOM.render(
      <Provider store={hvkStore}>
        <LifeBeneficiary />
      </Provider>,
      beneficiary,
    );
  }

  const resultField = document.getElementById('lifeResult');
  if (resultField) {
    ReactDOM.render(
      <Provider store={hvkStore}>
        <ThankYouMonthlyPrice
          priceInfoKey="lifeCalculatorText"
          getMonthlyPrice={getNormalCustomerLifePerMonth}
        />
      </Provider>,
      resultField,
    );
  }

  const healthQuestions = document.getElementById('lifeHealthQuestions');
  if (healthQuestions) {
    ReactDOM.render(
      <Provider store={hvkStore}>
        <ThankYouApproval
          header="thankYouApprovalHeader"
          subHeader="thankYouApprovalSubHeader"
          path="customerQuestions"
          getApprovalQuestions={getCustomerQuestions}
        />
      </Provider>,
      healthQuestions,
    );
  }

  const approval = document.getElementById('lifeApproval');
  if (approval) {
    ReactDOM.render(
      <Provider store={hvkStore}>
        <ThankYouApproval
          path="approvalQuestions"
          getApprovalQuestions={getApprovalQuestions}
        />
      </Provider>,
      approval,
    );
  }

  const begin = document.getElementById('beginLifePurchase');
  if (begin) {
    const validationFilter = (path) => ['customerBeneficiaryDetails'].indexOf(path[0]) > -1;

    ReactDOM.render(
      <Provider store={hvkStore}>
        <ThankYouPurchase
          getApprovalQuestions={getApprovalQuestions}
          getHealthQuestions={getCustomerQuestions}
          purchaseUrl="startLifePurchase"
          validationFilter={validationFilter}
        />
      </Provider>,
      begin,
    );
  }

  const footer = document.getElementById('lifeFooter');
  if (footer) {
    ReactDOM.render(
      <Provider store={hvkStore}>
        <Footer
          footerLocalizationKey="lifeFooter"
        />
      </Provider>,
      footer,
    );
  }

  const bottomFooter = document.getElementById('lifeBottomFooter');
  if (bottomFooter) {
    ReactDOM.render(
      <Provider store={hvkStore}>
        <BottomFooter />
      </Provider>,
      bottomFooter,
    );
  }
}
