import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import Calculator from './calculator/calculator';
import EmbeddedCalculator from './calculator/embeddedCalculator';
import stateApiProvider from './redux/stateApiProvider';
import PurchaseButton from './purchase/purchaseButton';
import localizationsLoader from './localization/loader';
import loadOrganizations from './api/organizationsApi';
import { setOrganizationRedirects } from './redux/actions/dataActions';
import { setHealthQuestions } from './redux/actions/purchaseActions';
import CalculatorModel from './models/calculatorModel';
import needCalculator from './needCalculator';
import HvkStore from './redux/hvkStore';
import { setCICompensations } from './redux/actions/selectedActions';

export default async function (initialPriceVariables, calculationResult, localizations, organizationRedirects) {
  const calculatorModel = new CalculatorModel(initialPriceVariables);
  const initialState = {
    selected: calculatorModel,
    price: calculationResult,
  };
  const hvkStore = HvkStore.getInstance(initialState).store;

  stateApiProvider.setupForCalculator();

  await loadOrganizations(hvkStore);
  localizationsLoader.initI18N(localizations);
  hvkStore.dispatch(setHealthQuestions());
  hvkStore.dispatch(setCICompensations());
  hvkStore.dispatch(setOrganizationRedirects(organizationRedirects));

  needCalculator(hvkStore);

  /* from old flux implementation, needed?
      _.assign(localizations,
      {'marketingTitle1': priceVariables.marketingTitle1},
      {'marketingTitle2': priceVariables.marketingTitle2}
    );
  */

  const calcContainer = document.getElementById('calcContainer');

  if (calcContainer) {
    ReactDOM.render(
      <div>
        <Provider store={hvkStore}>
          <Calculator />
        </Provider>
      </div>,
      calcContainer,
    );

    const container = document.getElementById('purchaseButtonContainer');

    if (container) {
      ReactDOM.render(
        <Provider store={hvkStore}>
          <PurchaseButton />
        </Provider>,
        container,
      );
    }
  }

  const embeddedCalcContainer = document.getElementById('embeddedCalcContainer');

  if (embeddedCalcContainer) {
    ReactDOM.render(
      <div>
        <Provider store={hvkStore}>
          <EmbeddedCalculator />
        </Provider>
      </div>,
      embeddedCalcContainer,
    );
  }
}
