import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import i18n from '../localization/i18n-wrapper';
import PersonDetails from './personDetails';
import { spouseHasProduct } from '../redux/reducers/selectedReducer';
import { getPurchase } from '../redux/reducers/purchaseReducer';

class SpouseDetails extends React.Component {
  static displayName = 'SpouseDetails';

  static propTypes = {
    purchase: PropTypes.object.isRequired,
    spouseHasLife: PropTypes.bool.isRequired,
    errors: PropTypes.object,
  };

  render() {
    const content = [];
    const { spouseHasLife, purchase, errors } = this.props;

    content.push(<h2 key="spouseDetailsHeader">
      {i18n.t('spouseDetailsTitle')}
      {' '}
    </h2>);
    content.push(
      <h4 className="centered" key="requiredFieldsHeader">
        {i18n.t('unansweredQuestionsBelow')}
      </h4>,
    );

    content.push(
      <PersonDetails
        marker="spouse"
        key="spouse"
        root={purchase}
        errors={errors}
        showPhoneAndEmail
        showAddress
        idPrefix="spouse"
        path="spouse"
        showBeneficiary={spouseHasLife}
        beneficiaryPath="spouseBeneficiary"
        beneficiaryDetailsPath="spouseBeneficiaryDetails"
        readOnly={false}
        showCopyButton
      />,
    );

    return <div className="purchaseSection">{content}</div>;
  }
}

const mapStateToProps = (state) => ({
  spouseHasLife: spouseHasProduct(state, 'LIFE'),
  purchase: getPurchase(state),
});

export default connect(mapStateToProps)(SpouseDetails);
