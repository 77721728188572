import get from 'lodash/get';

import * as actionTypes from '../actionTypes';

export const initialState = {
  organizations: [],
  postOffices: [],
  organizationRedirects: [],
};

const data = (state = initialState, action) => {
  switch (action && action.type) {
    case actionTypes.SET_ORGANIZATION_LIST: {
      const { organizations } = action;
      return {
        ...state,
        organizations,
      };
    }
    case actionTypes.SET_POST_OFFICES: {
      const { postOffices } = action;
      return {
        ...state,
        postOffices,
      };
    }
    case actionTypes.SET_ORGANIZATION_REDIRECTS: {
      const { organizationRedirects } = action;
      return {
        ...state,
        organizationRedirects,
      };
    }
    default: {
      return state;
    }
  }
};

export default data;

// Selectors

export const getOrganizations = (state) => get(state, 'data.organizations', []);

export const getOrganizationDetails = (state, id) => {
  const organizations = getOrganizations(state);
  const organization = organizations.filter((o) => o.id === id);
  return organization;
};

export const getPostOffices = (state) => get(state, 'data.postOffices', []);

export const getPostOfficeByCode = (state, code) => {
  const postOffices = getPostOffices(state);
  return postOffices.find((po) => po.postCodes.includes(code));
};

export const getOrganizationRedirects = (state) => get(state, 'data.organizationRedirects', []);
