import without from 'lodash/without';
import * as actionTypes from '../actionTypes';
import { availableProducts } from '../../models/productModel';
import { getSpouseProducts, getOrganization, isMemberOfOrganization } from '../reducers/selectedReducer';
import { removeSpouseProduct } from './selectedActions';
import { getMandatumLifeOrganization } from '../../models/organizationModel';
import { getOrganizations } from '../reducers/dataReducer';

export const setSpouseAvailableProducts = () => (dispatch, getState) => {
  const state = getState();
  const { spouseAge } = state.selected;
  const organization = isMemberOfOrganization(state)
    ? getOrganization(state)
    : getMandatumLifeOrganization(getOrganizations(state));
  const products = (organization && organization.products) || [];
  const spouseAvailableProducts = availableProducts(products, spouseAge);

  dispatch({
    type: actionTypes.SET_SPOUSE_AVAILABLE_PRODUCTS,
    spouseAvailableProducts,
  });

  const removeSpouseProducts = without(getSpouseProducts(state), ...spouseAvailableProducts);
  removeSpouseProducts.forEach((product) => {
    dispatch(removeSpouseProduct(product));
  });
};
