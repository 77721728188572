import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider, connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import { navigate } from 'react-mini-router';
import ProductPage from './purchase/productPage';
import CustomerAndChildrenPage from './purchase/customerAndChildrenPage';
import SpousePage from './purchase/spousePage';
import SummaryPage from './purchase/summaryPage';
import PageHeader from './purchase/pageHeader';
import { isSpouseVastusOk, isSpousePresent, getApplicationState } from './redux/reducers/purchaseReducer';
import { hasSpouse } from './redux/reducers/selectedReducer';

const { RouterMixin } = require('react-mini-router');

const pageHeaderDom = document.getElementById('purchaseHeader');

const content = document.getElementById('purchaseContent');

/* eslint react/prop-types: 0 */
const Router = createReactClass({
  mixins: [RouterMixin],

  contextTypes: {
    store: PropTypes.object,
  },

  routes: {
    '/': 'entryPoint',
    '/1': 'productPage',
    '/2a': 'customerAndChildrenPage',
    '/2b': 'spousePage',
    '/3': 'summaryPage',
  },

  componentDidMount() {
    switch (this.props.applicationState) {
      case 'CUSTOMER_OK':
      case 'CUSTOMER_REJECTED':
      case 'CUSTOMER_FAILED':
      case 'CUSTOMER_SSN_MISMATCH':
      case 'CUSTOMER_CREDIT_CHECK_FAILED':
      case 'CUSTOMER_PURCHASE_FAILED':
        navigate('/3');
        break;
      case 'SPOUSE_OK':
      case 'SPOUSE_REJECTED':
      case 'SPOUSE_FAILED':
      case 'SPOUSE_SSN_MISMATCH':
        navigate('/2b');
        break;
      case 'SESSION_EXPIRED':
        navigate('/1');
        break;
    }
  },

  componentDidUpdate() {
    const { store } = this.context;
    ReactDOM.render(
      <Provider store={store}>
        <PageHeader />
      </Provider>,
      pageHeaderDom,
    );
  },

  onChange() {
    this.forceUpdate();
  },

  customerAndChildrenPage() {
    content.className = 'hasPrice';
    const { store } = this.context;
    ReactDOM.render(
      <Provider store={store}>
        <PageHeader />
      </Provider>,
      pageHeaderDom,
    );
    return <CustomerAndChildrenPage />;
  },

  entryPoint() {
    content.className = 'hasPrice';
    return this.productPage();
  },

  productPage() {
    content.className = 'hasPrice';
    return <ProductPage />;
  },

  spousePage() {
    content.className = 'hasPrice';
    return <SpousePage />;
  },

  summaryPage() {
    const { hasSpouse, spouseVastusOk, spousePresent } = this.props;
    if (hasSpouse && !spouseVastusOk && spousePresent) {
      navigate('/2b', true);

      return this.spousePage();
    }
    content.className = '';
    return <SummaryPage />;
  },

  render() {
    return this.renderCurrentRoute();
  },
});

const mapStateToProps = (state) => ({
  spouseVastusOk: isSpouseVastusOk(state),
  hasSpouse: hasSpouse(state),
  spousePresent: isSpousePresent(state),
  applicationState: getApplicationState(state),
});

export default connect(mapStateToProps)(Router);
