import * as React from 'react';
import * as PropTypes from 'prop-types';
import * as classNames from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import filter from 'lodash/filter';

import PriceDisplay from '../../common/priceDisplay';
import i18n from '../../localization/i18n-wrapper';
import { Selviytymisturva } from './selviytymisturva';
import { Product } from './product';

export class InsuredPerson extends React.Component {
  static displayName = 'InsuredPerson';

  static propTypes = {
    idPrefix: PropTypes.string.isRequired,
    person: PropTypes.object.isRequired,
    beneficiary: PropTypes.string,
    beneficiaryDetails: PropTypes.object,
    products: PropTypes.array.isRequired,
    additionalProducts: PropTypes.array.isRequired,
    prices: PropTypes.object.isRequired,
    lifeAmount: PropTypes.number,
    memberOfOrganization: PropTypes.bool.isRequired,
    coupleInsuranceSelected: PropTypes.bool,
  };

  state = { open: false };

  _deductible = (deductibleDays) => (deductibleDays
    ? i18n.t('insuranceDeductibleDays', {
      days: deductibleDays,
      count: deductibleDays,
    })
    : i18n.t('insuranceWithouDeductibleDays'));

  _formatPerson = (person) => `${person.firstName} ${person.lastName}`;

  _getMaxCompensation = (productType, additionalProducts) => filter(additionalProducts, { productType })
    .map((p) => p.maxCompensation)
    .shift();

  _product = (product) => {
    const p = this.props;
    let lifeTitle = i18n.t('summaryProductLifeTitle');
    if (p.coupleInsurance) {
      lifeTitle = i18n.t('summaryProductCoupleInsuranceTitle');
    }
    switch (product) {
      case 'LIFE':
        return p.coupleInsurance ? (
          <span />
        ) : (
          <CSSTransition classNames="products" timeout={{ enter: 500, exit: 300 }} key={p.idPrefix + product}>
            <Product
              id={p.idPrefix + product}
              productType={product}
              title={lifeTitle}
              description={i18n.t('summaryProductLifeDescription')}
              person={p.person}
              amount={p.lifeAmount}
              form={i18n.t('productLifeFormEqualSum')}
              beneficiary={p.beneficiary}
              beneficiaryDetails={p.beneficiaryDetails}
              price={p.prices.lifePerMonth}
            />
          </CSSTransition>
        );
      case 'CI':
        return (
          <CSSTransition classNames="products" timeout={{ enter: 500, exit: 300 }} key={p.idPrefix + product}>
            <Product
              id={p.idPrefix + product}
              productType={product}
              title={i18n.t('productSelectorCITitle')}
              person={p.person}
              amount={p.ciAmount}
              smoking={p.smoking}
              price={p.prices.ciPerMonth}
            />
          </CSSTransition>
        );
      case 'DAB':
        return (
          <CSSTransition classNames="products" timeout={{ enter: 500, exit: 300 }} key={p.idPrefix + product}>
            <Product
              id={p.idPrefix + product}
              productType={product}
              title={i18n.t('summaryProductDabTitle')}
              person={p.person}
              amount={this._getMaxCompensation('DAB', p.additionalProducts)}
              price={p.prices.dabPerMonth}
              deductible={i18n.t('answerNo')}
              validity={p.prices.fullTime}
            />
          </CSSTransition>
        );
      case 'DAL':
        return (
          <CSSTransition classNames="products" timeout={{ enter: 500, exit: 300 }} key={p.idPrefix + product}>
            <Product
              productType={product}
              title={i18n.t('summaryProductDalTitle')}
              person={p.person}
              amount={this._getMaxCompensation('DAL', p.additionalProducts)}
              price={p.prices.dalPerMonth}
              validity={p.prices.fullTime}
              beneficiary={p.beneficiary}
              beneficiaryDetails={p.beneficiaryDetails}
            />
          </CSSTransition>
        );
      case 'DAE':
        return (
          <CSSTransition classNames="products" timeout={{ enter: 500, exit: 300 }} key={p.idPrefix + product}>
            <Product
              id={p.idPrefix + product}
              productType={product}
              title={i18n.t('summaryProductDaeTitle')}
              person={p.person}
              amount={this._getMaxCompensation('DAE', p.additionalProducts)}
              price={p.prices.daePerMonth}
              deductible={this._deductible(p.prices.daeDeductibleDays)}
              validity={p.prices.fullTime}
            />
          </CSSTransition>
        );
      case 'DAI':
        return (
          <CSSTransition classNames="products" timeout={{ enter: 500, exit: 300 }} key={p.idPrefix + product}>
            <Product
              id={p.idPrefix + product}
              productType={product}
              title={i18n.t('summaryProductDaiTitle')}
              person={p.person}
              amount={this._getMaxCompensation('DAI', p.additionalProducts)}
              price={p.prices.daiPerMonth}
              validity={p.prices.fullTime}
            />
          </CSSTransition>
        );

      default:
        return null;
    }
  };

  _primusInsuranceSelected = () => {
    const p = this.props;
    const primusInsurances = ['LIFE', 'DAB', 'DAE', 'DAI', 'DAL'];
    return p.products.some((p) => primusInsurances.indexOf(p) >= 0);
  };

  _summary = () => {
    const p = this.props;
    const formattedPerson = this._formatPerson(p.person);
    let productCount = 0;
    if (p.products) {
      if (p.coupleInsurance) {
        p.products.forEach((product) => {
          if (product == 'LIFE') {
            productCount -= 1;
          }
        });
      }
      productCount += p.products.length;
    }
    const iconClasses = classNames({
      fa: true,
      'fa-plus-square-o': !this.state.open,
      'fa-minus-square-o': !!this.state.open,
    });
    let price = p.prices.totalPerMonth;
    if (p.coupleInsurance) {
      price = p.prices.totalSelectedAdditionalProductsPerMonth;
    }
    const selviytysturva = p.memberOfOrganization && this._primusInsuranceSelected() ? 1 : 0;
    return (
      <div key={formattedPerson} className="insuranceSummary">
        <div className="personDetails">
          <label>{formattedPerson}</label>
          <p className="productSummary" onClick={this.handleClick}>
            <i className={iconClasses} />
            {' '}
            {i18n.t('insuranceSummaryProductCount', {
              count: productCount + selviytysturva,
            })}
          </p>
        </div>
        <PriceDisplay price={price} priceDisplayUnit={i18n.t('priceDisplayUnit')} decimals />
      </div>
    );
  };

  handleClick = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
  };

  render = () => {
    const p = this.props;
    const content = [];
    if (this.state.open) {
      if (p.products) {
        p.products.forEach((product) => {
          if (p.coupleInsurance && product == 'LIFE') {
            // Don't show individual LIFE insurance when buying coupleInsurance
          } else {
            content.push(this._product(product));
          }
        });
      }
    }
    if (p.memberOfOrganization && this._primusInsuranceSelected()) {
      const selviytymisturva = (
        <Selviytymisturva
          key={`${p.idPrefix}selvitysmisturva`}
          id={`${p.idPrefix}selvitysmisturva`}
          title="Selviytymisturva – Kaupan päälle 2 000 €"
          person={p.person}
          price={0}
          visible={this.state.open}
        />
      );
      content.push(selviytymisturva);
    }
    return (
      <div className="insuredPerson">
        {this._summary()}
        <TransitionGroup>
          {content}
        </TransitionGroup>
      </div>
    );
  };
}
