import * as React from 'react';
import * as PropTypes from 'prop-types';
import * as classNames from 'classnames';
import i18n from '../localization/i18n-wrapper';

export const PurchaseButton = (props) => {
  let errors;

  const classes = {
    btn: true,
  };

  const hasError = !!props.error;

  if (hasError) {
    errors = <div className="error">{props.error}</div>;
  } else {
    classes['btn-active'] = true;
    errors = <div className="error" />;
  }

  return (
    <div className="results">
      <button className={classNames(classes)} type="button" onClick={props.onClick}>
        {i18n.t('startThankYouPurchaseFlow')}
      </button>
      {errors}
    </div>
  );
};

PurchaseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export const Footer = (props) => {
  const { footerLocalizationKey } = props;
  return (
    <footer>
      <p>{i18n.t(footerLocalizationKey)}</p>
    </footer>
  );
};

Footer.propTypes = {
  footerLocalizationKey: PropTypes.string.isRequired,
};

export const BottomFooter = () => (
  <footer>
    <p>{i18n.t('thankYouBottomFooter')}</p>
  </footer>
);
