import i18n from '../localization/i18n-wrapper';
import { calculateAge } from '../util/utils';

export function verifyAgeIsCorrect(customerSocialSecurityNumber, customerAge) {
  let error;
  const ssnAge = calculateAge(customerSocialSecurityNumber);
  if (ssnAge !== customerAge) {
    error = i18n.t('validationSocialSecurityNumberAge');
  }
  return error;
}
