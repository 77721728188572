const numMatcher = new RegExp('^(-?\\d+)(?:\\.(\\d+))?$');

export default function (num, withDecimals) {
  let result;

  num = parseFloat(num.toString());

  if (!withDecimals) {
    num = Math.round(num);
  }

  const decimals = 2;

  const s = num.toString();
  const match = numMatcher.exec(s);

  if (match) {
    const integerPart = match[1];
    const decimalPart = match[2];

    let offset = integerPart.length % 3;
    if (offset == 0 && integerPart.length >= 3) {
      offset = 3; // first 3 numbers shouldn't be prefixed by space
    }

    result = integerPart.substr(0, offset);

    for (let i = offset; i < integerPart.length; i += 3) {
      result += `\xa0${integerPart.substr(i, 3)}`; // \xa0 is non-breakable space
    }

    if (withDecimals) {
      if (decimalPart && decimalPart.length) {
        result = `${result},${Math.round(parseInt(decimalPart, 10) * Math.pow(10, decimals - decimalPart.length))}`;
      } else {
        result = `${result},${new Array(decimals + 1).join('0')}`;
      }
    }
  }

  return result;
}
