import * as React from 'react';

export function thenDelay(ms) {
  return function () {
    return new Promise((resolve /* , reject */) => {
      setTimeout(resolve, ms); // (A)
    });
  };
}

export function ensureArray(val) {
  return [].concat(val || []);
}

export function safeNum(num) {
  let retval = num;

  if (typeof num !== 'number') {
    if (typeof num === 'string') {
      retval = parseInt(num, 10);
    } else {
      retval = 0;
    }
  }

  return retval;
}

export function gtZeroOrNull(val) {
  const num = safeNum(val);
  return num < 1 ? null : num;
}

export function extendArray(a, b) {
  b.forEach((val) => {
    a.push(val);
  });
}

export function inRange(num, min, max) {
  return num >= min && num <= max;
}

// match for 291088-066L is ['291088-066L', '291088', '-', '066', 'L']
const checkCharacterIsValid = (match) => {
  const checksumRemainder = '0123456789ABCDEFHJKLMNPRSTUVWXY';
  const checkChar = match[4].charAt(0);
  let sum = match[1] + match[3];
  if (sum.charAt(0) === '0') {
    sum = sum.substring(1);
  }

  const remainder = parseInt(sum, 10) % 31;
  return checkChar === checksumRemainder.charAt(remainder);
};

export const validateSsn = (ssn) => {
  if (!ssn) return false;
  const ssnRegex = /^(\d{6})([+\-A])(\d{3})([\dA-Z])$/;
  const match = ssnRegex.exec(ssn.trim().toUpperCase());
  return match ? checkCharacterIsValid(match) : false;
};

export function getCentury(centuryCode) {
  if (centuryCode === '+') {
    return 1800;
  }
  if (centuryCode === '-') {
    return 1900;
  }
  if (centuryCode === 'A') {
    return 2000;
  }
  return null;
}

export function calculateAge(ssn) {
  const ucSsn = ssn && ssn.toUpperCase();

  const ssnRegexp = /(\d{2})(\d{2})(\d{2})([+\-A])\d{3}[\dA-Z]/g;
  const match = ssnRegexp.exec(ucSsn);

  if (match && match.length > 4) {
    const date = safeNum(match[1]);
    const month = safeNum(match[2]);
    const year = safeNum(match[3]) + safeNum(getCentury(match[4]));

    const today = new Date();
    const todayYear = today.getFullYear();
    const todayMonth = today.getMonth() + 1;
    const todayDate = today.getDate();

    let age = todayYear - year;
    if (month > todayMonth || (month === todayMonth && date > todayDate)) {
      age -= 1;
    }

    return age;
  }

  return null;
}

export function zeroPad(str, length) {
  if (str.length >= length) {
    return str;
  }
  return new Array(length - str.length + 1).join('0') + str;
}

export function safeFloat(num) {
  let retval = num;

  if (typeof num !== 'number') {
    if (typeof num === 'string') {
      retval = parseFloat(num);
    } else {
      retval = 0;
    }
  }

  return retval;
}

/* eslint react/prop-types: off */
/* eslint react/prefer-stateless-function: off */
/* eslint react/jsx-filename-extension: off */
/* eslint react/destructuring-assignment: off */
export class DumpJSON extends React.Component {
  render() {
    return <pre>{JSON.stringify(this.props.value, undefined, 2)}</pre>;
  }
}

export function formatPrice(price, decimals) {
  if (!price) return '';

  return (Math.round(price * 100) / 100).toFixed(decimals);
}

export function sumArrayLengths(...arrays) {
  return arrays.reduce((total, array) => total + array.length, 0);
}

/**
 * Searches closest number to target from numbers
 *
 * @param {number} target      number to which closest number is searched
 * @param {number[]} numbers   the numbers from which closest number is searched
 *
 * @returns the number from numbers array that is closest to target. Returns undefined if
 *  target is undefined or numbers is empty or undefined
 */
export const findClosestNumber = (target, numbers) => {
  if (numbers === undefined || target === undefined || numbers.length === 0) {
    return undefined;
  }

  return numbers.reduce((a, b) => (
    (Math.abs(b - target) < Math.abs(a - target) ? b : a)));
};

export const isEmpty = (val) => val === undefined || val === null;

export default {
  safeNum,
  safeFloat,
  gtZeroOrNull,
  ensureArray,
  extendArray,
  DumpJSON,
  calculateAge,
  zeroPad,
  inRange,
  thenDelay,
  sumArrayLengths,
};
