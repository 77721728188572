import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import union from 'lodash/union';
import i18n from 'i18next-client';
import Picker from './picker';

import formatNumber from '../util/formatNumber';
import { handleScroll } from '../util/handleScroll';
import { getCustomerAvailableProducts } from '../redux/reducers/customerAvailableProductsReducer';
import { getSpouseAvailableProducts } from '../redux/reducers/spouseAvailableProductsReducer';
import {
  getCustomerProducts,
  getCustomerCompensation,
  getSpouseAge,
  getSpouseProducts,
  getSpouseCompensation,
  getCustomerCICompensation,
  getSpouseCICompensation,
  hasCustomer,
} from '../redux/reducers/selectedReducer';
import ProductContainer from '../products/productContainer';
import { defaultCompensation } from '../models/compensationModel';

class ProductPicker extends React.Component {
  static displayName = 'ProductPicker';

  static propTypes = {
    customerProducts: PropTypes.array,
    spouseProducts: PropTypes.array,
    customerCompensation: PropTypes.number,
    spouseCompensation: PropTypes.number,
    customerCICompensation: PropTypes.number,
    spouseCICompensation: PropTypes.number,
  };

  handleScroll(event) {
    handleScroll(event, this.scrollElement);
  }

  render() {
    const {
      customerProducts,
      spouseProducts,
      customerCompensation,
      spouseCompensation,
      customerCICompensation,
      spouseCICompensation,
      hasCustomer,
    } = this.props;
    const products = union(customerProducts, spouseProducts);
    const compensation = customerCompensation || spouseCompensation || defaultCompensation;
    const ciCompensation = hasCustomer ? customerCICompensation : spouseCICompensation;
    let text;
    if (products.length === 0) {
      text = i18n.t('productSelectorLabelNoProducts');
    } else if (products.length === 1 && products[0] === 'LIFE') {
      text = i18n.t('productSelectorOnlyLife', {
        amount: formatNumber(compensation),
      });
    } else if (products.length === 1 && products[0] === 'CI') {
      text = i18n.t('productSelectorOnlyCi', {
        amount: formatNumber(ciCompensation),
      });
    } else {
      text = i18n.t('productSelectorLabel', { count: products.length });
    }

    return (
      <Picker
        onWheel={(event) => this.handleScroll(event)}
        value={text}
        className="attachRight productPicker"
        role="form"
        {...this.props}
      >
        <ProductContainer
          ref={(self) => (this.scrollElement = self)}
          context="calculator"
          key="calculatorProductContainer"
        />
      </Picker>
    );
  }
}

const mapStateToProps = (state) => ({
  spouseAge: getSpouseAge(state),
  customerProducts: getCustomerProducts(state),
  spouseProducts: getSpouseProducts(state),
  customerCompensation: getCustomerCompensation(state),
  spouseCompensation: getSpouseCompensation(state),
  customerCICompensation: getCustomerCICompensation(state),
  spouseCICompensation: getSpouseCICompensation(state),
  customerAvailableProducts: getCustomerAvailableProducts(state),
  spouseAvailableProducts: getSpouseAvailableProducts(state),
  hasCustomer: hasCustomer(state),
});

export default connect(mapStateToProps)(ProductPicker);
