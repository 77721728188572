import * as actionTypes from '../actionTypes';
import NeedCalculatorModel from '../../models/needCalculatorModel';

export const setNeedField = (field) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_NEED_FIELD,
    field,
  });
  dispatch(calculateNeed());
};

export const calculateNeed = () => (dispatch, getState) => {
  const result = new NeedCalculatorModel(getState().need).calculateNeed();
  dispatch({
    type: actionTypes.SET_RESULT,
    result,
  });
};
