import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import PriceDisplay from '../common/priceDisplay';

import i18n from '../localization/i18n-wrapper';
import { setBillsPerYear } from '../redux/actions/selectedActions';
import { getBillsPerYear } from '../redux/reducers/selectedReducer';
import { getDiscountedBillingLot } from '../redux/reducers/priceReducer';
import purchaseApi from '../api/purchaseApi';

class BillingLots extends React.Component {
  static displayName = 'BillingLots';

  static propTypes = {
    billsPerYear: PropTypes.number.isRequired,
    discountedBillingLot: PropTypes.number.isRequired,
    setBillsPerYear: PropTypes.func.isRequired,
    postState: PropTypes.func.isRequired,
  };

  handleChange = (e) => {
    if (e.target.checked) {
      this.props.setBillsPerYear(e.target.value);
      this.props.postState();
    }
  };

  render = () => {
    const { billsPerYear, discountedBillingLot } = this.props;

    const content = [1, 2, 4].map((num) => (
      <div key={num} className="radio">
        <label>
          <input type="radio" value={num} onChange={this.handleChange} checked={billsPerYear == num} />
          {num}
        </label>
      </div>
    ));
    return (
      <div className="billAmounts">
        <div className="billsPerYear">
          <div className="form-group">
            <label className="control-label">{i18n.t('billsPerYear')}</label>
            <div className="form-entry">{content}</div>
          </div>
        </div>
        <div className="billingLot">
          <div className="form-group">
            <label className="control-label">{i18n.t('billingLot')}</label>
            <PriceDisplay price={discountedBillingLot} priceDisplayUnit="€" decimals />
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  billsPerYear: getBillsPerYear(state),
  discountedBillingLot: getDiscountedBillingLot(state),
});

const mapDispatchToProps = (dispatch) => ({
  setBillsPerYear: (b) => dispatch(setBillsPerYear(b)),
  postState: () => purchaseApi.postState(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BillingLots);
