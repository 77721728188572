import {
  createStore, combineReducers, applyMiddleware, compose,
} from 'redux';
import thunk from 'redux-thunk';

import selectedReducer from './reducers/selectedReducer';
import purchaseReducer from './reducers/purchaseReducer';
import dataReducer from './reducers/dataReducer';
import priceReducer from './reducers/priceReducer';
import customerAvailableProductsReducer from './reducers/customerAvailableProductsReducer';
import spouseAvailableProductsReducer from './reducers/spouseAvailableProductsReducer';
import needReducer from './reducers/needReducer';
import analyticsMiddleware from './middleware/analyticsMiddleware';

// Singleton
export default class HvkStore {
  static instance = undefined;

  store = undefined;

  static getInstance(initialState = {}) {
    if (!HvkStore.instance) {
      const hvkStore = new HvkStore(initialState);
      HvkStore.instance = hvkStore;
    }

    return HvkStore.instance;
  }

  getState() {
    return (this.store && this.store.getState()) || {};
  }

  // private constructor. Do not call from outside this class
  constructor(initialState) {
    this.store = this.createAndInitializeStore(initialState);
    HvkStore.instance = this;
    return HvkStore.instance;
  }

  createAndInitializeStore(initialState) {
    const combinedReducers = combineReducers({
      selected: selectedReducer,
      purchase: purchaseReducer,
      data: dataReducer,
      price: priceReducer,
      customerAvailableProducts: customerAvailableProductsReducer,
      spouseAvailableProducts: spouseAvailableProductsReducer,
      need: needReducer,
      //    routing: routerReducer,
    });
    const middleware = [
      //    routerMiddleware(browserHistory),
      thunk,
      analyticsMiddleware,
    ];

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    return createStore(
      combinedReducers,
      initialState,
      composeEnhancers(
        applyMiddleware(...middleware),
      ),
    );
  }
}
