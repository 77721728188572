import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import i18n from '../localization/i18n-wrapper';
import I18n from '../localization/I18n';

import PriceDisplay from '../common/priceDisplay';
import Info from '../common/info';

import {
  getNormalCoupleInsurancePerMonth,
  getNormalTotalAdultLifePerMonth,
  getNormalCustomerLifePerMonth,
} from '../redux/reducers/priceReducer';
import {
  isCustomerLifeSelected, hasCustomer, hasSpouse, isCoupleInsurance,
} from '../redux/reducers/selectedReducer';
import { setCoupleInsurance } from '../redux/actions/selectedActions';

import purchaseApi from '../api/purchaseApi';
import { isSpouseDifferent } from '../redux/reducers/purchaseReducer';

class CoupleInsurance extends React.Component {
  static displayName = 'CoupleInsurance';

  static propTypes = {
    context: PropTypes.string,
    coupleInsurance: PropTypes.bool,
    couplePricePerMonth: PropTypes.number.isRequired,
    customerLifeSelected: PropTypes.bool,
    spouseLifeSelected: PropTypes.bool,
    spouseDifferent: PropTypes.bool.isRequired,
    coupleInsurancePerMonth: PropTypes.number,
    lifePerMonth: PropTypes.number,
    customerLifePerMonth: PropTypes.number,
    spouseLifePerMonth: PropTypes.number,
    hasCustomer: PropTypes.bool.isRequired,
    hasSpouse: PropTypes.bool.isRequired,
    setCoupleInsurance: PropTypes.func.isRequired,
    postState: PropTypes.func.isRequired,
  };

  handleChange = (event) => {
    event.stopPropagation();
    const value = event.currentTarget.value === 'couple';
    this.props.setCoupleInsurance(value);
    this.props.postState();
  };

  render() {
    const {
      hasCustomer, hasSpouse, customerLifeSelected, coupleInsurance, spouseDifferent,
    } = this.props;
    const { couplePricePerMonth, lifePerMonth, customerLifePerMonth } = this.props;
    const { context } = this.props;
    const isDisabled = !hasCustomer || !hasSpouse || !customerLifeSelected;
    let couplePriceInfoHeader = [];
    let separatePriceInfoHeader = [];
    let separatedPrice = lifePerMonth;

    const useDetailedPrices = context !== 'calculator' && spouseDifferent;
    if (useDetailedPrices) {
      couplePriceInfoHeader = <h4>{i18n.t('coupleInsurancePriceInfoHeader')}</h4>;
      separatePriceInfoHeader = <h4>{i18n.t('separateInsurancePriceInfoHeader')}</h4>;
      separatedPrice = customerLifePerMonth;
    }

    return (
      <span className="coupleInsuranceSelector">
        <div className="insuranceType form-group">
          <div className="separateInsurances">
            <label>
              <input
                type="radio"
                data-analytics="separateInsurances"
                disabled={isDisabled}
                value="separate"
                checked={customerLifeSelected && !coupleInsurance}
                onChange={this.handleChange}
              />
              <div className="titleContainer">{i18n.t('separateInsurancesTitle')}</div>
              <p>{i18n.t('separateInsurancesInfo')}</p>
              <div>
                {separatePriceInfoHeader}
                <PriceDisplay
                  className="productPrice"
                  price={separatedPrice}
                  priceDisplayUnit={i18n.t('priceDisplayUnit')}
                  decimals
                />
              </div>
            </label>
          </div>
          <div className="infoContainer separateInfoContainer">
            <Info>
              <I18n i18nKey="separateInsurancesInfoKey" />
            </Info>
          </div>
          <div className="coupleInsurance">
            <label>
              <input
                type="radio"
                data-analytics="coupleInsurance"
                disabled={isDisabled}
                value="couple"
                checked={customerLifeSelected && coupleInsurance}
                onChange={this.handleChange}
              />
              <div className="titleContainer">{i18n.t('coupleInsuranceTitle')}</div>
              <p>{i18n.t('coupleInsuranceInfo')}</p>
              <div>
                {couplePriceInfoHeader}
                <PriceDisplay
                  className="productPrice"
                  price={couplePricePerMonth}
                  priceDisplayUnit={i18n.t('priceDisplayUnit')}
                  decimals
                />
              </div>
            </label>
          </div>
          <div className="infoContainer coupleInfoContainer">
            <Info>
              <I18n i18nKey="coupleInsuranceInfoKey" />
            </Info>
          </div>
        </div>
      </span>
    );
  }
}

const mapStateToProps = (state) => ({
  coupleInsurance: isCoupleInsurance(state),
  couplePricePerMonth: getNormalCoupleInsurancePerMonth(state),
  lifePerMonth: getNormalTotalAdultLifePerMonth(state),
  customerLifePerMonth: getNormalCustomerLifePerMonth(state),
  coupleInsurancePerMonth: getNormalCoupleInsurancePerMonth(state),
  customerLifeSelected: isCustomerLifeSelected(state),
  hasCustomer: hasCustomer(state),
  hasSpouse: hasSpouse(state),
  spouseDifferent: isSpouseDifferent(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCoupleInsurance: (value) => dispatch(setCoupleInsurance(value)),
  postState: () => purchaseApi.postState(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoupleInsurance);
