import * as React from 'react';
import * as PropTypes from 'prop-types';
import Modal from 'react-modal';
import i18n from '../localization/i18n-wrapper';

import BackButton from '../common/backButton';
import OkButton from '../common/continueButton';

export default class OptionalQuestionConfirmationDialog extends React.Component {
  static displayName = 'HealthYesNoQuestion';

  static propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    okAction: PropTypes.func,
    cancelAction: PropTypes.func,
  };

  state = {
    showDialog: true,
  };

  handleCancel = () => {
    const { cancelAction } = this.props;
    if (cancelAction) {
      cancelAction();
    }
    this.setState({ showDialog: false });
  };

  handleOk = () => {
    const { okAction } = this.props;
    if (okAction) {
      okAction();
    }
    this.setState({ showDialog: false });
  };

  render() {
    const { title, description } = this.props;

    return (
      <Modal isOpen={this.state.showDialog} style={{ content: { background: {}, border: 0 } }}>
        <div className="confirmation">
          <div className="dialog">
            <h2>{i18n.t(title)}</h2>
            <p>{i18n.t(description)}</p>
            <div className="button-group">
              <BackButton className="popup-button" onClick={this.handleCancel} />
              <OkButton className="popup-button" onClick={this.handleOk} disabled={false} />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
