const $ = require('jquery');

window.jQuery = $;

require('bootstrap/js/transition');
require('bootstrap/js/collapse');

(function () {
  function toggleAccordion() {
    const $el = $(this);

    if ($el.hasClass('closed')) {
      $el
        .removeClass('closed')
        .addClass('open')
        .parents('.accordion-container:first')
        .find('.accordion-togglable:first')
        .slideDown();
    } else {
      $el
        .addClass('closed')
        .removeClass('open')
        .parents('.accordion-container:first')
        .find('.accordion-togglable:first')
        .slideUp();
    }
  }

  $('.accordion-toggler').click(toggleAccordion);
}());
