import * as React from 'react';
import * as PropTypes from 'prop-types';
import * as classNames from 'classnames';

import i18n from 'i18next-client';

export default class extends React.Component {
  static displayName = 'ContinueButton';

  static propTypes = {
    onClick: PropTypes.func.isRequired,
    role: PropTypes.oneOf(['FI', 'SE']).isRequired,
    selected: PropTypes.bool,
  };

  static defaultProps = {
    clicked: false,
  };

  render() {
    const { onClick, selected, role } = this.props;

    const classes = {
      btn: true,
      'btn-active': selected,
    };

    const text = role === 'FI' ? i18n.t('languageOptionFI') : i18n.t('languageOptionSE');

    return (
      <span className="yesNoButton">
        <button className={classNames(classes)} onClick={onClick}>
          {text}
        </button>
      </span>
    );
  }
}
