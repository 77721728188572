import * as actionTypes from '../actionTypes';

export const setOrganizationList = (organizations) => ({
  type: actionTypes.SET_ORGANIZATION_LIST,
  organizations,
});

export const setOrganizationRedirects = (organizationRedirects) => ({
  type: actionTypes.SET_ORGANIZATION_REDIRECTS,
  organizationRedirects,
});
