import * as React from 'react';
import * as PropTypes from 'prop-types';

/** THIS COMPONENT IS NOT USED (TODO: REFACTOR ApprovalCheckboxQuestion) */

class Checkbox extends React.Component {
  static displayName = 'Checkbox';

  static propTypes = {
    value: PropTypes.bool.isRequired,
    marker: PropTypes.string,
    children: PropTypes.any,
    onChange: PropTypes.func,
  };

  handleChange = (event) => {
    if (this.props.onChange) {
      this.props.onChange(event.target.checked);
    }
  };

  render = () => {
    const { value } = this.props;

    return (
      <div className="checkboxQuestion" data-marker={this.props.marker}>
        <input type="checkbox" checked={value} onChange={this.handleChange} />
        <p>{this.props.children}</p>
      </div>
    );
  };
}

export default Checkbox;
