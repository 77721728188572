import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import * as classNames from 'classnames';

import PriceDisplay from '../common/priceDisplay';

import i18n from '../localization/i18n-wrapper';
import { getCustomerQuestions, getSpouseQuestions, getPriceVisible } from '../redux/reducers/purchaseReducer';
import {
  getCustomerProducts,
  getSpouseProducts,
  getChildCount,
  hasCustomer,
  hasSpouse,
  customerHasProduct,
  spouseHasProduct,
  isMemberOfOrganization,
} from '../redux/reducers/selectedReducer';
import { getNormalTotalPerMonth } from '../redux/reducers/priceReducer';

class PriceBar extends React.Component {
  static displayName = 'PriceBar';

  static propTypes = {
    customerProducts: PropTypes.array.isRequired,
    spouseProducts: PropTypes.array.isRequired,
    totalPerMonth: PropTypes.number.isRequired,
    childCount: PropTypes.number.isRequired,
    priceVisible: PropTypes.bool,
    customerQuestions: PropTypes.array.isRequired,
    spouseQuestions: PropTypes.array.isRequired,
    hasCustomer: PropTypes.bool.isRequired,
    hasSpouse: PropTypes.bool.isRequired,
    hasCustomerLife: PropTypes.bool.isRequired,
    hasSpouseLife: PropTypes.bool.isRequired,
    hasCustomerCi: PropTypes.bool.isRequired,
    hasSpouseCi: PropTypes.bool.isRequired,
    memberOfOrganization: PropTypes.bool.isRequired,
  };

  onChange = () => {
    this.forceUpdate();
  };

  getPriceContent = () => {
    const content = [];
    const { totalPerMonth } = this.props;

    const lifeCount = this.getLifeProductCount();
    const ciCount = this.getCiProductCount();
    const accidentalCount = this.getAccidentalProductCount();

    const firstRowText = this.getText('priceBarNoLifeInsurance', 'priceBarLifeInsurance', lifeCount);
    const secondRowText = this.getOtherProductsText(ciCount, accidentalCount);

    content.push(
      <p key="text_lifeInsuranceText_additionalProductsText" className="col-xs-8" data-marker="pricebar-text">
        {firstRowText}
        <br />
        {' '}
        {secondRowText}
      </p>,
    );

    content.push(
      <PriceDisplay
        key={`priceDisplay_${totalPerMonth}`}
        className="col-xs-4 price"
        price={totalPerMonth}
        priceDisplayUnit={i18n.t('priceDisplayUnit')}
        decimals
      />,
    );

    return {
      content,
      hasAnyProduct: lifeCount + ciCount + accidentalCount > 0,
    };
  };

  getLifeProductCount() {
    const {
      hasCustomer, hasSpouse, hasCustomerLife, hasSpouseLife,
    } = this.props;
    const customerLife = hasCustomer && hasCustomerLife;
    const spouseLife = hasSpouse && hasSpouseLife;

    return (customerLife ? 1 : 0) + (spouseLife ? 1 : 0);
  }

  getCiProductCount = () => {
    const { hasCustomerCi, hasSpouseCi } = this.props;
    return 0 + (hasCustomerCi ? 1 : 0) + (hasSpouseCi ? 1 : 0);
  };

  getAccidentalProductCount = () => {
    const {
      hasCustomer, hasSpouse, customerProducts, spouseProducts, childCount,
    } = this.props;
    const productCount = (hasCustomer ? this.countAccidentals(customerProducts) : 0)
      + (hasSpouse ? this.countAccidentals(spouseProducts) : 0)
      + childCount;
    return productCount;
  };

  countAccidentals = (list) => {
    if (!list) {
      return 0;
    }
    return list.filter((product) => product !== 'LIFE' && product !== 'CI').length;
  };

  hasOnlyCiProducts = () => {
    const ciCount = this.getCiProductCount();
    const accidentalCount = this.getAccidentalProductCount();
    const lifeProductCount = this.getLifeProductCount();
    return ciCount > 0 && accidentalCount === 0 && lifeProductCount === 0;
  }

  getCustomerServiceInfo = () => {
    if (this.hasOnlyCiProducts()) {
      return 'priceBarErrorML';
    }

    const { memberOfOrganization } = this.props;

    return memberOfOrganization
      ? 'priceBarErrorIf'
      : 'priceBarErrorML';
  }

  getOtherProductsText = (ciCount, accidentalCount) => {
    const totalCount = ciCount + accidentalCount;
    const hasOnlyCi = ciCount > 0 && accidentalCount === 0;
    const hasOnlyAccidental = accidentalCount > 0 && ciCount === 0;

    let text;
    if (hasOnlyCi) {
      text = this.getText('priceBarAdditionalProductsEmpty', 'priceBarCiProducts', ciCount);
    } else if (hasOnlyAccidental) {
      text = this.getText('priceBarAdditionalProductsEmpty', 'priceBarAccidentalProducts', accidentalCount);
    } else {
      text = this.getText('priceBarAdditionalProductsEmpty', 'priceBarAdditionalProducts', totalCount);
    }
    return text;
  };

  getText = (noInsuranceText, insuranceCountText, count) => {
    if (count > 0) {
      return i18n.t(insuranceCountText, { count });
    }
    return i18n.t(noInsuranceText);
  };

  isFatalState = () => {
    const { customerQuestions, spouseQuestions } = this.props;

    const isNegativeAnswer = ({ answer }) => answer === false;

    // fatal state → one of the health questions has been answered with a negative answer
    return customerQuestions.some(isNegativeAnswer) || spouseQuestions.some(isNegativeAnswer);
  };

  render = () => {
    const { priceVisible } = this.props;

    if (!priceVisible) {
      return <div />;
    }
    const fatal = this.isFatalState();

    const classes = {};
    let content = [];

    const customerServiceInfo = this.getCustomerServiceInfo();
    if (fatal) {
      content.push(
        <p key="error" className="error col-xs-12" data-marker="pricebar-text">
          {i18n.t('priceBarError1')}
          <br />
          {i18n.t(customerServiceInfo)}
        </p>,
      );
      classes.warning = true;
    } else {
      const priceContent = this.getPriceContent();
      content = priceContent.content;

      if (!priceContent.hasAnyProduct) {
        classes.warning = true;
      }
    }

    return (
      <div id="priceBar" className={classNames(classes)}>
        <div className="container">
          <div className="row">{content}</div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  hasCustomer: hasCustomer(state),
  hasSpouse: hasSpouse(state),
  customerProducts: getCustomerProducts(state),
  spouseProducts: getSpouseProducts(state),
  hasCustomerLife: customerHasProduct(state, 'LIFE'),
  hasSpouseLife: spouseHasProduct(state, 'LIFE'),
  hasCustomerCi: customerHasProduct(state, 'CI'),
  hasSpouseCi: spouseHasProduct(state, 'CI'),
  customerQuestions: getCustomerQuestions(state),
  spouseQuestions: getSpouseQuestions(state),
  priceVisible: getPriceVisible(state),
  childCount: getChildCount(state),
  totalPerMonth: getNormalTotalPerMonth(state),
  memberOfOrganization: isMemberOfOrganization(state),
});

export default connect(mapStateToProps)(PriceBar);
