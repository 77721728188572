import * as React from 'react';
import * as PropTypes from 'prop-types';
import PriceDisplay from '../../common/priceDisplay';
import i18n from '../../localization/i18n-wrapper';

export class Selviytymisturva extends React.Component {
  static displayName = 'Selviytymisturva';

  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    description: PropTypes.string,
    person: PropTypes.object,
    amount: PropTypes.number,
    price: PropTypes.number.isRequired,
  };

  _createDetailRow = (label, value) => (
    <div key={label} className="insuranceDetail">
      <p className="name">{label}</p>
      <p className="value">{value}</p>
    </div>
  );

  _createHeader = (p) => {
    const content = [
      <label key={p.title} className="title">
        {p.title}
      </label>,
    ];
    if (p.description) {
      content.push(
        <p key={p.description} className="description">
          {p.description}
        </p>,
      );
    }
    return (
      <div key={p.title + p.description} className="header">
        {content}
      </div>
    );
  };

  render() {
    const p = this.props;
    const details = [];
    details.push(this._createHeader(p));
    if (p.amount) {
      details.push(this._createDetailRow(i18n.t('productDetailAmount'), p.amount));
    }
    if (!p.visible) {
      return <div />;
    }
    return (
      <div className="product">
        <div className="insuranceDetails">{details}</div>
        <PriceDisplay price={p.price} priceDisplayUnit={i18n.t('priceDisplayUnit')} decimals />
      </div>
    );
  }
}
