import * as actionTypes from '../actionTypes';
import {
  isMemberOfOrganization,
  hasCustomer,
  hasSpouse,
  defaultCompensation,
  getCustomerProducts,
  getSpouseProducts,
  defaultProducts,
  getOrganization,
  ciCompensations,
  getSpouseCompensation,
  getCustomerCompensation,
  getSelected,
  isSpouseSmoking,
  isCustomerSmoking,
  getCoupleInsuranceCompensation,
  isCoupleInsurance,
  getOrganizationId,
  getMaximumCompensation,
} from '../reducers/selectedReducer';
import { setCustomerAvailableProducts } from './customerAvailableProductsActions';
import { setSpouseAvailableProducts } from './spouseAvailableProductsActions';
import { getOrganizations } from '../reducers/dataReducer';
import { getCompensations } from '../../models/compensationModel';
import {
  setHealthQuestions,
  clearCustomerOptionalQuestionAnswers,
  clearSpouseOptionalQuestionAnswers,
  setCustomerOptionalQuestions,
  setSpouseOptionalQuestions,
  setApprovalQuestions,
  setSpouseVastusStatus,
  setSpousePresent,
} from './purchaseActions';
import { gtZeroOrNull, findClosestNumber, isEmpty } from '../../util/utils';
import { isSpouseDifferent, isSpousePresent } from '../reducers/purchaseReducer';
import { getCustomerAvailableProducts } from '../reducers/customerAvailableProductsReducer';
import { getSpouseAvailableProducts } from '../reducers/spouseAvailableProductsReducer';
import {
  nonOrgMandatumLife,
  getMaxCompensation,
  sampoGroupPersonnel,
} from '../../models/organizationModel';

export const setCustomerAge = (customerAge) => (dispatch, getState) => {
  dispatch({
    type: actionTypes.SET_CUSTOMER_AGE,
    customerAge,
  });
  if (customerAge) {
    dispatch({
      type: actionTypes.SET_CUSTOMER_AGE_PREVIOUS,
      customerAge,
    });
  }
  updateCustomerProducts(getState(), dispatch);
  dispatch(setCustomerAvailableProducts());
  dispatch(setCompensations());
  if (!customerAge && isSpouseDifferent(getState())) {
    dispatch(setSpouseDifferent(false));
  }
  if (!getState().customerCompensation) {
    initCustomerCompensation(dispatch, getState());
  }
  if (!customerAge) {
    dispatch(setCustomerOptionalQuestions([]));
  }
};
const updateCustomerProducts = (state, dispatch) => {
  const oldCustomerProducts = getCustomerProducts(state);
  if (oldCustomerProducts.length === 0) {
    const spouseOrDefaultProducts = getSpouseProducts(state) || defaultProducts;
    spouseOrDefaultProducts.forEach((p) => dispatch(addCustomerProduct(p)));
  } else if (!hasCustomer(state)) {
    oldCustomerProducts.forEach((p) => dispatch(removeCustomerProduct(p)));
  }
};

const spouseHasCompensation = (state) => !isEmpty(getSpouseCompensation(state));

export const setSpouseAge = (spouseAge) => (dispatch, getState) => {
  updateSpouseProducts(getState(), dispatch);
  dispatch({
    type: actionTypes.SET_SPOUSE_AGE,
    spouseAge,
  });

  if (!spouseHasCompensation(getState()) && spouseAge) {
    dispatch({
      type: actionTypes.SET_SPOUSE_COMPENSATION,
      spouseCompensation: getCustomerCompensation(getState()),
    });
  }
  if (spouseAge) {
    dispatch({
      type: actionTypes.SET_SPOUSE_AGE_PREVIOUS,
      spouseAge,
    });
  } else {
    dispatch(setSpouseProducts([])); // remove products when desecting spouse
    dispatch(setSpouseCompensation());
  }
  dispatch(setSpouseAvailableProducts());
  dispatch(setCompensations());
  dispatch(setSpouseSmoking(false));
  dispatch(setSpouseVastusStatus(false));
  if (!spouseAge && isSpouseDifferent(getState())) {
    dispatch(setSpouseDifferent(false));
  }
  if (!getState().spouseCompensation) {
    initSpouseCompensation(dispatch, getState());
  }
  if (!spouseAge) {
    dispatch(setSpouseOptionalQuestions([]));
  }
};
const updateSpouseProducts = (state, dispatch) => {
  const oldHasSpouse = hasSpouse(state);
  if (!oldHasSpouse) {
    const customerOrDefaultProducts = getCustomerProducts(state) || defaultProducts;
    customerOrDefaultProducts.forEach((p) => dispatch(addSpouseProduct(p)));
  } else if (!hasSpouse(state)) {
    const oldSpouseProducts = getSpouseProducts(state);
    oldSpouseProducts.forEach((p) => dispatch(removeSpouseProduct(p)));
  }
};

export const setCoupleInsurance = (coupleInsurance) => (dispatch, getState) => {
  dispatch({
    type: actionTypes.SET_COUPLE_INSURANCE,
    coupleInsurance,
  });
  if (coupleInsurance) {
    dispatch({
      type: actionTypes.SET_SPOUSE_COMPENSATION,
      spouseCompensation: getCustomerCompensation(getState()), // harmonize with customer compensation
    });
  }
};

export const setOrganization = (organizationId) => (dispatch, getState) => {
  const memberOfOrganization = isMemberOfOrganization(getState());
  const organizations = getOrganizations(getState());
  dispatch({
    type: actionTypes.SET_ORGANIZATION,
    organizationId,
    memberOfOrganization,
    organizations,
  });
  if (memberOfOrganization && organizationId === nonOrgMandatumLife) {
    dispatch(setMemberOfOrganization(false));
  }
  if (organizationId === sampoGroupPersonnel) {
    dispatch(clearChildren());
  }

  const maxCompensation = getMaxCompensation(organizationId, organizations, memberOfOrganization);
  const currentCompensation = getCustomerCompensation(getState());
  const spouseCompensation = getSpouseCompensation(getState());

  if (currentCompensation > maxCompensation) {
    dispatch(setCustomerCompensation(maxCompensation));
  }

  if (spouseCompensation > maxCompensation) {
    dispatch(setSpouseCompensation(maxCompensation));
  }

  dispatch(setCustomerAvailableProducts());
  dispatch(setSpouseAvailableProducts());
  dispatch(setCompensations());
};

export const setMemberOfOrganization = (memberOfOrganization) => (dispatch, getState) => {
  dispatch({
    type: actionTypes.SET_MEMBER_OF_ORGANIZATION,
    memberOfOrganization,
  });
  dispatch(setApprovalQuestions());
  dispatch(setCustomerAvailableProducts());
  dispatch(setSpouseAvailableProducts());
  dispatch(setSpousePresent(true));
  if (!memberOfOrganization) {
    dispatch(clearChildren());
    dispatch(setSpouseDifferent(false));

    const organizations = getOrganizations(getState());
    const currentCompensation = getCustomerCompensation(getState());
    const spouseCompensation = getSpouseCompensation(getState());
    const maxCompensation = getMaxCompensation(nonOrgMandatumLife, organizations, memberOfOrganization);
    if (currentCompensation > maxCompensation) {
      dispatch(setCustomerCompensation(maxCompensation));
    }
    if (spouseCompensation > maxCompensation) {
      dispatch(setSpouseCompensation(maxCompensation));
    }
  }
};

export const toggleMemberOfOrganization = () => (dispatch, getState) => {
  const current = isMemberOfOrganization(getState());
  dispatch(setMemberOfOrganization(!current));
};

export const setProducts = (products) => (dispatch, getState) => {
  const customerProducts = hasCustomer(getState()) ? products.customerProducts : [];
  const spouseProducts = hasSpouse(getState()) ? products.spouseProducts : [];
  dispatch(setCustomerProducts(customerProducts));
  dispatch(setSpouseProducts(spouseProducts));
};

export const customerHasProduct = (product) => (_dispatch, getState) => {
  const state = getState();
  return getCustomerProducts(state).includes(product);
};

export const spouseHasProduct = (product) => (_dispatch, getState) => {
  const state = getState();
  return getSpouseProducts(state).includes(product);
};

export const setCustomerProducts = (customerProducts) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_CUSTOMER_PRODUCTS,
    customerProducts,
  });
  dispatch(setHealthQuestions());
};

export const setSpouseProducts = (spouseProducts) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SPOUSE_PRODUCTS,
    spouseProducts,
  });
  dispatch(setHealthQuestions());
};

export const setCompensations = (compensationData) => (dispatch, getState) => {
  const state = getState();
  const organization = getOrganization(state);
  const compensations = getCompensations(organization);
  const { customerCompensation, spouseCompensation } = {
    ...getSelected(state),
    ...compensationData,
  };

  const needToUpdateCustomerCompensation = hasCustomer(state) && !compensations.includes(customerCompensation);
  const needToUpdateSpouseCompensation = hasSpouse(state) && !compensations.includes(spouseCompensation);
  const newCustomerCompensation = needToUpdateCustomerCompensation
    ? findClosestNumber(customerCompensation, compensations)
    : customerCompensation;
  const newSpouseCompensation = needToUpdateSpouseCompensation
    ? findClosestNumber(spouseCompensation, compensations)
    : spouseCompensation;

  dispatch({
    type: actionTypes.SET_COMPENSATION,
    compensations,
    customerCompensation: newCustomerCompensation,
    spouseCompensation: newSpouseCompensation,
  });
};

export const setCustomerCompensation = (c) => (dispatch, getState) => {
  const customerCompensation = hasCustomer(getState()) ? gtZeroOrNull(c) : null;
  dispatch({
    type: actionTypes.SET_CUSTOMER_COMPENSATION,
    customerCompensation,
  });
};

export const setSpouseCompensation = (c) => (dispatch, getState) => {
  const spouseCompensation = hasSpouse(getState()) ? gtZeroOrNull(c) : null;
  dispatch({
    type: actionTypes.SET_SPOUSE_COMPENSATION,
    spouseCompensation,
  });
};

export const setChildren = (children) => (dispatch, getState) => {
  const oldState = getState();
  const childCountDecreases = children.childrenFrom0to9 < oldState.selected.childrenFrom0to9
    || children.childrenFrom10to12 < oldState.selected.childrenFrom10to12
    || children.childrenFrom13to17 < oldState.selected.childrenFrom13to17;
  dispatch({
    type: actionTypes.SET_CHILDREN,
    ...children,
    resetDetails: childCountDecreases,
  });
  dispatch(setHealthQuestions());
  dispatch(setApprovalQuestions());
};

export const clearChildren = () => ({
  type: actionTypes.SET_CHILDREN,
  childrenFrom0to9: 0,
  childrenFrom10to12: 0,
  childrenFrom13to17: 0,
  resetDetails: true,
});

export const setBillsPerYear = (billsPerYear) => {
  const numberValue = parseInt(billsPerYear, 10);
  return {
    type: actionTypes.SET_BILLS_PER_YEAR,
    billsPerYear: numberValue,
  };
};

export const setCampaignDescription = (campaignDescription) => ({
  type: actionTypes.SET_CAMPAIGN_DESCRIPTION,
  campaignDescription,
});

export const setCampaignCodeValid = (campaignCodeValid) => ({
  type: actionTypes.SET_CAMPAIGN_CODE_VALID,
  campaignCodeValid,
});

export const setCampaignActive = (campaignActive) => ({
  type: actionTypes.SET_CAMPAIGN_ACTIVE,
  campaignActive,
});

export const toggleSpouseDifferent = () => (dispatch, getState) => {
  const { spouseDifferent } = getState().purchase;
  dispatch(setSpouseDifferent(!spouseDifferent));
};

export const setSpouseDifferent = (spouseDifferent) => (dispatch, getState) => {
  dispatch({
    type: actionTypes.SET_SPOUSE_DIFFERENT,
    spouseDifferent,
  });
  const state = getState();
  const resetSpouseLikeCustomer = !spouseDifferent && hasSpouse(state) && hasCustomer(state);
  if (resetSpouseLikeCustomer) {
    dispatch(setSpouseCompensation(getCustomerCompensation(state)));
    dispatch(setSpouseProducts(getCustomerProducts(state)));
  }
};

export const removeCustomerProduct = (product) => (dispatch, getState) => {
  const newProducts = getState().selected.customerProducts.filter((value) => value !== product);
  dispatch({
    type: actionTypes.REMOVE_CUSTOMER_PRODUCT,
    newProducts,
  });
  dispatch(setHealthQuestions());
  dispatch(setApprovalQuestions());
};

export const removeSpouseProduct = (product) => (dispatch, getState) => {
  const newProducts = getState().selected.spouseProducts.filter((value) => value !== product);
  dispatch({
    type: actionTypes.REMOVE_SPOUSE_PRODUCT,
    newProducts,
  });
  dispatch(setSpousePresent(true));
  dispatch(setHealthQuestions());
  dispatch(setApprovalQuestions());
};

export const removeAllCustomerProducts = () => (dispatch) => {
  dispatch({
    type: actionTypes.REMOVE_ALL_CUSTOMER_PRODUCTS,
  });
  dispatch(setHealthQuestions());
  dispatch(setApprovalQuestions());
};

export const removeAllSpouseProducts = () => (dispatch) => {
  dispatch({
    type: actionTypes.REMOVE_ALL_SPOUSE_PRODUCTS,
  });
  dispatch(setHealthQuestions());
  dispatch(setApprovalQuestions());
};

export const addCustomerProduct = (product) => (dispatch, getState) => {
  const state = getState();
  if (getCustomerProducts(state).includes(product)) {
    console.trace(`Attempt to add customer product ${product} which already exists`); // eslint-disable-line no-console
  } else {
    const availableProducts = getCustomerAvailableProducts(state);
    if (availableProducts.includes(product)) {
      const newProducts = getCustomerProducts(state).slice();
      newProducts.splice(newProducts.length, 0, product);
      dispatch({
        type: actionTypes.ADD_CUSTOMER_PRODUCT,
        newProducts,
      });
      if (product === 'LIFE') {
        initCustomerCompensation(dispatch, state);
      }
      dispatch(setHealthQuestions());
      dispatch(setApprovalQuestions());
      if (product === 'CI') {
        dispatch(clearCustomerOptionalQuestionAnswers());
        const smoking = isCustomerSmoking(state);
        const smokingSet = smoking === true || smoking === false;
        if (!smokingSet) {
          dispatch(setCustomerSmoking(false));
        }
      }
    }
  }
};

export const addSpouseProduct = (product) => (dispatch, getState) => {
  const state = getState();
  if (getSpouseProducts(state).includes(product)) {
    console.trace(`Attempt to add spouse product ${product} which already exists`); // eslint-disable-line no-console
  } else {
    const availableProducts = getSpouseAvailableProducts(state);
    if (availableProducts.includes(product)) {
      const newProducts = getSpouseProducts(state).slice();
      newProducts.splice(newProducts.length, 0, product);

      // cannot add Mandatun Life products if spouse is not present
      const isMLproduct = product !== 'CI' || (product !== 'LIFE' && !isMemberOfOrganization(state));
      const isPossibleToAdd = isSpousePresent(state) !== false || !isMLproduct;

      if (isPossibleToAdd) {
        dispatch({
          type: actionTypes.ADD_SPOUSE_PRODUCT,
          newProducts,
        });
        dispatch(setSpousePresent(true));
        if (product === 'LIFE') {
          initSpouseCompensation(dispatch, state);
          if (isCoupleInsurance(state)) {
            const coupleCompensation = getCoupleInsuranceCompensation(state);
            const maxCompensation = getMaximumCompensation(state);
            const compensation = coupleCompensation <= maxCompensation
              ? coupleCompensation
              : maxCompensation;

            dispatch(setSpouseCompensation(compensation));
            dispatch(setCustomerCompensation(compensation));
          }
        }
        dispatch(setHealthQuestions());
        dispatch(setApprovalQuestions());
        if (product === 'CI') {
          dispatch(clearSpouseOptionalQuestionAnswers());
          const smoking = isSpouseSmoking(state);
          const smokingSet = smoking === true || smoking === false;
          if (!smokingSet) {
            dispatch(setSpouseSmoking(false));
          }
        }
      }
    }
  }
};

export const setCustomerSmoking = (smoking) => ({
  type: actionTypes.SET_CUSTOMER_SMOKING,
  smoking,
});

export const setSpouseSmoking = (smoking) => ({
  type: actionTypes.SET_SPOUSE_SMOKING,
  smoking,
});

export const setCustomerCICompensation = (customerCICompensation) => ({
  type: actionTypes.SET_CUSTOMER_CI_COMPENSATION,
  customerCICompensation: parseInt(customerCICompensation, 10),
});

export const setSpouseCICompensation = (spouseCICompensation) => ({
  type: actionTypes.SET_SPOUSE_CI_COMPENSATION,
  spouseCICompensation: parseInt(spouseCICompensation, 10),
});

export const setCICompensations = () => ({
  type: actionTypes.SET_CI_COMPENSATIONS,
  ciCompensations,
});

const initCustomerCompensation = (dispatch, state) => {
  const compensation = getCustomerCompensation(state) || getSpouseCompensation(state) || defaultCompensation;
  dispatch(setCustomerCompensation(compensation));
};

const initSpouseCompensation = (dispatch, state) => {
  const compensation = getSpouseCompensation(state) || getCustomerCompensation(state) || defaultCompensation;
  dispatch(setSpouseCompensation(compensation));
};
