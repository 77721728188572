import { ensureArray } from '../util/utils';

export const defaultOrg = 90; // organization ID for 'valitse järjestösi' placeholder
export const sampoGroupPersonnel = 206;
export const nonOrgMandatumLife = 1000; // organization ID for cases where customer is not member or any organization

export const findOrganization = (organizationId, organizations) => ensureArray(organizations).find((o) => o.id === organizationId);

export const getDefaultOrganization = (organizations) => {
  const defaultOrganization = ensureArray(organizations).find((o) => o.id === defaultOrg);
  return defaultOrganization;
};

export const getMandatumLifeOrganization = (organizations) => {
  const mandatumLifeOrganization = ensureArray(organizations).find((o) => o.id === nonOrgMandatumLife);
  return mandatumLifeOrganization;
};

// Return real organization name - or not a member of organization
export const getEffectiveOrganization = (org, memberOfOrganization, organizations) => {
  if (memberOfOrganization === false || org === undefined || org.id === defaultOrg) {
    return getMandatumLifeOrganization(organizations);
  }
  return org;
};

export const getProduct = (organization, productName) => {
  let product;
  if (organization && organization.products) {
    product = organization.products.find((p) => p.productType === productName);
  }
  return product;
};

export const getMaxCompensation = (organizationId, organizations, memberOfOrganization) => {
  const organization = memberOfOrganization
    ? findOrganization(organizationId, organizations)
    : getMandatumLifeOrganization(organizations);
  return getProduct(organization, 'LIFE').maxCompensation;
};
