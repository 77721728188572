import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import i18n from '../localization/i18n-wrapper';
import PriceDisplay from '../common/priceDisplay';

const ThankYouMonthlyPrice = function (props) {
  const yearlyPrice = typeof props.monthlyPrice !== 'undefined' ? (props.monthlyPrice * 12).toFixed(2) : 0;
  const monthlyPrice = typeof props.monthlyPrice !== 'undefined' ? props.monthlyPrice : 0;
  const { priceInfoKey } = props;

  return (
    <div className="thankYouCalcPrice">
      <PriceDisplay price={monthlyPrice} priceDisplayUnit={i18n.t('priceDisplayUnit')} decimals />
      <p id="totalPrice">
        Yht.
        {yearlyPrice}
        €/vuosi
      </p>
      <p>{i18n.t(priceInfoKey)}</p>
    </div>
  );
};

ThankYouMonthlyPrice.propTypes = {
  getMonthlyPrice: PropTypes.func.isRequired,
  priceInfoKey: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  monthlyPrice: ownProps.getMonthlyPrice(state),
});

export default connect(mapStateToProps)(ThankYouMonthlyPrice);
