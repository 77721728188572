import get from 'lodash/get';

import * as actionTypes from '../actionTypes';

const initialState = {
  discountedPrice: {},
  normalPrice: {},
};

const price = (state = initialState, action) => {
  switch (action && action.type) {
    case actionTypes.SET_PRICES: {
      const { prices } = action;
      return {
        ...state,
        ...prices,
      };
    }
    default: {
      return state;
    }
  }
};

export default price;

// selectors

export const getNormalTotalPerMonth = (state) => get(state, 'price.normalPrice.totalPerMonth', 0.0);
export const getTotalPerMonthNoOrg = (state) => {
  // TEMPORARY FIX FOR ZRO VALUE NO ORG PRICES
  // return get(state, 'price.totalPerMonthNoOrg.totalPerMonth', 0.0);
  let totalPerMonthNoOrg = get(state, 'price.totalPerMonthNoOrg.totalPerMonth', 0.0);
  const normalPrice = getNormalTotalPerMonth(state);
  if (totalPerMonthNoOrg < normalPrice) {
    totalPerMonthNoOrg = normalPrice;
  }
  return totalPerMonthNoOrg;
};
export const getNormalAdultPerMonth = (state) => get(state, 'price.normalPrice.totalAdultInsurancePrices.totalPerMonth');
export const getNormalCoupleInsurancePerMonth = (state) => get(state, 'price.normalPrice.totalAdultInsurancePrices.coupleInsurancePerMonth');
export const getNormalCustomerInsurancePrices = (state) => get(state, 'price.normalPrice.customerInsurancePrices');
export const getNormalSpouseInsurancePrices = (state) => get(state, 'price.normalPrice.spouseInsurancePrices');
export const getNormalTotalSelectedAdditionalProductsPerMonth = (state) => get(state, 'price.normalPrice.totalAdultInsurancePrices.totalSelectedAdditionalProductsPerMonth');

export const getNormalChildInsurancePrices = (state) => get(state, 'price.normalPrice.childInsurancePrices');
export const getNormalChildFrom0To9PerMonth = (state) => get(state, 'price.normalPrice.childInsurancePrices.from0To9PerMonth');
export const getNormalChildFrom10To12PerMonth = (state) => get(state, 'price.normalPrice.childInsurancePrices.from10To12PerMonth');
export const getNormalChildFrom13To17PerMonth = (state) => get(state, 'price.normalPrice.childInsurancePrices.from13To17PerMonth');

export const getNormalPrices = (state) => get(state, 'price.normalPrice');

export const getDiscountedTotalPerMonth = (state) => get(state, 'price.discountedPrice.totalPerMonth', 0.0);
export const getDiscountedPrices = (state) => get(state, 'price.discountedPrice');
export const getDiscountedBillingLot = (state) => get(state, 'price.discountedPrice.billingLot');

export const getNormalTotalAdultLifePerMonth = (state) => get(state, 'price.normalPrice.totalAdultInsurancePrices.lifePerMonth');
export const getNormalCustomerLifePerMonth = (state) => get(state, 'price.normalPrice.customerInsurancePrices.lifePerMonth');
export const getNormalSpouseLifePerMonth = (state) => get(state, 'price.normalPrice.spouseInsurancePrices.lifePerMonth');

export const getNormalTotalAdultDabPerMonth = (state) => get(state, 'price.normalPrice.totalAdultInsurancePrices.dabPerMonth');
export const getNormalCustomerDabPerMonth = (state) => get(state, 'price.normalPrice.customerInsurancePrices.dabPerMonth');
export const getNormalSpouseDabPerMonth = (state) => get(state, 'price.normalPrice.spouseInsurancePrices.dabPerMonth');

export const getNormalTotalAdultDaePerMonth = (state) => get(state, 'price.normalPrice.totalAdultInsurancePrices.daePerMonth');
export const getNormalCustomerDaePerMonth = (state) => get(state, 'price.normalPrice.customerInsurancePrices.daePerMonth');
export const getNormalSpouseDaePerMonth = (state) => get(state, 'price.normalPrice.spouseInsurancePrices.daePerMonth');

export const getNormalTotalAdultDaiPerMonth = (state) => get(state, 'price.normalPrice.totalAdultInsurancePrices.daiPerMonth');
export const getNormalCustomerDaiPerMonth = (state) => get(state, 'price.normalPrice.customerInsurancePrices.daiPerMonth');
export const getNormalSpouseDaiPerMonth = (state) => get(state, 'price.normalPrice.spouseInsurancePrices.daiPerMonth');

export const getNormalTotalAdultDalPerMonth = (state) => get(state, 'price.normalPrice.totalAdultInsurancePrices.dalPerMonth');
export const getNormalCustomerDalPerMonth = (state) => get(state, 'price.normalPrice.customerInsurancePrices.dalPerMonth');
export const getNormalSpouseDalPerMonth = (state) => get(state, 'price.normalPrice.spouseInsurancePrices.dalPerMonth');

export const getNormalTotalAdultCiPerMonth = (state) => get(state, 'price.normalPrice.totalAdultInsurancePrices.ciPerMonth');
export const getNormalCustomerCiPerMonth = (state) => get(state, 'price.normalPrice.customerInsurancePrices.ciPerMonth');
export const getNormalSpouseCiPerMonth = (state) => get(state, 'price.normalPrice.spouseInsurancePrices.ciPerMonth');

// TODO: replace this more specific selectors
export const getPrice = (state) => state.price;
