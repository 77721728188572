import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import range from 'lodash/range';

import I18n from '../localization/I18n';
import i18n from '../localization/i18n-wrapper';

import Info from '../common/info';

import { getCustomerAge } from '../redux/reducers/selectedReducer';
import { setCustomerAge } from '../redux/actions/selectedActions';
import { postState } from '../api/purchaseApi';

const ThankYouAge = function (props) {
  const handleAgeChange = (event) => {
    event.stopPropagation();
    const newAge = event.target.value;
    props.updateCustomerAge(newAge);
    props.postState();
  };

  const { minAge, maxAge, infoKey } = props;
  const ages = range(minAge, maxAge, 1).map((age) => <option key={age}>{age}</option>);
  const { customerAge } = props;

  return (
    <div className="form-group">
      <label className="control-label" htmlFor="customerAgeSelect">
        {i18n.t('thankYouAge')}
      </label>
      <div className="form-entry">
        <select
          className="form-control"
          id="customerAgeSelect"
          onChange={handleAgeChange}
          value={customerAge}
        >
          {ages}
        </select>
      </div>
      <div className="infoContainer">
        <Info>
          <I18n i18nKey={infoKey} />
        </Info>
      </div>
    </div>
  );
};

ThankYouAge.displayName = 'ThankYouAge';

ThankYouAge.propTypes = {
  customerAge: PropTypes.number.isRequired,
  updateCustomerAge: PropTypes.func.isRequired,
  postState: PropTypes.func.isRequired,
  minAge: PropTypes.number.isRequired,
  maxAge: PropTypes.number.isRequired,
  infoKey: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  customerAge: getCustomerAge(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateCustomerAge: (age) => {
    dispatch(setCustomerAge(age));
  },
  postState: () => {
    postState(dispatch);
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ThankYouAge);
