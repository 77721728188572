export const handleScroll = (event, scrollElement) => {
  const d = event.deltaY;

  if (
    (scrollElement.scrollTop === scrollElement.scrollHeight - scrollElement.clientHeight && d > 0)
    || (scrollElement.scrollTop === 0 && d < 0)
  ) {
    event.preventDefault();
  }
};
