import * as React from 'react';
import * as PropTypes from 'prop-types';
import PriceDisplay from '../../common/priceDisplay';
import i18n from '../../localization/i18n-wrapper';
import { formatBeneficiary } from './insuranceDetails';
import { formatPrice } from '../../util/utils';

export class InsuranceTotalRow extends React.Component {
  static displayName = 'InsuranceTotalRow';

  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    additionalDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    price: PropTypes.number.isRequired,
    compensation: PropTypes.string,
    beneficiary: PropTypes.string,
    beneficiaryDescription: PropTypes.string,
    noOrgPrice: PropTypes.number,
    showReferencePriceOnly: PropTypes.bool,
  };

  render() {
    const { props } = this;

    const totalPerMonthNoOrg = formatPrice(props.noOrgPrice, 2);
    const priceDisplayUnit = props.priceDisplayUnit || '€';

    const noOrgPriceText = totalPerMonthNoOrg
      ? i18n.t('priceWithoutMembership', { totalPerMonthNoOrg })
      : '';

    if (props.showReferencePriceOnly) {
      return <p>{noOrgPriceText}</p>;
    }

    const beneficiary = props.beneficiary ? (
      <p className="description">
        {i18n.t('productDetailBeneficiary')}
        {' '}
        {formatBeneficiary(props.beneficiary, props.beneficiaryDetails)}
      </p>
    ) : (
      <span />
    );

    return (
      <div className="insuranceTotals">
        <div className="insuranceDetails">
          <div className="header">
            <label className="title">{props.title}</label>
            <p className="description">{props.description}</p>
            <p className="additionalDescription">
              {props.additionalDescription}
              {props.compensation}
            </p>
            {<p>{noOrgPriceText}</p>}
            {beneficiary}
          </div>
        </div>
        <PriceDisplay price={props.price} priceDisplayUnit={priceDisplayUnit} decimals />
      </div>
    );
  }
}
