import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import i18n from '../localization/i18n-wrapper';
import formatNumber from '../util/formatNumber';
import {
  getOrganization,
  getCustomerAge,
  getCustomerAgePrevious,
  getSpouseAge,
  getSpouseAgePrevious,
  getCustomerProducts,
  getCustomerCompensation,
  getChildrenFrom0to9,
  getChildrenFrom10to12,
  getChildrenFrom13to17,
} from '../redux/reducers/selectedReducer';
import { getOrganizations } from '../redux/reducers/dataReducer';
import { getDefaultOrganization } from '../models/organizationModel';

class SmallCalculator extends React.Component {
  static displayName = 'SmallCalculator';

  static propTypes = {
    organization: PropTypes.object.isRequired,
    organizations: PropTypes.array.isRequired,
    customerAge: PropTypes.number,
    customerAgePrevious: PropTypes.number,
    spouseAge: PropTypes.number,
    spouseAgePrevious: PropTypes.number,
    customerProducts: PropTypes.array,
    customerCompensation: PropTypes.number,
    childrenFrom0to9: PropTypes.number,
    childrenFrom10to12: PropTypes.number,
    childrenFrom13to17: PropTypes.number,
    onTransition: PropTypes.func,
  };

  handleClick = () => {
    this.props.onTransition();
  };

  onChange = () => {
    this.forceUpdate();
  };

  getSelectedAges = () => {
    const { customerAge, spouseAge } = this.props;

    let text = i18n.t('calculatorAgeSelectorDefault');

    if (customerAge > 0 && !spouseAge) {
      text = i18n.t('smallCalculatorAgeSelectorMeWithAge', {
        age: customerAge,
      });
    } else if (!customerAge && spouseAge > 0) {
      text = i18n.t('smallCalculatorAgeSelectorSpouseWithAge', {
        age: spouseAge,
      });
    } else if (customerAge > 0 && spouseAge > 0) {
      text = i18n.t('smallCalculatorAgeSelectorMeAndSpouse');
    }

    return text;
  };

  getSelectedProductsText = () => {
    const { customerProducts, customerCompensation } = this.props;

    if (customerProducts.length === 0) {
      return i18n.t('smallProductSelectorLabelNoProducts');
    }
    if (customerProducts.length === 1 && customerProducts[0] == 'LIFE') {
      return i18n.t('smallProductSelectorOnlyLife', {
        amount: formatNumber(customerCompensation),
      });
    }
    return i18n.t('smallProductSelectorLabel', {
      count: customerProducts.length,
    });
  };

  getSelectedOrgText = () => {
    const { organization, organizations } = this.props;
    const org = organization || getDefaultOrganization(organizations);
    return i18n.t('smallCalculatorOrgSelected', { name: org.name });
  };

  getSelectedChildText = () => {
    const { childrenFrom0to9, childrenFrom10to12, childrenFrom13to17 } = this.props;
    const count = childrenFrom0to9 + childrenFrom10to12 + childrenFrom13to17;

    return i18n.t('childSelectorChildCount', { count });
  };

  render = () => (
    <div className="smallCalculator">
      <div className="marketing-title">{i18n.t('marketingTitle1')}</div>
      <div className="marketing-title-2">{i18n.t('marketingTitle2')}</div>

      <button className="btn btn-active btn-big" onClick={this.handleClick}>
        <span className="btn-big-text">{i18n.t('smallCalculatorButtonText')}</span>
      </button>
    </div>
  );
}

const mapStateToProps = (state) => ({
  customerAge: getCustomerAge(state),
  customerAgePrevious: getCustomerAgePrevious(state),
  spouseAge: getSpouseAge(state),
  spouseAgePrevious: getSpouseAgePrevious(state),
  customerProducts: getCustomerProducts(state),
  customerCompensation: getCustomerCompensation(state),
  organization: getOrganization(state),
  organizations: getOrganizations(state),
  childrenFrom0to9: getChildrenFrom0to9(state),
  childrenFrom10to12: getChildrenFrom10to12(state),
  childrenFrom13to17: getChildrenFrom13to17(state),
});

export default connect(mapStateToProps)(SmallCalculator);
