import cloneDeep from 'lodash/cloneDeep';
import { getOrganization } from '../reducers/selectedReducer';

const analyticsMiddleware = (store) => (next) => (action) => {
  updateHvkState(store.getState());
  updateCiState(store.getState());
  next(action);
};

const updateHvkState = (state) => {
  if (window) {
    window.__hvk_state = {
      customerAvailableProducts: cloneDeep(state.customerAvailableProducts),
      spouseAvailableProducts: cloneDeep(state.spouseAvailableProducts),
      selected: cloneDeep(state.selected),
      purchase: cloneDeep(state.purchase),
      price: cloneDeep(state.price),
      organization: cloneDeep(getOrganization(state)),
    };

    if (window.__hvk_state_callback && typeof window.__hvk_state_callback === 'function') {
      window.__hvk_state_callback(window.__hvk_state);
    }
  }
};

const updateCiState = (state) => {
  if (window) {
    window.__ci_state = {
      insuranceSum: cloneDeep(state.selected.customerCICompensation),
      customerAge: cloneDeep(state.selected.customerAge),
      smoke: cloneDeep(state.selected.customerSmoking),
      paymentFrequency: cloneDeep(state.selected.billsPerYear),
      result: cloneDeep(state.price),
    };
  }
};

export default analyticsMiddleware;
