import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import get from 'lodash/get';

import FieldBase from '../common/fieldBase';
import PersonDetails from './personDetails';
import i18n from '../localization/i18n-wrapper';
import config from '../config';

import { setCustomerBeneficiary, setSpouseBeneficiary } from '../redux/actions/purchaseActions';

const BeneficiaryField = (function () {
  const options = config.beneficiaries;

  return class extends React.Component {
    static displayName = 'BeneficiaryField';

    static propTypes = {
      root: PropTypes.object.isRequired,
      errors: PropTypes.object,
      path: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
      readOnly: PropTypes.bool,
      setCustomerBeneficiary: PropTypes.func.isRequired,
      setSpouseBeneficiary: PropTypes.func.isRequired,
    };

    handleChange = (e) => {
      const { value } = e.target;
      if (this.props.path == 'customerBeneficiary') {
        this.props.setCustomerBeneficiary(value);
      } else {
        this.props.setSpouseBeneficiary(value);
      }
    };

    render() {
      const p = this.props;

      const { root } = p;
      const { errors } = p;

      const selectValue = get(root, p.path) || {};

      const additionalElements = [];

      if (selectValue === 'COHABITANT' || selectValue === 'NAMED') {
        additionalElements.push(<h2 key="beneficiaryHeader">{i18n.t('beneficiaryDetailsTitle')}</h2>);
        additionalElements.push(
          <PersonDetails
            key={p.path}
            idPrefix={p.path}
            root={root}
            errors={errors}
            path={p.detailsPath}
            readOnly={p.readOnly}
          />,
        );
      }

      const self = this;

      function selectOrReadOnly() {
        if (p.readOnly) {
          return <p className="form-control-static">{i18n.t(`beneficiary_${selectValue}`)}</p>;
        }
        const optionElements = options.map((option) => (
          <option value={option} key={option}>
            {i18n.raw(`beneficiary_${option}`)}
          </option>
        ));

        return (
          <select className="form-control" id={p.id} value={selectValue} onChange={self.handleChange}>
            {optionElements}
          </select>
        );
      }

      return (
        <div>
          <FieldBase {...p}>{selectOrReadOnly()}</FieldBase>
          {additionalElements}
        </div>
      );
    }
  };
}());

const mapDispatchToProps = (dispatch) => ({
  setCustomerBeneficiary: (b) => dispatch(setCustomerBeneficiary(b)),
  setSpouseBeneficiary: (b) => dispatch(setSpouseBeneficiary(b)),
});

export default connect(
  undefined,
  mapDispatchToProps,
)(BeneficiaryField);
