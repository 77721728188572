import assign from 'lodash/assign';
import extend from 'lodash/extend';

import validate from '../util/validate';
import utils from '../util/utils';

const { check } = validate;
const v = validate.validators;

function PurchaseModel(properties) {
  assign(
    this,
    {
      customer: {},
      spouse: {},
      memberOfOrganization: true,
      children: [],
    },
    properties,
  );
  changeStringAnswersToBoolean(this.customerQuestions || []);
  changeStringAnswersToBoolean(this.spouseQuestions || []);
  changeStringAnswersToBoolean(this.optionalCustomerQuestions || []);
  changeStringAnswersToBoolean(this.optionalSpouseQuestions || []);
  changeStringAnswersToBoolean(this.approvalQuestions || []);
  changeStringAnswersToBoolean(this.ciApprovalQuestions || []);
}

const changeStringAnswersToBoolean = (questions) => {
  questions.forEach((q) => {
    if (q.answer === true || q.answer === false || q.answer === undefined) {
      return;
    }
    if (typeof q.answer === 'string' && (q.answer.includes('Ei') || q.answer.includes('false'))) {
      q.answer = false;
    } else if (typeof q.answer === 'string' && (q.answer.includes('Kyll') || q.answer.includes('true'))) {
      q.answer = true;
    } else {
      q.answer = undefined;
    }
  });
};

export const changeBooleanAnswersToString = (questions) => {
  if (questions === undefined) {
    return;
  }
  questions.forEach((q) => {
    if (q.answer === 'Kyllä' || q.answer === 'Ei' || q.answer === undefined) {
      return;
    }
    if (typeof q.answer === 'boolean' && q.answer === false) {
      q.answer = 'Ei';
    } else if (typeof q.answer === 'boolean' && q.answer === true) {
      q.answer = 'Kyllä';
    } else {
      q.answer = '';
    }
  });
};

const validPerson = {
  firstName: v.and(check(v.required, 'validationFirstName'), check(v.validName, 'validationValidName')),
  lastName: v.and(check(v.required, 'validationLastName'), check(v.validName, 'validationValidName')),
};

const ssnValidator = v.and(
  check(v.required, 'validationSocialSecurityNumber'),
  check(v.validSocialSecurityNumber, 'validationSocialSecurityNumberInvalid'),
);

const validChild = assign({}, validPerson, {
  socialSecurityNumber: ssnValidator,
});

const validAddress = {
  streetAddress: v.and(check(v.required, 'validationStreetAddress'), check(v.validAddress, 'validationValidAddress')),
  postCode: check(v.and(v.required, v.numeric, v.hasLength(5)), 'validationPostCode'),
  city: check(v.required, 'validationCity'),
};

const validEmailAndPhone = {
  email: check(v.and(v.required, v.validEmail), 'validationEmail'),
  phoneNumber: check(v.required, 'validationPhone'),
};

const validBeneficiary = assign({}, validPerson, {
  socialSecurityNumber: ssnValidator,
});

function validBeneficiaryDetails(beneficiaryProperty) {
  return function (value, options, path, context) {
    // only apply person validation if "named" beneficiary is selected
    const selectedBeneficiary = context[0].purchase[beneficiaryProperty];
    if (selectedBeneficiary === 'COHABITANT' || selectedBeneficiary === 'NAMED') {
      return validate.object(validBeneficiary).apply(undefined, arguments);
    }

    return true;
  };
}

function validMembershipNumber(value, options, path, context) {
  const selectedOrganization = context[0].organization;
  if (selectedOrganization) {
    const { membershipNumberMaxLength } = selectedOrganization;

    if (membershipNumberMaxLength > 0) {
      if (!v.required(value, options)) {
        return 'validationMembershipNumber';
      }
      if (!v.hasMaxLength(membershipNumberMaxLength)(value, options)) {
        return ['validationMembershipNumberInvalid', { maxLength: membershipNumberMaxLength }];
      }
    }
  }

  return true;
}

function ssnAgeValidator(age) {
  return check((ssn) => {
    if (v.blank(ssn)) {
      return true;
    }

    return utils.calculateAge(ssn) === age;
  }, 'validationSocialSecurityNumberAge');
}

const schema = {
  customer: extend({}, validPerson, validEmailAndPhone, {
    address: validAddress,
    membershipNumber: validMembershipNumber,
    socialSecurityNumber(value, options, path, context) {
      let retval = ssnValidator(value, options, path, context);
      const { customerAge } = context[0].selected;

      if (retval === true && customerAge) {
        retval = ssnAgeValidator(customerAge)(value, options, path, context);
      }

      return retval;
    },
  }),

  spouse: extend({}, validPerson, validEmailAndPhone, {
    address: validAddress,
    socialSecurityNumber(value, options, path, context) {
      let retval = ssnValidator(value, options, path, context);

      if (retval === true) {
        const { spouseAge } = context[0].selected;
        retval = ssnAgeValidator(spouseAge)(value, options, path, context);
      }

      return retval;
    },
  }),

  customerBeneficiaryDetails: validBeneficiaryDetails('customerBeneficiary'),
  spouseBeneficiaryDetails: validBeneficiaryDetails('spouseBeneficiary'),

  children(value, options, path, context) {
    const childDetailCount = (value && value.length) || 0;
    const childDetailsMissing = childDetailCount !== getChildCount(context[0].selected);
    if (childDetailsMissing) {
      return function () {
        return error('firstName', 'validationValidName');
      };
    }

    return validate.array(validChild).apply(undefined, arguments);
  },
};

const getChildCount = (selected) => {
  const { childrenFrom0to9, childrenFrom10to12, childrenFrom13to17 } = selected;
  const childrenCounts = [childrenFrom0to9, childrenFrom10to12, childrenFrom13to17];

  // sum child counts (counting undefined as 0)
  const count = childrenCounts.reduce((sum, childCount) => sum + (childCount || 0), 0);

  return count;
};

const error = (fieldName, errorKey) => ({
  0: {
    [fieldName]: errorKey,
  },
});

const purchaseModelValidator = validate.build(schema);

PurchaseModel.prototype = {
  validate(context, relaxed, filter) {
    return purchaseModelValidator(context, relaxed, filter);
  },
};

export default PurchaseModel;
