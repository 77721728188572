import i18n from 'i18next-client';
import extend from 'lodash/extend';

import config from '../config';

const initI18N = (localizations) => {
  i18n.init(
    extend(config.i18n, {
      customLoad: (lng, ns, options, loadComplete) => {
        // load the file for given language and namespace
        // callback with parsed json data
        loadComplete(null, localizations); // or loadComplete('some error'); if failed
      },
      getAsync: false,
    }),
  );
};

export default {
  initI18N,
};
