import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import i18n from '../localization/i18n-wrapper';

import HealthYesNoQuestion from './healthYesNoQuestion';
import OptionalHealthYesNoQuestion from './optionalHealthYesNoQuestion';
import {
  getSpouseQuestions,
  isSpousePresent,
  getOptionalSpouseQuestions,
} from '../redux/reducers/purchaseReducer';

SpouseQuestions.displayName = 'SpouseQuestions';

SpouseQuestions.propTypes = {
  spouseQuestions: PropTypes.array.isRequired,
  optionalSpouseQuestions: PropTypes.array.isRequired,
  spousePresent: PropTypes.bool.isRequired,
};

function SpouseQuestions(props) {
  const content = [];

  const {
    spouseQuestions, spousePresent, optionalSpouseQuestions,
  } = props;

  content.push(<h2 key="spouseHeader">{i18n.t('healthCheckSpouseHeader')}</h2>);

  spouseQuestions.forEach((question, index) => {
    const path = ['spouseQuestions', index];
    content.push(<HealthYesNoQuestion question={question} path={path} />);
  });

  optionalSpouseQuestions.forEach((question, index) => {
    const path = ['optionalSpouseQuestions', index];
    content.push(
      <OptionalHealthYesNoQuestion question={question} path={path} personType="spouse" />,
    );
  });

  if (spousePresent) {
    return <div className="purchaseSection">{content}</div>;
  }
  return <div />;
}

const mapStateToProps = (state) => ({
  spouseQuestions: getSpouseQuestions(state),
  spousePresent: isSpousePresent(state),
  optionalSpouseQuestions: getOptionalSpouseQuestions(state),
});

export default connect(mapStateToProps)(SpouseQuestions);
