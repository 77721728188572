import * as React from 'react';
import isNumber from 'lodash/isNumber';

import * as PropTypes from 'prop-types';
import PriceDisplay from '../../common/priceDisplay';
import i18n from '../../localization/i18n-wrapper';
import formatNumber from '../../util/formatNumber';
import { formatBeneficiary } from './insuranceDetails';

export class Product extends React.Component {
  static displayName = 'Product';

  static propTypes = {
    id: PropTypes.string.isRequired,
    productType: PropTypes.string.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    description: PropTypes.object,
    person: PropTypes.object,
    amount: PropTypes.number,
    form: PropTypes.object,
    beneficiary: PropTypes.string,
    beneficiaryDetails: PropTypes.object,
    deductible: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    validity: PropTypes.bool,
    smoking: PropTypes.bool,
    price: PropTypes.number.isRequired,
  };

  _createAmountRow = (text) => (
    <div key={text} className="insuranceAmount">
      <p className="text">{text}</p>
    </div>
  );

  _createDetailRow = (label, value, extra) => {
    if (isNumber(value)) {
      value = `${formatNumber(value)} €`;
    }
    return (
      <div key={label.key} className="insuranceDetail">
        <p className="name">{label}</p>
        <p className="value">
          {value}
          {' '}
          {extra}
        </p>
      </div>
    );
  };

  _createHeader = (p) => {
    const content = [
      <label key={p.title} className="title">
        {p.title}
      </label>,
    ];
    if (p.description) {
      content.push(
        <p key={p.description.key} className="description">
          {p.description}
        </p>,
      );
    }
    return (
      <div key={p.title + p.description} className="header">
        {content}
      </div>
    );
  };

  _formatValidity = (fullTime) => {
    if (fullTime) {
      return i18n.t('validityFullTime');
    }
    return i18n.t('validityPartTime');
  };

  getCompensationDescription = (productType) => {
    switch (productType) {
      case 'LIFE':
        return 'productDetailAmount';
      case 'CI':
        return 'productCiDetailAmount';
      default:
        return 'additionalProductDetailAmount';
    }
  };

  render() {
    const p = this.props;
    const details = [];
    details.push(this._createHeader(p));
    if (p.amount) {
      const compensationDescription = this.getCompensationDescription(p.productType);
      const extra = p.productType == 'DAE' ? i18n.t('perDayDescription') : undefined;
      details.push(this._createDetailRow(i18n.t(compensationDescription), p.amount, extra));
    }
    if (p.form) {
      details.push(this._createDetailRow(i18n.t('productDetailForm'), p.form));
    }
    if (p.beneficiary) {
      details.push(
        this._createDetailRow(
          i18n.t('productDetailBeneficiary'),
          formatBeneficiary(p.beneficiary, p.beneficiaryDetails),
        ),
      );
    }
    if (p.deductible) {
      details.push(this._createDetailRow(i18n.t('productDetailDeductible'), p.deductible));
    }
    if (p.smoking) {
      details.push(this._createDetailRow(i18n.t('smokingKey'), p.smoking ? i18n.t('answerYes') : i18n.t('answerNo')));
    }
    if (p.validity) {
      details.push(this._createDetailRow(i18n.t('productDetailValidity'), this._formatValidity(p.validity)));
    }
    return (
      <div className="product">
        <div className="insuranceDetails">{details}</div>
        <PriceDisplay price={p.price} priceDisplayUnit={i18n.t('priceDisplayUnit')} decimals />
      </div>
    );
  }
}
