import * as React from 'react';
import * as PropTypes from 'prop-types';

import get from 'lodash/get';

import PostOfficePicker from './postOfficePicker';
import FieldBase from '../common/fieldBase';

export default class PostOfficeField extends React.Component {
  static displayName = 'PostOfficeField';

  static propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    path: PropTypes.string.isRequired,
    root: PropTypes.object.isRequired,
    errors: PropTypes.object,
    readOnly: PropTypes.bool,
  };

  state = { open: false };

  onVisibilityChange = (visible) => {
    this.setState({ open: visible });
  };

  render() {
    const p = this.props;
    const self = this;

    function inputOrReadOnly() {
      if (p.readOnly) {
        const value = get(p.root, p.path);

        return <p className="form-control-static">{value}</p>;
      }
      return (
        <PostOfficePicker
          path={p.path}
          root={p.root}
          onVisibilityChange={self.onVisibilityChange}
          open={self.state.open}
        />
      );
    }

    return <FieldBase {...p}>{inputOrReadOnly()}</FieldBase>;
  }
}
