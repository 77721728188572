import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import findIndex from 'lodash/findIndex';

import { navigate } from 'react-mini-router';
import { getCurrentPage } from '../redux/reducers/purchaseReducer';

const pages = [['product', '/1'], ['customerAndChildren', '/2a'], ['spouse', '/2b'], ['summary', '/3']];

export const pageIndex = (page) => findIndex(pages, (p) => p[0] === page);

class NavBar extends React.Component {
  static displayName = 'NavBar';

  static propTypes = {
    currentPage: PropTypes.string.isRequired,
  };

  handleClick = (page) => {
    const { currentPage } = this.props;

    if (pageIndex(page) < pageIndex(currentPage)) {
      navigate(pages[pageIndex(page)][1]);
    }
  };

  render() {
    const { currentPage } = this.props;

    return (
      <ul>
        <li className={isCurrent('product')}>
          <span onClick={() => this.handleClick('product')}>Vakuutus</span>
        </li>
        <li className={isCurrent('customerAndChildren', 'spouse')}>
          <span onClick={() => this.handleClick('customerAndChildren')}>Henkilötiedot</span>
        </li>
        <li className={isCurrent('summary')}>
          <span onClick={() => this.handleClick('summary')}>Vahvistus</span>
        </li>
      </ul>
    );

    function isCurrent(...pageNames) {
      return pageNames.indexOf(currentPage) >= 0 ? 'current' : '';
    }
  }
}

const mapStateToProps = (state) => ({
  currentPage: getCurrentPage(state),
});

export default connect(mapStateToProps)(NavBar);
